import { useCartStore } from "@/shop/store";

export const useCartItemsStore = () => ({
  cartItems: useCartStore(state => state.getCombinedItems()),
  cartItemsCount: useCartStore(
    s =>
      s.getCombinedItems()?.reduce((total, item) => total + item.quantity, 0) ??
      0
  ),
});
