import React from "react";
import { PaymentMethod } from "@/shared/shop/api";
import CardOnDeliveryPaymentMethod from "./CardOnDeliveryPaymentMethod";
import TermsPaymentMethod from "./PaymentTerms/TermsPaymentMethod";
import StraightCreditCardPaymentMethod from "./StraightCreditCardPaymentMethod";
import CheckoutPaymentMethod from "../../CheckoutPaymentMethod";

type Props = {
  name: PaymentMethod;
  onSuccess?: (paymentMethod: PaymentMethod, isPaid?: boolean) => void;
};

const DynamicSecondaryPaymentMethod = ({ name, onSuccess }: Props) => {
  switch (name) {
    case PaymentMethod.CREDIT_CARD_CHECKOUT:
      return <CheckoutPaymentMethod />;
    case PaymentMethod.CREDIT_CARD_BLACKBIRD:
      return <StraightCreditCardPaymentMethod onSuccess={onSuccess} />;
    case PaymentMethod.PAYMENT_TERMS:
      return <TermsPaymentMethod onSuccess={onSuccess} />;
    case PaymentMethod.CARD_ON_DELIVERY:
      return <CardOnDeliveryPaymentMethod onSuccess={onSuccess} />;
    default:
      return null;
  }
};

export default DynamicSecondaryPaymentMethod;
