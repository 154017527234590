import React from "react";
import Typography from "@/shared/components/elements/Typography";
import { Color } from "@/constants/color";
import deliveryImage from "./delivery-icon.svg";

const PaymentCompleteContent = () => {
  return (
    <div className="my-auto flex flex-1 flex-col">
      <div className="flex flex-col gap-12-v2 mb-32-v2 mx-auto">
        <Typography
          text="We’ve got your order"
          size="lead-lg"
          font="grotesk"
          weight="medium"
          color={Color.Black}
        />
        <Typography
          text="Your order is on its way. Check your email for more details."
          size="body-sm"
          font="grotesk"
          weight="book"
          color={Color.Black}
        />
      </div>
      <img
        src={deliveryImage}
        alt="Yoco POS on phone and tablet"
        className="mx-auto"
      />
    </div>
  );
};

export default PaymentCompleteContent;
