import CONFIG from "@/config";
import Segment from "@/shared/services/Segment";
import { useSignupStore } from "@/signup/store";
import { useWaitUntilMerchantAccountIsCreated } from "@/utils/hooks";
import React, { useEffect, useMemo } from "react";
// V2 Imports
import Layout from "../../../shared/components/Layout";
import FormHeader from "../../../shared/components/checkout/FormHeader";
import Loader from "../../../shared/components/elements/Loader";
import Typography from "../../../shared/components/elements/Typography";
import PillButton from "../../../shared/components/elements/Buttons/PillButton";

import { Color } from "../../../constants/color";

const useStore = () => ({
  yocoAuthToken: useSignupStore(state => state.yocoAuthToken),
});

const AutoLoginToAppContent = () => {
  const { yocoAuthToken } = useStore();
  const { isMerchantAccountCreated, merchantAccountError } =
    useWaitUntilMerchantAccountIsCreated();

  useEffect(() => {
    if (isMerchantAccountCreated && yocoAuthToken) {
      const appURL = `${CONFIG.appURL}/login/landing?yat=${yocoAuthToken}`;
      window.location.replace(appURL);
    }
  }, [isMerchantAccountCreated, yocoAuthToken]);

  const onClickContinueToAppButton = () => {
    const appUrl =
      yocoAuthToken && isMerchantAccountCreated
        ? `${CONFIG.appURL}/login/landing?yat=${yocoAuthToken}`
        : CONFIG.appURL;

    Segment.track("web_signup_confirmation_complete_app_button_clicked");
    window.location.replace(appUrl);
  };

  const canClickContinue = useMemo(() => {
    if (merchantAccountError) {
      return true;
    }
    return isMerchantAccountCreated;
  }, [isMerchantAccountCreated, merchantAccountError]);

  return (
    <Layout isCartEditable={false}>
      <FormHeader
        title="Congrats!"
        className="items-center"
        description="Creating your App Profile..."
      />

      <div className="flex flex-col gap-32-v2 text-center">
        <Loader size="lg" />
        <Typography
          element="p"
          weight="book"
          size="body-md"
          font="grotesk"
          color={Color.Black}
          text="You're about to enter the App, where you'll complete your profile
          setup and verification for payouts."
        />

        <PillButton
          hideIcon
          size="large"
          style="solid"
          color={Color.Blue}
          text="Complete your setup"
          disabled={!canClickContinue}
          className="sm:!w-9/12 mx-auto"
          onClick={onClickContinueToAppButton}
        />

        <Typography
          element="p"
          weight="book"
          size="body-md"
          font="grotesk"
          color={Color.Black}
          text='Not getting redirected? Click the "Complete your setup" button to
          start your setup now.'
        />
      </div>
    </Layout>
  );
};

export default AutoLoginToAppContent;
