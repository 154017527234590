import { Color } from "../../../../constants/color";
import Typography from "../../elements/Typography";

type Props = { header?: string; clickPrompt?: string };

/**
 * Use this for common EFT details display
 */
const EFTDetails = ({
  header = `Paying with EFT uses a secure payment solution that
  facilitates instant EFT payments from all major South
  African banks`,
  clickPrompt = `Pay by EFT`,
}: Props) => {
  return (
    <div className="mb-32-v2">
      <Typography
        font="grotesk"
        size="body-md"
        text={header}
        color={Color.Black}
        weight="medium"
      />

      <Typography
        font="grotesk"
        size="body-md"
        text="How does it work?"
        color={Color.Black}
        weight="medium"
        className="my-8-v2"
      />
      <Typography
        font="grotesk"
        size="body-sm"
        text={`1. Click ‘${clickPrompt}’ to confirm your order.`}
        color={Color.Black}
        weight="book"
      />
      <Typography
        font="grotesk"
        size="body-sm"
        text="2. Select your bank and login securely using your internet banking
        details."
        color={Color.Black}
        weight="book"
      />
      <Typography
        font="grotesk"
        size="body-sm"
        text="3. Select an account to pay from."
        color={Color.Black}
        weight="book"
      />
      <Typography
        font="grotesk"
        size="body-sm"
        text="4. Your bank may send you an OTP or require you to authenticate payment
        within the banking app."
        color={Color.Black}
        weight="book"
      />
    </div>
  );
};

export default EFTDetails;
