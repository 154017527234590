import { memo } from "react";

import { Product } from "@yoco/shop-sdk";
import { Bundle } from "@yoco/shop-sdk/lib/types";
import {
  getBundleThumbnailImage,
  getProductThumbnailImage,
} from "../../../../assets/images/products";

export const DrawerItemImage = memo(
  ({ product, bundle }: { product: Product; bundle?: Bundle }) => {
    return (
      <img
        // ToDo: pull these images in from api rather
        src={
          bundle
            ? getBundleThumbnailImage(bundle) ?? bundle?.image
            : getProductThumbnailImage(product) ?? product.image
        }
        alt={`Thumbnail of ${bundle?.short_name || product?.short_name}`}
        className="md-v2:w-full md-v2:h-full object-contain"
      />
    );
  }
);

DrawerItemImage.displayName = "DrawerItemImage";
