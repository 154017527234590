// ToDo V2 Design
import { useCartStore } from "@/shop/store";
import React from "react";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
});

const EFTBankAccountDetails = () => {
  const { cart } = useStore();

  const reference = cart?.eft_payment_reference || "";
  const amount = cart?.balance_due_formatted || "";

  return (
    <div className="bg-gray-200 leading-8 p-10 border rounded-md mb-8">
      <div className="w-full lg:w-9/12 mx-auto text-center">
        <div>
          <strong>Account name:</strong> Yoco Technologies
        </div>
        <div>
          <strong>Account number:</strong> 4096074584
        </div>
        <div>
          <strong>Bank:</strong> Absa Bank Limited
        </div>
        <div>
          <strong>Branch code:</strong> 632005
        </div>
        <div>
          <strong>Reference number:</strong> {reference}
        </div>
        <div>
          <strong>Amount:</strong> {amount}
        </div>
      </div>
    </div>
  );
};

export default EFTBankAccountDetails;
