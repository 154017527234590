import React from "react";

type Props = {
  title: string;
  description?: string | React.ReactNode;
};

const Header = ({ title, description }: Props) => {
  return (
    <div className="mx-auto mb-8 flex justify-center flex-col">
      <h1 className="text-2xl sm:text-3xl sharp-grotesk-medium leading-8 sm:leading-14">
        {title}
      </h1>
      {description && <h6 className="block mt-3">{description}</h6>}
    </div>
  );
};

export default Header;
