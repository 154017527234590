import classNames from "classnames";

import { Color } from "../../../../constants/color";
import Typography from "../Typography";

interface Props {
  error: string;
  className?: string;
}

const InputError = ({ error, className, ...props }: Props): JSX.Element => {
  return (
    <Typography
      color={Color.Charcoal}
      text={error}
      font="grotesk"
      size="body-md-fixed"
      weight="book"
      className={classNames("mb-2-v2 mt-4-v2 !text-orange-v2", className)}
      {...props}
    />
  );
};

export default InputError;
