import { useFeatureFlags } from "@yoco/feature-flags";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { makeTestID } from "@/shared/utils/development";
import { useCartStore } from "@/shop/store";
import { ShopRoute } from "@/shop/types";
import ProductGrid from "@/signup/pages/ProductCataloguePage/ProductGrid";
import LayoutOrderSummary from "@/shared/components/checkout/LayoutOrderSummary";
import SubmitButton from "@/shared/components/checkout/SubmitButton";
import { useStepShop } from "@/shop/store/steps";
import Formik from "@/shared/components/form/Formik";
import Form from "@/shared/components/form/Form";

const ProductCataloguePage = () => {
  const navigate = useNavigate();
  const { updateCart } = useCartStore.getState();
  const features = useFeatureFlags().getEnabledFeatureNames();

  const useShopStore = () => ({
    steps: useStepShop(state => state.steps),
    activeStep: useStepShop(state => state.activeStep),
    setActiveStep: useStepShop(state => state.setActiveStep),
    getPreviousStep: useStepShop(state => state.getPreviousStep),
    getPreviousStepLink: useStepShop(state => state.getPreviousStepLink),
  });

  const { setActiveStep } = useShopStore();

  useEffect(() => {
    const { cart } = useCartStore.getState();
    if (cart) {
      setActiveStep(ShopRoute.CartPage);
    } else {
      setActiveStep(ShopRoute.HomePage);
    }
  }, []);

  const onSubmit = async () => {
    const { cart } = useCartStore.getState();
    if (cart) {
      if (features) {
        await updateCart({ feature_flags: features });
      }

      navigate(
        formatRoute(ShopRoute.DeliveryPage, {
          id: cart.id,
        })
      );
    }
  };

  const cartItemsLength = useCartStore(state => state.cart?.items.length);

  return (
    <Formik onSubmit={onSubmit}>
      {() => (
        <Form>
          <LayoutOrderSummary
            title="We’ve found some products for your business"
            description="Select a product that best suites your business needs below to continue."
            isCartEditable
            mobileFooterVariant="next"
            showButtonTarget
          >
            <div className="flex flex-col gap-32-v2">
              <ProductGrid />
            </div>
            <div className="md-v2:hidden">
              <SubmitButton
                portalled
                type="submit"
                testID={makeTestID("productCatalogue", "checkout")}
                text="Next"
                disabled={!cartItemsLength}
              />
            </div>
          </LayoutOrderSummary>
        </Form>
      )}
    </Formik>
  );
};

export default ProductCataloguePage;
