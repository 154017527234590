type Props = {
  className?: string;
};

const LockIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.668 17.4997H6.33464C5.4138 17.4997 4.66797 16.7538 4.66797 15.833V9.99967C4.66797 9.07884 5.4138 8.33301 6.33464 8.33301H14.668C15.5888 8.33301 16.3346 9.07884 16.3346 9.99967V15.833C16.3346 16.7538 15.5888 17.4997 14.668 17.4997Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.16797 8.33333V5.83333V5.83333C7.16797 3.9925 8.66047 2.5 10.5013 2.5V2.5C12.3421 2.5 13.8346 3.9925 13.8346 5.83333V5.83333V8.33333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LockIcon;
