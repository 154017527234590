import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import Validator from "@/signup/services/Validator";
import { useSignupStore, useStepStore } from "@/signup/store";
import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Layout from "../../../shared/components/Layout";
import FormHeader from "../../../shared/components/checkout/FormHeader";
import TextInput from "../../../shared/components/elements/Inputs/TextInput";
import Field from "../../../shared/components/elements/Inputs/Field";
import Typography from "../../../shared/components/elements/Typography";
import { Color } from "../../../constants/color";
import SubmitButton from "../../../shared/components/checkout/SubmitButton";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

const BusinessNamePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { signup, updateSignup, getNextStepLink } = useStore();

  useEffect(() => {
    Segment.track("web_signup_business_name_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.BusinessNamePage,
    business_name: signup?.business_name || "",
  };

  yup.addMethod(
    yup.string,
    "validBusinessName",
    Validator.validateBusinessName
  );

  const schema = yup.object({
    business_name: (yup as any)
      .string()
      .required()
      .label("business name")
      .validBusinessName(),
  });

  const onSubmit = async (values: FormikValues) => {
    const isSignupUpdated = await updateSignup(values);

    if (isSignupUpdated) {
      Segment.trackWithSignup("web_signup_business_name_next_button_clicked");

      navigate(getNextStepLink());
    }
  };

  return (
    <Layout>
      <FormHeader
        title="What's your business called?"
        description={[
          `Not sure yet? That's okay, you can update this anytime in your
              Yoco profile.`,
          `It can be your name, your trading name, or whatever your customers
              best know you by.`,
        ]}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <div className="flex flex-col gap-32-v2">
                <div>
                  <Field
                    id="business_name"
                    name="business_name"
                    type="text"
                    label="Business trading name"
                    placeholder="Business trading name"
                    component={TextInput}
                    data-testid={makeTestID("businessName", "businessName")}
                  />
                </div>
                <div>
                  <Typography
                    font="grotesk"
                    size="body-md"
                    element="p"
                    color={Color.Black}
                    weight="book"
                    text="This will be displayed on your"
                    className="inline"
                  />
                  <Typography
                    font="grotesk"
                    size="body-md"
                    element="p"
                    color={Color.Black}
                    weight="medium"
                    text=" (free) "
                    className="inline"
                  />
                  <Typography
                    font="grotesk"
                    size="body-md"
                    element="p"
                    color={Color.Black}
                    weight="book"
                    text="Yoco receipts
                  when you transact."
                    className="inline"
                  />
                </div>
                <SubmitButton testID={makeTestID("businessName", "next")} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default withFetchSignup(BusinessNamePage, SignupStep.BusinessNamePage);
