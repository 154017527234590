import { Signup } from "@yoco/sawubona-sdk";
import Segment from "@/shared/services/Segment";

const onFinaliseComplete = (signup: Signup) => {
  try {
    Segment.trackWithSignup("web_signup_complete");
    Segment.trackWithSignup("Account Created", "milestones");

    if (signup.cart && signup.cart.is_order) {
      Segment.track("web_signup_order_placed", signup.cart);
    }
  } catch (error) {
    // We specifically catch and suppress the exception here, as we don't want a minor failure to be indicative of the
    // signup having failed to finalise.
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

const isResellerCode = (code: string): boolean => {
  // This is a temporary hacky function which contains a list of reseller codes that haven't yet switched to the new
  // resellerCode links.  We need to hard-code these so that we can differentiate between referral codes and reseller
  // codes, since the old link was the same for both, eg: https://referral.yoco.com/FooBar
  const codes: string[] = [
    "AmazingMall",
    "BR001",
    "BR002",
    "BR003",
    "BR004",
    "BR005",
    "BR006",
    "BR007",
    "BR008",
    "BR009",
    "BR010",
    "BR011",
    "BR012",
    "BR013",
    "BR014",
    "Donovan",
    "GrowMyBusiness",
    "IG001",
    "IG002",
    "IG003",
    "IG004",
    "IG005",
    "IG006",
    "IG007",
    "IG008",
    "IG009",
    "IG010",
    "IG011",
    "IG012",
    "IG013",
    "IG014",
    "IG015",
    "IG016",
    "Katlego012",
    "Kesh",
    "Klein200",
    "Luhyoco",
    "melissa17",
    "Mfanah",
    "Mhligo",
    "mycoza",
    "MyTech",
    "Nthabi",
    "OrarunGroup",
    "SbuYoco",
    "Shandu",
    "vinny22",
    "YoBK",
    "YoCberg",
    "YoChris",
    "YocoBahle",
    "YocoLonwabo",
    "YocoMphot",
    "YOCORUFF",
    "YoEunice",
    "YoGrey",
    "YoGrowth",
    "YoHeirz",
    "YoHugo",
    "YoInsaka",
    "YoItu",
    "YoJames",
    "YoJerry",
    "YoLebo",
    "YoLemonade",
    "YoMall",
    "Yomalvinkoketso",
    "YoMashilane",
    "YoMmule",
    "YoMotso",
    "YoNkulu",
    "YoSfiso",
    "YoSof",
    "YoSolly",
    "Yostudio",
    "YoTam",
    "YoThulani",
    "YoTman",
    "YoTony",
    "YoTshepo",
    "YoTsheps",
    "YoVicinity",
    "YoXneelo",
    "YoZan1",
    "3wGd2j",
  ];
  const normalisedCodes = codes.map(str => str.toUpperCase());

  return normalisedCodes.includes(code.toUpperCase());
};

/**
 * Determine if a code is an affilaite partner code
 * @param code code in string format
 * @returns `true` if the code is an affiliate partner code
 */
const isPartnerCode = (code: string): boolean => {
  const partnerCodes = [
    "xneelo",
    "semantica",
    "woww",
    "digitalboutique",
    "YoMall",
    "YoStudio",
    "stonehut",
    "devscape",
    "erafold",
    "i22",
    "bastionflowe",
    "rubydigital",
    "YoInsaka",
    "medscape",
  ];
  const normalisedCodes = partnerCodes.map(str => str.toUpperCase());

  return normalisedCodes.includes(code.toUpperCase());
};

export default {
  onFinaliseComplete,
  isPartnerCode,
  isResellerCode,
};
