import { useFeatureFlags } from "@yoco/feature-flags";
import { BusinessType, Service } from "@yoco/sawubona-sdk";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formatRoute } from "react-router-named-routes";
import shop, { Product, ReasonNotAvailable } from "@/shared/shop/api";
import { useAppConfigStore, useCartStore } from "@/shop/store";
import { useSignupStore } from "@/signup/store";
import { Features, SignupRoute } from "@/signup/types";

import { Bundle } from "@yoco/shop-sdk/lib/types";
import { Color } from "@/constants/color";
import { getRibbonColor } from "@/utils/color";
import CardMachine from "../../../shared/components/cards/Product/CardMachine";
import { getProductImage } from "../../../assets/images/products";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  business: useAppConfigStore(state => state.business),
  signup: useSignupStore(state => state.signup),
  getSignup: useSignupStore(state => state.getSignup),
  finaliseSignup: useSignupStore(state => state.finaliseSignup),
  updateSignup: useSignupStore(state => state.updateSignup),
});

type Props = {
  product: Product;
  onAddedProductToCart?: (product: Product | Bundle) => void;
};

const ProductBlock = ({ product, onAddedProductToCart }: Props) => {
  const { cart, business, signup, getSignup, finaliseSignup, updateSignup } =
    useStore();
  const features = useFeatureFlags();
  const navigate = useNavigate();

  const isAvailableOnTerms = useMemo(
    () =>
      (signup?.business_type === BusinessType.SOLE_TRADER ||
        signup?.business_type === BusinessType.COMPANY ||
        signup?.business_type === BusinessType.NOT_REGISTERED) &&
      features.isEnabled(Features.TERMS) &&
      product.can_be_purchased &&
      product.is_available_on_payment_terms,
    [signup, features, product]
  );

  const [isOnWaitlist, setIsOnWaitlist] = useState(false);

  const onAddToWaitlist = async () => {
    const result = await shop.waitlist.add({
      email: signup?.email || business?.owner.email,
      mobile_number: signup?.mobile_number || business?.telephone_number,
      product: product.id,
    });

    if (result.id) {
      setIsOnWaitlist(true);
      toast.success(
        `You have been added to the waitlist. We'll let you know when it becomes available.`,
        { position: "bottom-center" }
      );
    }
  };

  // TODO: Remove this after Go out of stock experiment or standardise this behaviour
  // This will render a disabled "out of stock" button instead of waitlist CTA
  const isExperimentalOutOfStockProduct = useMemo(() => {
    return (
      product.reason_not_available === ReasonNotAvailable.OUT_OF_STOCK &&
      product.sku === "DSQ001"
    );
  }, [product]);

  const isWaitListAvailable = useMemo(() => {
    return !!(
      product.reason_not_available &&
      (signup || business) &&
      !isExperimentalOutOfStockProduct
    );
  }, [product, signup, business, isExperimentalOutOfStockProduct]);

  const canFinaliseSignup = useMemo(() => {
    return signup && isOnWaitlist && cart?.items.length === 0;
  }, [signup, isOnWaitlist, cart]);

  const onClickFinalise = async () => {
    const signupServices = {
      services: [Service.WAITLIST],
      primary_service: Service.WAITLIST,
    };
    await updateSignup(signupServices);

    const isSignupFinalised = await finaliseSignup();

    if (isSignupFinalised) {
      const finalisedSignup = getSignup();

      navigate(
        formatRoute(SignupRoute.CompletePage, {
          id: finalisedSignup.id,
        })
      );
    }
  };

  const isProductPurchasable = useMemo(() => {
    return (
      product.can_be_purchased &&
      !isWaitListAvailable &&
      product.max_quantity_per_cart !== 0
    );
  }, [product, isWaitListAvailable]);

  return (
    <CardMachine
      direction="row"
      imageUrl={getProductImage(product)}
      imageAlt="Card machine"
      item={product}
      badgeText={
        product.reason_not_available === ReasonNotAvailable.OUT_OF_STOCK
          ? "Sold Out"
          : product.ribbon_text
      }
      badgeAccentColor={
        product.reason_not_available
          ? Color.Orange
          : getRibbonColor(product.ribbon_color)
      }
      calloutLabel={
        isAvailableOnTerms ? "Available over 3 instalments" : undefined
      }
      priceValue={product.unit_price}
      priceStrikeSuperscriptText={product.is_discounted}
      priceSuperscriptText={
        product.is_discounted
          ? `Was ${product.original_unit_price_formatted}`
          : undefined
      }
      priceSideScriptText="Once Off"
      onAddedToCart={onAddedProductToCart}
      isPurchasable={isProductPurchasable}
      // below props are to support:
      // - out of stock
      // - wait list
      // - not available (!isPurchasable)
      // but will only show if isPurchasable is false
      ctaOnClick={
        canFinaliseSignup
          ? onClickFinalise
          : isWaitListAvailable
          ? onAddToWaitlist
          : () => {}
      }
      ctaText={
        isExperimentalOutOfStockProduct
          ? "Out of stock"
          : canFinaliseSignup
          ? "Complete signup"
          : isWaitListAvailable
          ? `Reserve your ${product.short_name}`
          : "Not available"
      }
      ctaDisabled={
        (isExperimentalOutOfStockProduct || !isProductPurchasable) &&
        !isWaitListAvailable // ToDo: Cleanup these conditionals
      }
    />
  );
};

export default ProductBlock;
