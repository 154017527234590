import React from "react";
import { PaymentMethod } from "@/shared/shop/api";
import { makeTestID } from "@/shared/utils/development";
import { useCartStore } from "@/shop/store";
import { PaymentMethodProps } from "@/shop/types";
import DynamicSecondaryPaymentMethod from "./DynamicSecondaryPaymentMethod";
import SecondaryPaymentMethodNavItem from "./SecondaryPaymentMethodNavItem";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  intendedPaymentMethod: useCartStore(state => state.intendedPaymentMethod),
  setIntendedPaymentMethod: useCartStore(
    state => state.setIntendedPaymentMethod
  ),
  availablePaymentMethods: useCartStore(
    state => state.cart?.available_payment_methods
  ),
});

const CreditCardPaymentMethod: React.FC<PaymentMethodProps> = ({
  onSuccess,
}: PaymentMethodProps) => {
  const {
    availablePaymentMethods,
    cart,
    intendedPaymentMethod,
    setIntendedPaymentMethod,
  } = useStore();

  if (availablePaymentMethods?.includes(PaymentMethod.CREDIT_CARD_CHECKOUT)) {
    setIntendedPaymentMethod(PaymentMethod.CREDIT_CARD_CHECKOUT);
  }

  const balance = cart?.balance_due_formatted;
  const instalmentPlanProposal = cart?.instalment_plan_proposal;
  const deposit = instalmentPlanProposal?.instalments[0];
  const minMonthlyInstalment = deposit?.amount_formatted;
  const navItems = [
    {
      paymentMethod: PaymentMethod.CREDIT_CARD_CHECKOUT,
      label: "Pay now",
      subLabel: { value: balance, suffix: "once-off" },
      description: "Online with your card or instant eft.",
    },
    {
      paymentMethod: PaymentMethod.CARD_ON_DELIVERY,
      label: "Pay on delivery",
      subLabel: { value: balance, suffix: "once-off" },
      description: "The courier will take card payment when they deliver.",
    },
    {
      paymentMethod: PaymentMethod.PAYMENT_TERMS,
      label: "Instalments",
      subLabel: { value: minMonthlyInstalment, suffix: "per month" },
    },
  ];

  const visibleNavItems = navItems.filter(navItem =>
    availablePaymentMethods?.includes(navItem.paymentMethod)
  );

  const onChangePaymentMethod = (paymentMethod: PaymentMethod) => {
    setIntendedPaymentMethod(paymentMethod);
  };

  return (
    <>
      {visibleNavItems.length > 1 ? (
        <div className="flex flex-col gap-10-v2 md-v2:gap-24-v2">
          {visibleNavItems.map(navItem => (
            <SecondaryPaymentMethodNavItem
              key={navItem.paymentMethod}
              name="secondary_payment_method"
              value={navItem.paymentMethod}
              label={navItem.label}
              description={navItem.description}
              subLabel={navItem.subLabel}
              onChange={() => onChangePaymentMethod(navItem.paymentMethod)}
              checked={intendedPaymentMethod === navItem.paymentMethod}
              data-testid={makeTestID(
                "payment",
                "secondaryMethod",
                navItem.label
              )}
            />
          ))}
        </div>
      ) : null}

      <DynamicSecondaryPaymentMethod
        name={intendedPaymentMethod}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default CreditCardPaymentMethod;
