import { Color } from "../../../constants/color";
import Typography from "../elements/Typography";

type Props = { title: string };

/**
 * Common title for form inputs
 *
 * @example
 * ```tsx
 *  <div>
 *      <FormItemTitle title="How do you plan on using Yoco?" />
 *      <MyInput />
 *  </div>
 * ```
 *
 */
const FormItemTitle = ({ title }: Props) => {
  return (
    <div className="mb-10-v2 sm:mb-16-v2">
      <Typography
        text={title}
        font="grotesk"
        size="body-lg"
        color={Color.Black}
        weight="medium"
      />
    </div>
  );
};

export default FormItemTitle;
