/*  receives a number in the form of a string or number as an input
    and returns the same value with 2 decimal places if the value has decimals i.e 100.33 will return R100.33
    can also recieve a boolean that determines whether to show decimal values depending on design requirements i.e R0.00 needs to be rendered even though the decimals are not greater than 0
    can also recieve a boolean that determines whether to have a thousands seperator comma i.e x,xxx.xx but defaulting to false
    can also receive a currency as a string but defaulting to ZAR i.e Rxxx.xxx */
interface FormatPriceOptions {
  shouldShowDecimals?: boolean;
  currency?: string;
  hasThousandsSeparator?: boolean;
}

export const formatPrice = (
  price: number | string,
  givenOptions?: FormatPriceOptions
): string => {
  const options = {
    shouldShowDecimals: false,
    currency: "R",
    hasThousandsSeparator: false,
    ...givenOptions,
  };

  const numPrice = typeof price === "string" ? parseFloat(price) : price;

  const decimalPlaces =
    numPrice % 1 !== 0 || options.shouldShowDecimals ? 2 : 0;

  let strPrice = numPrice.toFixed(decimalPlaces);

  if (options.hasThousandsSeparator) {
    strPrice = strPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return options.currency.concat(strPrice);
};
