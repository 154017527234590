/* eslint-disable jsx-a11y/anchor-is-valid */
// V2 Imports
import Layout from "../../../shared/components/Layout";
import FormHeader from "../../../shared/components/checkout/FormHeader";
import Typography from "../../../shared/components/elements/Typography";
import Link from "../../../shared/components/elements/Link";
import { Color } from "../../../constants/color";

type Props = {
  type: "shop" | "signup";
};

const NotFoundPage = ({ type }: Props) => {
  return (
    <Layout>
      <FormHeader title="404 - Page not found" />
      <Link link={`/${type}`}>
        <Typography
          text={`Back to ${type}`}
          color={Color.Blue}
          size="body-lg"
          font="grotesk"
          weight="book"
        />
      </Link>
    </Layout>
  );
};

export default NotFoundPage;
