import classNames from "classnames";

type SectionProps = {
  className?: string;
  children: React.ReactNode;
};

const Section = ({ children, className }: SectionProps) => {
  return (
    <section
      className={classNames(
        "px-gutter-xs-v2 sm-v2:px-gutter-sm-v2 md-v2:px-gutter-md-v2 lg-v2:px-gutter-lg-v2 xl-v2:px-gutter-xl-v2",
        className
      )}
    >
      {children}
    </section>
  );
};

export default Section;
