import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";

import Segment from "@/shared/services/Segment";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import { useSignupStore, useStepStore } from "@/signup/store";
import Layout from "../../../shared/components/Layout";

import DropdownInput from "../../../shared/components/elements/Inputs/DropdownInput";
import FormItemTitle from "../../../shared/components/checkout/FormItemTitle";
import TextInput from "../../../shared/components/elements/Inputs/TextInput";
import FormHeader from "../../../shared/components/checkout/FormHeader";
import SubmitButton from "../../../shared/components/checkout/SubmitButton";
import { makeTestID } from "../../../shared/utils/development";

const QUESTION_LABELS = {
  card_machine_use: "How do you plan on using Yoco?",
  approximate_volume_of_card_payments:
    "What is your total expected volume of card payments per month?",
  lead_source: "Where did you hear about Yoco?",
};

const CARD_PAYMENT_VOLUME_CHOICES = [
  {
    value: "",
    label: "Select an option",
  },
  {
    value: "Less than R10,000",
    label: "Less than R10,000",
  },
  {
    value: "R10,000 - R50,000",
    label: "R10,000 - R50,000",
  },
  {
    value: "R50,000 - R100,000",
    label: "R50,000 - R100,000",
  },
  {
    value: "R100,000 - R200,000",
    label: "R100,000 - R200,000",
  },
  {
    value: "R200,000 - R500,000",
    label: "R200,000 - R500,000",
  },
  {
    value: "R500,000 - R1,000,000",
    label: "R500,000 - R1,000,000",
  },
  {
    value: "R1,000,000 +",
    label: "R1,000,000 +",
  },
];

const CARD_MACHINE_USE_CHOICES = [
  {
    value: "",
    label: "Select an option",
  },
  {
    value: "switching_provider",
    label: "I am switching from an existing provider to Yoco",
  },
  {
    value: "backup_or_events",
    label: "I plan on using Yoco as a backup or for events",
  },
  {
    value: "new_to_card_payments",
    label: "I am new to card payments",
  },
];

const useStore = () => ({
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

const BusinessPreQualificationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { signup, updateSignup, getNextStepLink } = useStore();

  useEffect(() => {
    Segment.track("web_signup_business_pre_qualification_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.BusinessPreQualificationPage,
    card_machine_use:
      signup?.card_machine_use || CARD_MACHINE_USE_CHOICES[0].value,
    approximate_volume_of_card_payments:
      signup?.approximate_volume_of_card_payments ||
      CARD_PAYMENT_VOLUME_CHOICES[0].value,
    lead_source: Array.isArray(signup?.pre_qualification?.questions)
      ? signup?.pre_qualification?.questions?.find(
          item => item.question == QUESTION_LABELS.lead_source
        )?.answer
      : "",
  };

  const onSubmit = async (values: FormikValues) => {
    const isSignupUpdated = await updateSignup({
      ...values,
      pre_qualification: {
        ...signup?.pre_qualification,
        version: "1.0.1",
        source: "signup",
        questions: [
          {
            answer: values.card_machine_use,
            order: 1,
            question: QUESTION_LABELS.card_machine_use,
          },
          {
            answer: values.approximate_volume_of_card_payments,
            order: 2,
            question: QUESTION_LABELS.approximate_volume_of_card_payments,
          },
          {
            answer: values.lead_source,
            order: 3,
            question: QUESTION_LABELS.lead_source,
          },
        ],
      },
    });

    if (isSignupUpdated) {
      Segment.trackWithSignup(
        "web_signup_business_pre_qualification_next_button_clicked"
      );

      navigate(getNextStepLink());
    }
  };

  const schema = yup.object({
    card_machine_use: yup
      .string()
      .oneOf(["switching_provider", "backup_or_events", "new_to_card_payments"])
      .required("You must select an option."),
    approximate_volume_of_card_payments: yup
      .string()
      .required("You must select an option."),
    lead_source: yup
      .string()
      .label("Heard about Yoco")
      .required("This field is required.")
      .max(25, "Cannot be longer than 25 characters"),
  });

  return (
    <Layout>
      <FormHeader
        title="We'd like to know a bit more about you"
        description="These questions help us better serve you"
      />
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <div className="flex flex-col gap-32-v2">
                <div>
                  <FormItemTitle title={QUESTION_LABELS.card_machine_use} />
                  <DropdownInput
                    id="card_machine_use"
                    name="card_machine_use"
                    label="Select an option"
                    options={CARD_MACHINE_USE_CHOICES}
                  />
                </div>
                <div>
                  <FormItemTitle
                    title={QUESTION_LABELS.approximate_volume_of_card_payments}
                  />
                  <DropdownInput
                    id="approximate_volume_of_card_payments"
                    name="approximate_volume_of_card_payments"
                    label="Select an option"
                    options={CARD_PAYMENT_VOLUME_CHOICES}
                  />
                </div>
                <div>
                  <FormItemTitle title={QUESTION_LABELS.lead_source} />
                  <TextInput
                    type="text"
                    id="lead_source"
                    name="lead_source"
                    maxLength={25}
                  />
                </div>
                <SubmitButton
                  testID={makeTestID("businessPreQualification", "next")}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default withFetchSignup(
  BusinessPreQualificationPage,
  SignupStep.BusinessPreQualificationPage
);
