// ToDo decide if we're keeping this for V2 design

import { percentage } from "@/shared/utils/helpers/general";
import React from "react";

const hasNumber = (password: string) => /[0-9]/.test(password);

const hasMixedCase = (password: string) =>
  /[a-z]/.test(password) && /[A-Z]/.test(password);

const hasSpecialChar = (password: string) => /[!#@$%^&*)(+=._-]/.test(password);

const calculateStrength = (password: string) => {
  let strength = 1;
  if (password.length < 8) {
    return strength;
  }
  if (password.length > 8) {
    strength += 1;
  }
  if (password.length > 12) {
    strength += 1;
  }
  if (password.length > 16) {
    strength += 1;
  }
  if (hasNumber(password)) {
    strength += 1;
  }
  if (hasSpecialChar(password)) {
    strength += 1;
  }
  if (hasMixedCase(password)) {
    strength += 2;
  }
  return strength;
};

// ToDo confirm if these colours are appropriate for v2
const passwordProperties = (strength: number) => {
  if (strength > 5) {
    return {
      label: "Strong",
      backgroundColor: "bg-green-v2",
    };
  }
  if (strength > 3) {
    return {
      label: "Good",
      backgroundColor: "bg-blue-v2",
    };
  }
  if (strength > 2) {
    return {
      label: "Okay",
      backgroundColor: "bg-yellow-v2",
    };
  }
  return {
    label: "Weak",
    backgroundColor: "bg-orange-v2",
  };
};

type Props = {
  password: string;
};

const PasswordStrengthBar: React.FC<Props> = ({ password }) => {
  const strength = password ? calculateStrength(password) : 0;
  const { label, backgroundColor } = password
    ? passwordProperties(strength)
    : { label: "", backgroundColor: "" };
  const calculatedPercentage = percentage(strength, 8);

  return (
    <div className="flex flex-col space-y-1 h-16-v2 mt-2-v2 place-items-end">
      <span className="bg-charcoal-v2/10 rounded-sm w-full h-4-v2">
        <div
          className={`${backgroundColor} rounded-sm h-1`}
          style={{ width: `${calculatedPercentage}%` }}
        />
      </span>
      {label && <span>{label}</span>}
    </div>
  );
};

export default PasswordStrengthBar;
