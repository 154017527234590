import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";

import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import {
  useFormHelperStore,
  useSignupStore,
  useStepStore,
} from "@/signup/store";
import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import FormHeader from "../../../shared/components/checkout/FormHeader";
import SubmitButton from "../../../shared/components/checkout/SubmitButton";
import DropdownInput from "../../../shared/components/elements/Inputs/DropdownInput";
import FormItemTitle from "../../../shared/components/checkout/FormItemTitle";
import Layout from "../../../shared/components/Layout";

const useStore = () => ({
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  businessTypes: useFormHelperStore(state => state.businessTypes),
  fetchBusinessTypes: useFormHelperStore(state => state.fetchBusinessTypes),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

const BusinessTypePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    signup,
    updateSignup,
    businessTypes,
    fetchBusinessTypes,
    getNextStepLink,
  } = useStore();

  useEffect(() => {
    fetchBusinessTypes();
  }, [fetchBusinessTypes]);

  useEffect(() => {
    Segment.track("web_signup_business_type_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.BusinessTypePage,
    business_type: signup?.business_type || "",
  };

  const schema = yup.object({
    business_type: yup
      .string()
      .required("You must select a business type.")
      .label("business type"),
  });

  const onSubmit = async (values: FormikValues) => {
    const isSignupUpdated = await updateSignup(values);

    if (isSignupUpdated) {
      Segment.trackWithSignup("web_signup_business_type_next_button_clicked");

      navigate(getNextStepLink());
    }
  };

  return (
    <Layout>
      <FormHeader
        title="How is your business structured?"
        description="Pick a category below that best describes how your business is owned and operated:"
      />

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <div className="flex flex-col gap-32-v2">
                <div>
                  <FormItemTitle title="Select your business type" />
                  <DropdownInput
                    id="business_type"
                    name="business_type"
                    label="Select an option"
                    options={businessTypes}
                    data-testid={makeTestID("businessType")}
                  />
                </div>

                <SubmitButton testID={makeTestID("businessType", "next")} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default withFetchSignup(BusinessTypePage, SignupStep.BusinessTypePage);
