import React from "react";
import { Helmet } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";
import Page from "@/shop/components/Page";
import CartPage from "@/shop/pages/CartPage";
import CompletePage from "@/shop/pages/CompletePage";
import { useAppConfigStore } from "@/shop/store";
import { NotFoundPage } from "@/signup/pages";
import HomePage from "@/shop/pages/HomePage";
import ExistingBusinessHomePage from "@/shop/pages/ExistingBusinessHomePage";
import ExistingBusinessDeliveryPage from "@/shop/pages/ExistingBusinessDeliveryPage";
import DeliveryPage from "@/shop/pages/DeliveryPage";
import PaymentPage from "@/shop/pages/PaymentPage";
import StitchInstantEFTResultPage from "@/shop/pages/StitchInstantEFTResultPage";

const App = () => {
  window.yc = window.yc || {};
  window.yc.config = window.yc.config || {};
  // @ts-ignore
  window.yc.appConfigStore = useAppConfigStore;

  return (
    <Page>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="business/:businessUUID"
          element={<ExistingBusinessHomePage />}
        />
        <Route
          path="business/:businessUUID/:id"
          element={<ExistingBusinessDeliveryPage />}
        />
        <Route path=":id/cart" element={<CartPage />} />
        <Route path=":id/delivery" element={<DeliveryPage />} />
        <Route path=":id/payment" element={<PaymentPage />} />
        <Route path=":id/complete" element={<CompletePage />} />
        <Route
          path="stitch_instant_eft_result"
          element={<StitchInstantEFTResultPage />}
        />
        <Route path="*" element={<NotFoundPage type="shop" />} />
      </Routes>
    </Page>
  );
};

export default App;
