import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import Validator from "@/signup/services/Validator";
import { useSignupStore } from "@/signup/store";
import { SignupRoute } from "@/signup/types";
import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import * as yup from "yup";
// V2 Components
import Layout from "../../../shared/components/Layout";
import FormHeader from "../../../shared/components/checkout/FormHeader";
import SubmitButton from "../../../shared/components/checkout/SubmitButton";
import TextInput from "../../../shared/components/elements/Inputs/TextInput";
import Field from "../../../shared/components/elements/Inputs/Field";

const useStore = () => ({
  signup: useSignupStore(state => state.signup),
  finaliseSignup: useSignupStore(state => state.finaliseSignup),
});

const RegisterReaderPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signup, finaliseSignup } = useStore();

  useEffect(() => {
    Segment.track("web_signup_retail_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.RegisterReaderPage,
    serial_number: signup?.serial_number || "",
  };

  yup.addMethod(
    yup.string,
    "serialNumberAvailable",
    Validator.validateSerialNumberAvailable
  );

  const schema = yup.object({
    serial_number: (yup as any)
      .string()
      .serialNumberAvailable()
      .required()
      .label("serial number"),
  });

  const onSubmit = async (values: FormikValues) => {
    const isSignupFinalised = await finaliseSignup({
      serial_number: values.serial_number,
    });

    if (isSignupFinalised) {
      Segment.trackWithSignup(
        "web_signup_retail_click_activate_card_machine_button"
      );

      navigate(
        formatRoute(SignupRoute.CompletePage, {
          id: signup?.id,
        })
      );
    }
  };

  return (
    <Layout>
      <FormHeader
        title="Enter your serial number"
        description="The serial number appears on the back of your card machine, to the left of the bar code."
      />

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <div className="flex flex-col w-full gap-32-v2">
              <Field
                component={TextInput}
                type="text"
                name="serial_number"
                id="serial_number"
                label="Serial number"
                placeholder="1234"
                data-testid={makeTestID("registerReader", "serialNumber")}
              />

              <SubmitButton
                text="Activate card machine"
                testID={makeTestID("registerReader", "activate")}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default withFetchSignup(
  RegisterReaderPage,
  SignupStep.RegisterReaderPage
);
