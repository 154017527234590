// ToDo V2 Design
import React from "react";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  subLabel: string | React.ReactElement;
  description?: string | React.ReactElement;
  checked: boolean;
  value: string;
};

const SecondaryPaymentMethodNavItem: React.FC<Props> = ({
  label,
  subLabel,
  description,
  checked,
  ...props
}: Props) => {
  return (
    <div className="bg-white flex-1 rounded-lg pr-2 mb-2 flex">
      <label className="flex items-center flex-1">
        <input
          type="radio"
          className="focus:ring-0 focus:ring-offset-0 text-yoco-green-700 ml-4"
          style={{
            position: "absolute",
            width: 18,
            height: 18,
            zIndex: 5,
          }}
          checked={checked}
          {...props}
        />
        <div
          className={`flex flex-1 h-28 shadow border-2 border-transparent rounded-lg px-4 py-3 justify-between items-start justify-start ${
            checked ? "option-selected" : ""
          }`}
        >
          <div className="flex-initial shrink justify-center content-center">
            <div className="block font-bold text-base tracking-tight" />
          </div>

          <div className="flex-1 shrink pl-4 flex items-center">
            <div className="flex flex-col space-y-1">
              <span className="font-bold text-sm flex-1">{label}</span>
              <span className="text-xs pr-5"> {description}</span>
            </div>
            <span className="text-sm -pt-2 break-words flex-1 text-right mb-6 pl-4">
              {subLabel}
            </span>
          </div>
        </div>
      </label>
    </div>
  );
};

export default SecondaryPaymentMethodNavItem;
