import classNames from "classnames";

type LogoIconProps = { className?: string; alt?: string };

const LogoIcon = ({ className, alt }: LogoIconProps) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 79 30"
      aria-labelledby={alt || ""}
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("group", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7498 14.8992C25.7498 17.2738 27.1588 18.4285 29.5211 18.4285C31.8833 18.4285 33.3338 17.4045 33.3338 14.8992C33.3338 12.3285 31.8833 11.2174 29.5417 11.2174C27.1794 11.2174 25.7498 12.3939 25.7498 14.8775V14.8992ZM29.5856 6.08936C31.4206 6.08936 33.0589 6.43639 34.4571 7.10735C37.2971 8.49534 38.9793 11.1098 38.9793 14.8346V14.9271C38.9793 16.8704 38.586 18.4668 37.7995 19.7625C36.2268 22.3304 33.343 23.5568 29.5417 23.5568C27.6194 23.5568 25.959 23.2559 24.5172 22.6312C21.6772 21.3819 20.1042 18.8833 20.1042 14.9041V14.8577C20.1042 13.0068 20.5194 11.4336 21.3058 10.1381C22.9224 7.50062 25.828 6.08936 29.5856 6.08936ZM12.0231 12.7237L15.7894 6.50583H22.0433L14.7897 18.398V23.0941H9.04738V18.3747L2.14258 6.50583H8.41953L12.0231 12.7237ZM66.2553 18.4285C63.8929 18.4285 62.484 17.2738 62.484 14.8992V14.8775C62.484 12.3939 63.9137 11.2174 66.2759 11.2174C68.6174 11.2174 70.068 12.3285 70.068 14.8992C70.068 17.4045 68.6174 18.4285 66.2553 18.4285ZM71.1914 7.10735C69.7933 6.43639 68.1548 6.08936 66.3198 6.08936C62.5624 6.08936 59.6567 7.50062 58.0401 10.1381C57.2536 11.4336 56.8386 13.0068 56.8386 14.8577V14.9041C56.8386 18.8833 58.4113 21.3819 61.2515 22.6312C62.6932 23.2559 64.3536 23.5568 66.276 23.5568C70.0772 23.5568 72.9611 22.3304 74.5339 19.7625C75.3202 18.4668 75.7137 16.8704 75.7137 14.9271V14.8346C75.7137 11.1098 74.0314 8.49534 71.1914 7.10735ZM41.3038 10.1381C42.9205 7.50062 45.8259 6.08936 49.5835 6.08936C51.4185 6.08936 53.057 6.43639 54.455 7.10735C54.9076 7.3285 55.3304 7.58105 55.7219 7.86405V13.114C55.7219 13.114 52.8547 11.2174 50.3208 11.2174C47.9584 11.2174 46.06 12.394 46.06 14.8776V14.8994C46.06 17.274 47.9378 18.4285 50.3 18.4285C52.8933 18.4285 55.7219 16.5323 55.7219 16.5323V21.937C54.129 23.0253 52.0337 23.5568 49.5398 23.5568C47.6173 23.5568 45.9569 23.2561 44.5152 22.6314C41.6752 21.3821 40.1023 18.8833 40.1023 14.9041V14.8577C40.1023 13.007 40.5174 11.4337 41.3038 10.1381Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LogoIcon;
