import { makeTestID } from "../../../shared/utils/development";
import FormItemTitle from "../../../shared/components/checkout/FormItemTitle";
import TextInput from "../../../shared/components/elements/Inputs/TextInput";
import Typography from "../../../shared/components/elements/Typography";
import { Color } from "../../../constants/color";

type Props = {
  name: string;
  title: string;
  subtitle: string;
  className?: string;
  id: string;
};

const NumberField = ({ name, title, subtitle, className = "", id }: Props) => (
  <div className={`${className}`}>
    <FormItemTitle title={title} />
    <Typography
      text={subtitle}
      font="grotesk"
      size="body-sm"
      color={Color.Black}
      weight="book"
    />
    <div className="flex flex-row pb-2 pt-2">
      <div className="w-full">
        <TextInput
          type="text"
          id={name}
          name={name}
          placeholder="000 0000"
          data-testid={makeTestID("registerReader", id)}
        />
      </div>
    </div>
  </div>
);

export default NumberField;
