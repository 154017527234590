import classNames from "classnames";
import Typography from "./Typography";
import { Color } from "../../../constants/color";

type LoaderIconProps = { width: number; stroke?: number; className?: string };

export const LoaderIcon = ({
  width,
  className,
  stroke = 4,
}: LoaderIconProps) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("m-auto bg-none block animate-spin", className)}
    >
      <circle
        r="32"
        cx="50"
        cy="50"
        fill="none"
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke="currentColor"
        strokeDasharray="50.26548245743669 50.26548245743669"
      />
    </svg>
  );
};

type LoaderProps = {
  text?: string;
  className?: string;
  size?: "sm" | "lg";
};

const Loader = ({
  className,
  size = "sm",
  text = "Loading...",
}: LoaderProps) => {
  const width = size === "sm" ? 60 : 150;

  return (
    <div
      className={classNames("flex flex-col gap-8-v2 items-center", className)}
    >
      <LoaderIcon width={width} className="text-blue-v2" stroke={8} />
      <Typography
        text={text}
        element="p"
        weight="book"
        font="grotesk"
        size="body-lg"
        color={Color.Black}
      />
    </div>
  );
};

export default Loader;
