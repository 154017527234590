import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
// ToDo V2 Design
import Loader from "@/shared/components/V1/Loader";
import shop, { PaymentMethod } from "@/shared/shop/api";
import { makeTestID } from "@/shared/utils/development";
import { toRoute } from "@/shared/utils/url";
import { useCartStore } from "@/shop/store";
import { PaymentMethodProps, ShopRoute } from "@/shop/types";
import React, { useEffect, useState } from "react";
import PayButton from "../PayButton";
import EFTDetails from "./EFTDetails";

enum InstantEFTState {
  INITIAL,
  GENERATING_PAYMENT_LINK,
  READY,
}

const useStore = () => ({
  cart: useCartStore(state => state.cart),
});

const InstantEFTPaymentMethod: React.FC<PaymentMethodProps> = ({
  onSuccess,
}: PaymentMethodProps) => {
  const { cart } = useStore();
  const [instantEFTState, setInstantEFTState] = useState<InstantEFTState>(
    InstantEFTState.INITIAL
  );
  const [instantEFTPaymentUrl, setInstantEFTPaymentUrl] = useState<
    string | null
  >(null);

  useEffect(() => {
    const onIFrameMessageReceived = async message => {
      // The StitchInstantEFTResultPage component is hosted within an iFrame.  When the user returns to us from Stitch,
      // we render this "page" and emit a message with the status and the payment request id.
      const event = message.data?.event;

      if (event === "RECEIVED_STITCH_INSTANT_EFT_RESULT") {
        if (message.data.status === "complete") {
          if (onSuccess) {
            onSuccess(PaymentMethod.INSTANT_EFT_STITCH, true);
          }
        } else {
          setInstantEFTState(InstantEFTState.INITIAL);
        }
      }
    };

    window.addEventListener("message", onIFrameMessageReceived);

    return () => {
      window.removeEventListener("message", onIFrameMessageReceived);
    };
  }, [onSuccess]);

  const onSubmit = async () => {
    setInstantEFTState(InstantEFTState.GENERATING_PAYMENT_LINK);

    const returnUrl = toRoute(ShopRoute.StitchInstantEFTResultPage);

    try {
      const paymentLink = await shop.carts.generateStitchInstantEFTPaymentLink(
        cart?.id || "",
        returnUrl
      );

      setInstantEFTPaymentUrl(paymentLink);
      setInstantEFTState(InstantEFTState.READY);
    } catch (error) {
      setInstantEFTState(InstantEFTState.INITIAL);
    }
  };

  return (
    <>
      <Formik onSubmit={onSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form>
              {instantEFTState === InstantEFTState.INITIAL && (
                <>
                  <EFTDetails
                    header="Stitch is a secure payment solution that facilitates instant
                    EFT payments from all major South African banks"
                    clickPrompt="Pay by EFT with Stitch"
                  />
                  <PayButton
                    type="submit"
                    testID={makeTestID("payment", "payByInstantEft")}
                    isSubmitting={isSubmitting}
                    textOverride="Pay by EFT"
                  />
                </>
              )}
            </Form>
          );
        }}
      </Formik>

      {instantEFTState === InstantEFTState.GENERATING_PAYMENT_LINK && (
        <Loader />
      )}

      {instantEFTState === InstantEFTState.READY && instantEFTPaymentUrl && (
        <iframe
          title="Stitch InstantEFT"
          src={instantEFTPaymentUrl}
          scrolling="no"
          style={{
            overflow: "hidden",
            width: "100%",
            height: "600px",
          }}
        />
      )}
    </>
  );
};

export default InstantEFTPaymentMethod;
