// ToDo V2 Design
import Button from "@/shared/components/V1/Button";
import { FormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";

type Props = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type"> & {
  children: React.ReactNode;
  block?: boolean;
  disabled?: boolean;
  submitting?: boolean;
  onClick?: () => void;
  testID?: string;
};

const SubmitButton: React.FC<Props> = ({
  children,
  block,
  disabled,
  submitting,
  onClick,
  testID,
  ...props
}: Props) => {
  const [isSubmitting, setSubmitting] = useState<boolean | undefined>(
    submitting
  );
  const formikContext = useContext(FormikContext);
  const isFormikSubmitting = formikContext?.isSubmitting;

  useEffect(() => {
    // We allow the SubmitButton component to be used both inside and outside the context of Formik.
    // If the user has passed the submitting prop through and it's set to true, we'll use that value.
    // If we're within the context of Formik, we'll grab the submission state from Formik.
    if (submitting === true || isFormikSubmitting) {
      setSubmitting(true);
    } else {
      setSubmitting(false);
    }
  }, [submitting, isFormikSubmitting]);

  return (
    <Button
      type="submit"
      block={block}
      disabled={disabled}
      submitting={isSubmitting}
      onClick={onClick}
      testID={testID}
      {...props}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
