import { useCartStore } from "@/shop/store";
import React from "react";
import Typography from "../../../../elements/Typography";
import { Color } from "../../../../../../constants/color";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
});

const InstalmentPlan = () => {
  const { cart } = useStore();
  const proposal = cart?.instalment_plan_proposal;
  const deposit = proposal?.instalments[0];
  const instalments = proposal?.instalments.slice(1);
  return (
    <div className="mb-20-v2">
      <Typography
        font="grotesk"
        size="body-lg"
        text="Your card will be charged on the following payment dates"
        color={Color.Black}
        weight="medium"
        element="h3"
      />

      <div className="my-8-v2">
        <ol className="font-grotesk text-responsive-body-md-v2 list-decimal pl-16-v2 grid gap-4-v2">
          <li>
            <div className="flex flex-row items-stretch">
              <Typography
                font="grotesk"
                size="body-md"
                text="Today"
                color={Color.Black}
                weight="book"
                className="flex-1"
              />
              <Typography
                font="grotesk"
                size="body-md"
                text={deposit?.amount_formatted ?? ""}
                color={Color.Black}
                weight="book"
                className="flex-right"
              />
            </div>
          </li>
          {instalments?.map(instalment => (
            <li key={instalment.number}>
              <div className="flex flex-row items-stretch">
                <Typography
                  font="grotesk"
                  size="body-md"
                  text={instalment.payment_date.toString()}
                  color={Color.Black}
                  weight="book"
                  className="flex-1"
                />
                <Typography
                  font="grotesk"
                  size="body-md"
                  text={instalment.amount_formatted}
                  color={Color.Black}
                  weight="book"
                  className="text-right"
                />
              </div>
            </li>
          ))}
        </ol>
        <div className="flex flex-row items-stretch mt-8-v2">
          <Typography
            font="grotesk"
            size="body-md"
            text="Total due:"
            color={Color.Black}
            weight="medium"
            className="flex-1"
          />
          <Typography
            font="grotesk"
            size="body-md"
            text={proposal?.amount_with_service_fee_formatted || ""}
            color={Color.Black}
            weight="medium"
            className="flex-right"
          />
        </div>
      </div>

      <Typography
        font="grotesk"
        size="body-md"
        text={`Please note: Our instalment payment option includes a service fee of 
        ${proposal?.service_fee_formatted}.`}
        color={Color.Black}
        weight="book"
        className="italic opacity-60"
      />
    </div>
  );
};

export default InstalmentPlan;
