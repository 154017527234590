import { FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import * as yup from "yup";
import AddressFormFields, {
  getValidationSchema as getShippingAddressValidationSchema,
} from "@/shared/components/elements/Inputs/Address/AddressFormFields";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import { Cart } from "@/shared/shop/api";
import { Address } from "@/shared/types";
import { makeTestID } from "@/shared/utils/development";
import withFetchCart from "@/shop/middleware/withFetchCart";
import withRedirectToCartPageIfCartEmpty from "@/shop/middleware/withRedirectToCartPageIfCartEmpty";
import { useCartStore } from "@/shop/store";
import { ShopRoute } from "@/shop/types";
import { getShippingAddressFromCart } from "@/shop/utils/address";
import LayoutOrderSummary from "@/shared/components/checkout/LayoutOrderSummary";
import Typography from "@/shared/components/elements/Typography";
import SubmitButton from "@/shared/components/checkout/SubmitButton";
import { Color } from "@/constants/color";
import TextInput from "@/shared/components/elements/Inputs/TextInput";
import { useStepShop } from "@/shop/store/steps";
import Validator from "@/signup/services/Validator";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  updateCart: useCartStore(state => state.updateCart),
});

type AddressFormValues = Omit<Address, "line1" | "line2"> & {
  address_line1: string | null;
  address_line2?: string | null;
};

type InitialShippingAddressValues = {
  initialShippingAddress: Address;
  initialShippingAddressFormValues: AddressFormValues;
};

const getInitialAddressFormValues = (address: Address): AddressFormValues => {
  return {
    address_line1: address.line1,
    address_line2: address.line2,
    suburb: address.suburb,
    city: address.city,
    province: address.province,
    postal_code: address.postal_code,
    country: address.country,
    latitude: address.latitude,
    longitude: address.longitude,
  };
};

const getInitialShippingAddressValues = (
  cart?: Cart | null
): InitialShippingAddressValues => {
  const initialShippingAddress = getShippingAddressFromCart(cart);
  const initialShippingAddressFormValues = getInitialAddressFormValues(
    initialShippingAddress
  );
  return {
    initialShippingAddress,
    initialShippingAddressFormValues,
  };
};

const DeliveryPage = () => {
  const navigate = useNavigate();
  const { cart, updateCart } = useStore();
  const { initialShippingAddress, initialShippingAddressFormValues } =
    getInitialShippingAddressValues(cart);

  const [address] = useState(initialShippingAddress);
  const useShopStore = () => ({
    steps: useStepShop(state => state.steps),
    activeStep: useStepShop(state => state.activeStep),
    setActiveStep: useStepShop(state => state.setActiveStep),
    getPreviousStep: useStepShop(state => state.getPreviousStep),
    getPreviousStepLink: useStepShop(state => state.getPreviousStepLink),
  });

  const { setActiveStep } = useShopStore();

  useEffect(() => {
    setActiveStep(ShopRoute.DeliveryPage);
  }, [setActiveStep]);

  const initialValues = {
    shipping_method: "courier",
    shipping_first_name: cart?.shipping_first_name || "",
    shipping_last_name: cart?.shipping_last_name || "",
    shipping_email: cart?.shipping_email || "",
    shipping_mobile_number: cart?.shipping_mobile_number || "",
    ...initialShippingAddressFormValues,
  };

  yup.addMethod(
    yup.string,
    "phoneNumberAvailable",
    Validator.phoneNumberAvailable
  );

  const schema = yup.object({
    shipping_first_name: yup.string().required().label("first name"),
    shipping_last_name: yup.string().required().label("last name"),
    shipping_email: yup.string().email().required().label("email"),
    shipping_mobile_number: (yup as any)
      .string()
      .phoneNumberAvailable()
      .required()
      .label("mobile number"),
    ...getShippingAddressValidationSchema(),
  });

  const onSubmit = async (values: FormikValues) => {
    const newValues = { ...values };

    // Set the specific shipping address field values from the generic form address fields
    newValues.shipping_address_line1 = newValues.address_line1;
    newValues.shipping_address_line2 = newValues.address_line2;
    newValues.shipping_suburb = newValues.suburb;
    newValues.shipping_city = newValues.city;
    newValues.shipping_province = newValues.province;
    newValues.shipping_postal_code = newValues.postal_code;
    newValues.shipping_country = newValues.country;
    newValues.shipping_latitude = newValues.latitude;
    newValues.shipping_longitude = newValues.longitude;

    const isCartUpdated = await updateCart(newValues);
    if (isCartUpdated.success) {
      navigate(
        formatRoute(ShopRoute.PaymentPage, {
          id: cart?.id,
        })
      );
    }
  };

  return (
    <LayoutOrderSummary
      title="Delivery"
      description="Where can we deliver your card machine?"
      isCartEditable
      mobileFooterVariant="next"
      showButtonTarget
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <div className="flex flex-col gap-32-v2">
                <div className="space-y-12-v2">
                  <div className="flex flex-wrap">
                    <div className="w-full sm:w-1/2 mb-3 sm:mb-0 sm:pr-2 rounded-sm">
                      <TextInput
                        name="shipping_first_name"
                        placeholder="First name"
                        label="First Name"
                        data-testid={makeTestID("delivery", "firstName")}
                        id="shipping_first_name"
                        type="text"
                      />
                    </div>
                    <div className="w-full sm:w-1/2 sm:pl-2 rounded-sm">
                      <TextInput
                        name="shipping_last_name"
                        placeholder="Last name"
                        label="Last Name"
                        data-testid={makeTestID("delivery", "lastName")}
                        id="shipping_last_name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap">
                    <div className="w-full sm:w-1/2 mb-3 sm:mb-0 sm:pr-2 rounded-sm">
                      <TextInput
                        name="shipping_email"
                        placeholder="Email"
                        label="Email"
                        data-testid={makeTestID("delivery", "email")}
                        id="shipping_email"
                        type="email"
                      />
                    </div>
                    <div className="w-full sm:w-1/2 sm:pl-2 rounded-sm">
                      <TextInput
                        name="shipping_mobile_number"
                        placeholder="Mobile number"
                        label="Mobile number"
                        data-testid={makeTestID("delivery", "mobileNumber")}
                        id="shipping_mobile_number"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <Typography
                    text="Delivery address"
                    font="grotesk"
                    size="body-lg-fixed"
                    color={Color.Black}
                    weight="medium"
                  />
                  <AddressFormFields address={address} />
                </div>
                <SubmitButton
                  portalled
                  testID={makeTestID("delivery", "next")}
                  type="submit"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </LayoutOrderSummary>
  );
};

export default withFetchCart(withRedirectToCartPageIfCartEmpty(DeliveryPage));
