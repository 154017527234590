import { Product } from "@yoco/shop-sdk";
import { Bundle } from "@yoco/shop-sdk/lib/types";
import yocoGoImage from "./yoco-go.png";
import yocoKhumoLadumaImage from "./yoco-khumo-laduma.png";
import yocoKhumoPrintImage from "./yoco-khumo-print.png";
import yocoKhumoImage from "./yoco-khumo.png";
import yocoNeoTouchImage from "./yoco-neo-touch.png";
import yocoNeoPlusImage from "./yoco-neo-plus.png";
import yocoNeoImage from "./yoco-neo.png";
import yocoProImage from "./yoco-pro.png";
import yocoIpadImage from "./yoco-ipad9.png";
import yocoIpadStandImage from "./yoco-ipad-stand.png";
import yocoKhumoPrintChargingStandImage from "./yoco-khumo-print-charging-stand.png";
import yocoCounterOnlyImage from "./counter-only.png";
import yocoPrinterImage from "./printer.png";
import yocoGoThumbImage from "./thumbs/yoco-go-thumb.png";
import yocoKhumoLadumaThumbImage from "./thumbs/yoco-khumo-laduma-thumb.png";
import yocoKhumoPrintThumbImage from "./thumbs/yoco-khumo-print-thumb.png";
import yocoKhumoThumbImage from "./thumbs/yoco-khumo-thumb.png";
import yocoNeoPlusThumbImage from "./thumbs/yoco-neo-plus-thumb.png";
import yocoNeoThumbImage from "./thumbs/yoco-neo-thumb.png";
import yocoNeoTouchThumbImage from "./thumbs/yoco-neo-touch.png";
import yocoProThumbImage from "./thumbs/yoco-pro-thumb.png";
import yocoIpadThumbImage from "./thumbs/yoco-ipad9-thumb.png";
import yocoIpadStandThumbImage from "./thumbs/yoco-ipad-stand-thumb.png";
import yocoKhumoPrintChargingStandThumbImage from "./thumbs/yoco-khumo-print-charging-stand-thumb.png";
import yocoCounterOnlyThumbImage from "./thumbs/counter-only-thumb.png";
import yocoPrinterThumbImage from "./thumbs/printer-thumb.png";
import counterBundleImage from "./bundles/counter-bundle.png";
import yocoKhumoPrintWithStandImage from "./bundles/khumo_print_and_stand.png";
import completePosImage from "./bundles/complete-pos.png";
import neoTouchAndStandImage from "./bundles/Neo_Touch_iPad_stand.png";
import counterBundleThumbImage from "./bundles/thumbs/counter-bundle.png";
import yocoKhumoPrintWithStandThumbImage from "./bundles/thumbs/khumo_print_and_stand.png";
import completePosThumbImage from "./bundles/thumbs/complete-pos.png";
import neoTouchAndStandThumbImage from "./bundles/thumbs/Neo_Touch_iPad_stand.png";

/**
 * Util that returns the product image based on SKU
 *
 * @todo these will eventually get replaced with image urls from the backend
 */
export const getProductThumbnailImage = (product: Product) => {
  switch (product.sku) {
    case "DSQ001":
      return yocoGoThumbImage;
    case "MIU010":
      return yocoProThumbImage;
    case "BLU55A":
      return yocoNeoThumbImage;
    case "KOZP5":
      return yocoKhumoThumbImage;
    case "KOZP12B":
      return yocoNeoTouchThumbImage;
    case "NXGN86":
      return yocoKhumoPrintThumbImage;
    case "KOZP5L":
      return yocoKhumoLadumaThumbImage;
    case "BLUE55P":
      return yocoNeoPlusThumbImage;
    case "IPAD9_WF":
      return yocoIpadThumbImage;
    case "TEXIS":
      return yocoIpadStandThumbImage;
    case "NXGCS":
      return yocoKhumoPrintChargingStandThumbImage;
    case "YOCO_CNTR":
      return yocoCounterOnlyThumbImage;
    case "PRNTR_S":
      return yocoPrinterThumbImage;
    default:
      return "";
  }
};

/**
 * Util that returns the product image thumbnails based on SKU
 *
 * @todo these will eventually get replaced with image thumbnail urls from the backend
 */
export const getProductImage = (product: Product) => {
  switch (product.sku) {
    case "DSQ001":
      return yocoGoImage;
    case "MIU010":
      return yocoProImage;
    case "BLU55A":
      return yocoNeoImage;
    case "KOZP5":
      return yocoKhumoImage;
    case "KOZP12B":
      return yocoNeoTouchImage;
    case "NXGN86":
      return yocoKhumoPrintImage;
    case "KOZP5L":
      return yocoKhumoLadumaImage;
    case "BLUE55P":
      return yocoNeoPlusImage;
    case "IPAD9_WF":
      return yocoIpadImage;
    case "TEXIS":
      return yocoIpadStandImage;
    case "NXGCS":
      return yocoKhumoPrintChargingStandImage;
    case "YOCO_CNTR":
      return yocoCounterOnlyImage;
    case "PRNTR_S":
      return yocoPrinterImage;
    default:
      return "";
  }
};

/**
 * Util that returns the bundle image thumbnails based on SKU
 *
 * @todo these will eventually get replaced with image thumbnail urls from the backend
 */
export const getBundleImage = (bundle: Bundle) => {
  switch (bundle.bundle_code) {
    case "COUNTER_BUNDLE":
      return counterBundleImage;
    case "KHUMO_PRINT_AND_STAND":
      return yocoKhumoPrintWithStandImage;
    case "COMPLETE_POS_SOLUTION":
      return completePosImage;
    case "NEO_TOUCH_AND_STAND":
      return neoTouchAndStandImage;
    default:
      return "";
  }
};

/**
 * Util that returns the bundle image thumbnails based on SKU
 *
 * @todo these will eventually get replaced with image thumbnail urls from the backend
 */
export const getBundleThumbnailImage = (bundle: Bundle) => {
  switch (bundle.bundle_code) {
    case "COUNTER_BUNDLE":
      return counterBundleThumbImage;
    case "KHUMO_PRINT_AND_STAND":
      return yocoKhumoPrintWithStandThumbImage;
    case "COMPLETE_POS_SOLUTION":
      return completePosThumbImage;
    case "NEO_TOUCH_AND_STAND":
      return neoTouchAndStandThumbImage;
    default:
      return "";
  }
};
