import { getQueryStringParams } from "@/shared/utils/url";

// TODO: R&D The type
export const getValueFromQueryStringOrWindowConfig = (key: string): any => {
  const queryStringParams = getQueryStringParams();
  const config = (window.yc && window.yc.config) || {};

  if (queryStringParams[key]) {
    return queryStringParams[key];
  }

  if (config[key]) {
    return config[key];
  }

  return null;
};

export const getString = (key: string): string | null => {
  return toString(getValueFromQueryStringOrWindowConfig(key));
};

export const toString = (value: string): string | null => {
  if (value === null) {
    return null;
  }

  const str = value.trim();

  return str === "" ? null : str;
};

export const getNumber = (key: string): number | null => {
  const value = getValueFromQueryStringOrWindowConfig(key);

  return value ? Number(value) : null;
};

export const getBool = (key: string): boolean => {
  return toBool(getValueFromQueryStringOrWindowConfig(key));
};

export const toBool = (value: string | number | boolean): boolean => {
  if (value === "true" || value === "1" || value === 1 || value === true) {
    return true;
  }

  if (value === "false" || value === "0" || value === 0 || value === false) {
    return false;
  }

  return false;
};

export const getArray = <T>(key: string): T[] => {
  const values = getValueFromQueryStringOrWindowConfig(key);

  if (values) {
    return Array.isArray(values) ? values : [values];
  }

  return [];
};
