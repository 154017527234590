import React, { useEffect } from "react";
import shop, { PaymentMethod } from "@/shared/shop/api";
import { useCartStore } from "@/shop/store";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  setCart: useCartStore(state => state.setCart),
});

type Props = {
  onSuccess?: (paymentMethod: PaymentMethod, isPaid?: boolean) => void;
};

const FreePaymentMethod = ({ onSuccess }: Props) => {
  const { cart, setCart } = useStore();

  useEffect(() => {
    const initiateFreePayment = async (id: string) => {
      const response = await shop.carts.initiateFreePayment(id);
      setCart(response);
      if (onSuccess) {
        onSuccess(response.payment_method);
      }
    };

    if (cart?.id) {
      initiateFreePayment(cart.id);
    }
  }, [cart?.id, setCart, onSuccess]);

  return null;
};

export default React.memo(FreePaymentMethod);
