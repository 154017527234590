import { Color } from "../constants/color";

export const getRibbonColor = (color?: string): Color => {
  switch (color) {
    case "green":
      return Color.Green;
    case "mint-green":
      return Color.MintGreen;
    case "orange":
      return Color.Orange;
    case "yellow":
      return Color.Yellow;
    case "light-blue":
      return Color.LightBlue;
    case "black":
      return Color.Black;
    case "white":
      return Color.White;
    case "charcoal":
      return Color.Charcoal;
    case "light-gray":
      return Color.LightGray;
    case "cream":
      return Color.Cream;
    case "pink":
      return Color.Pink;
    case "blue":
    default:
      return Color.Blue;
  }
};

export const getStrokeColorClass = (color?: Color): string => {
  switch (color) {
    case Color.Green:
      return "stroke-green-v2";
    case Color.MintGreen:
      return "stroke-mint-green-v2";
    case Color.Orange:
      return "stroke-orange-v2";
    case Color.Yellow:
      return "stroke-yellow-v2";
    case Color.Blue:
      return "stroke-blue-v2";
    case Color.LightBlue:
      return "stroke-light-blue-v2";
    case Color.Black:
      return "stroke-black-v2";
    case Color.White:
      return "stroke-white-v2";
    case Color.Charcoal:
      return "stroke-charcoal-v2";
    case Color.LightGray:
      return "stroke-light-gray-v2";
    case Color.Cream:
      return "stroke-cream-v2";
    case Color.Pink:
      return "stroke-pink-v2";
    default:
      return "";
  }
};

export const getTextColorClass = (color?: Color): string => {
  switch (color) {
    case Color.Green:
      return "text-green-v2";
    case Color.MintGreen:
      return "text-mint-green-v2";
    case Color.Orange:
      return "text-orange-v2";
    case Color.Yellow:
      return "text-yellow-v2";
    case Color.Blue:
      return "text-blue-v2";
    case Color.LightBlue:
      return "text-light-blue-v2";
    case Color.Black:
      return "text-black-v2";
    case Color.White:
      return "text-white-v2";
    case Color.Charcoal:
      return "text-charcoal-v2";
    case Color.LightGray:
      return "text-light-gray-v2";
    case Color.Cream:
      return "text-cream-v2";
    case Color.Pink:
      return "text-pink-v2";
    default:
      return "";
  }
};

export const getTextHoverColorClass = (color?: Color): string => {
  switch (color) {
    case Color.Green:
      return "hover:text-green-v2 group-hover:text-green-v2 focus-visible:text-green-v2 group-focus-visible:text-green-v2";
    case Color.MintGreen:
      return "hover:text-mint-green-v2 group-hover:text-mint-green-v2 focus-visible:text-mint-green-v2 group-focus-visible:text-mint-green-v2";
    case Color.Orange:
      return "hover:text-orange-v2 group-hover:text-orange-v2 focus-visible:text-orange-v2 group-focus-visible:text-orange-v2";
    case Color.Yellow:
      return "hover:text-yellow-v2 group-hover:text-yellow-v2 focus-visible:text-yellow-v2 group-focus-visible:text-yellow-v2";
    case Color.Blue:
      return "hover:text-blue-v2 group-hover:text-blue-v2 focus-visible:text-blue-v2 group-focus-visible:text-blue-v2";
    case Color.LightBlue:
      return "hover:text-light-blue-v2 group-hover:text-light-blue-v2 focus-visible:text-light-blue-v2 group-focus-visible:text-light-blue-v2";
    case Color.Black:
      return "hover:text-black-v2 group-hover:text-black-v2 focus-visible:text-black-v2 group-focus-visible:text-black-v2";
    case Color.White:
      return "hover:text-white-v2 group-hover:text-white-v2 focus-visible:text-white-v2 group-focus-visible:text-white-v2";
    case Color.Charcoal:
      return "hover:text-charcoal-v2 group-hover:text-charcoal-v2 focus-visible:text-charcoal-v2 group-focus-visible:text-charcoal-v2";
    case Color.LightGray:
      return "hover:text-light-gray-v2 group-hover:text-light-gray-v2 focus-visible:text-light-gray-v2 group-focus-visible:text-light-gray-v2";
    case Color.Cream:
      return "hover:text-cream-v2 group-hover:text-cream-v2 focus-visible:text-cream-v2 group-focus-visible:text-cream-v2";
    case Color.Pink:
      return "hover:text-pink-v2 group-hover:text-pink-v2 focus-visible:text-pink-v2 group-focus-visible:text-pink-v2";
    default:
      return "";
  }
};

export const getBackgroundColorClass = (color: Color): string => {
  switch (color) {
    case Color.Green:
      return "bg-green-v2";
    case Color.MintGreen:
      return "bg-mint-green-v2";
    case Color.Orange:
      return "bg-orange-v2";
    case Color.Yellow:
      return "bg-yellow-v2";
    case Color.Blue:
      return "bg-blue-v2";
    case Color.LightBlue:
      return "bg-light-blue-v2";
    case Color.Black:
      return "bg-black-v2";
    case Color.Charcoal:
      return "bg-charcoal-v2";
    case Color.LightGray:
      return "bg-light-gray-v2";
    case Color.Cream:
      return "bg-cream-v2";
    case Color.Pink:
      return "bg-pink-v2";
    case Color.White:
      return "bg-white-v2";
    default:
      return "";
  }
};

export const getBackgroundHoverColorClass = (color: Color): string => {
  switch (color) {
    case Color.Green:
      return "hover:bg-green-v2 focus-visible:bg-green-v2";
    case Color.MintGreen:
      return "hover:bg-mint-green-v2 focus-visible:bg-mint-green-v2";
    case Color.Orange:
      return "hover:bg-orange-v2 focus-visible:bg-orange-v2";
    case Color.Yellow:
      return "hover:bg-yellow-v2 focus-visible:bg-yellow-v2";
    case Color.Blue:
      return "hover:bg-blue-v2 focus-visible:bg-blue-v2";
    case Color.LightBlue:
      return "hover:bg-light-blue-v2 focus-visible:bg-light-blue-v2";
    case Color.Black:
      return "hover:bg-black-v2 focus-visible:bg-black-v2";
    case Color.Charcoal:
      return "hover:bg-charcoal-v2 focus-visible:bg-charcoal-v2";
    case Color.LightGray:
      return "hover:bg-light-gray-v2 focus-visible:bg-light-gray-v2";
    case Color.Cream:
      return "hover:bg-cream-v2 focus-visible:bg-cream-v2";
    case Color.Pink:
      return "hover:bg-pink-v2 focus-visible:bg-pink-v2";
    case Color.White:
      return "hover:bg-white-v2 focus-visible:bg-white-v2";
    default:
      return "";
  }
};

export const getBorderColorClass = (color: Color): string => {
  switch (color) {
    case Color.Green:
      return "border-green-v2";
    case Color.MintGreen:
      return "border-mint-green-v2";
    case Color.Orange:
      return "border-orange-v2";
    case Color.Yellow:
      return "border-yellow-v2";
    case Color.Blue:
      return "border-blue-v2";
    case Color.LightBlue:
      return "border-light-blue-v2";
    case Color.Black:
      return "border-black-v2";
    case Color.Charcoal:
      return "border-charcoal-v2";
    case Color.LightGray:
      return "border-light-gray-v2";
    case Color.Cream:
      return "border-cream-v2";
    case Color.Pink:
      return "border-pink-v2";
    case Color.White:
      return "border-white-v2";
    default:
      return "";
  }
};

export const getGradientFromColorClass = (color: Color): string => {
  switch (color) {
    case Color.Green:
      return "from-green-v2";
    case Color.MintGreen:
      return "from-mint-green-v2";
    case Color.Orange:
      return "from-orange-v2";
    case Color.Yellow:
      return "from-yellow-v2";
    case Color.Blue:
      return "from-blue-v2";
    case Color.LightBlue:
      return "from-light-blue-v2";
    case Color.Black:
      return "from-black-v2";
    case Color.Charcoal:
      return "from-charcoal-v2";
    case Color.LightGray:
      return "from-light-gray-v2";
    case Color.Cream:
      return "from-cream-v2";
    case Color.Pink:
      return "from-pink-v2";
    case Color.White:
      return "from-white-v2";
    default:
      return "";
  }
};

export const getGradientToColorClass = (color: Color): string => {
  switch (color) {
    case Color.Green:
      return "to-green-v2";
    case Color.MintGreen:
      return "to-mint-green-v2";
    case Color.Orange:
      return "to-orange-v2";
    case Color.Yellow:
      return "to-yellow-v2";
    case Color.Blue:
      return "to-blue-v2";
    case Color.LightBlue:
      return "to-light-blue-v2";
    case Color.Black:
      return "to-black-v2";
    case Color.Charcoal:
      return "to-charcoal-v2";
    case Color.LightGray:
      return "to-light-gray-v2";
    case Color.Cream:
      return "to-cream-v2";
    case Color.Pink:
      return "to-pink-v2";
    case Color.White:
      return "to-white-v2";
    default:
      return "";
  }
};

export const getAccentColor = (color: Color) => {
  switch (color) {
    case Color.Green:
    case Color.Charcoal:
    case Color.Blue:
    case Color.Black:
    case Color.Orange:
    case Color.Yellow:
      return Color.White;
    case Color.MintGreen:
    case Color.White:
    case Color.LightBlue:
    case Color.LightGray:
    case Color.Cream:
    case Color.Pink:
      return Color.Charcoal;
    default:
      return Color.Charcoal;
  }
};

export const getSliderThumbColorClass = (color: Color): string => {
  switch (color) {
    case Color.Black:
      return "[&::-webkit-slider-thumb]:bg-black-v2 [&::-moz-range-thumb]:bg-black-v2";
    case Color.White:
      return "[&::-webkit-slider-thumb]:bg-white [&::-moz-range-thumb]:bg-white";
    default:
      return "[&::-webkit-slider-thumb]:bg-black-v2 [&::-moz-range-thumb]:bg-black-v2";
  }
};
