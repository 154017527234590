import { UserJourney } from "@/signup/types";
import { CountryCode } from "@/libs/sawubona-sdk/types";
import PRODUCTION_CONFIG from "./environments/production";
import LOCAL_CONFIG from "./environments/local";
import DEV_CONFIG from "./environments/dev";
import { FEATURE_ENV_CONFIGS } from "./environments/feature";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface ProcessEnv {
      VITE_REACT_APP_APP_ENV: string;
      VITE_REACT_APP_GIT_COMMIT_SHA: string;
    }
  }
}

export interface Config {
  apiURL: string;
  baseDomain: string;
  blackbirdPublicKey: string;
  blackbirdSDKUrl: string;
  cookieYesKey: string;
  countryCode: CountryCode;
  defaultUserJourney: UserJourney;
  gitCommitSha: string;
  googlePlacesPublicKey: string;
  googleTagManagerContainerID: string;
  appURL: string;
  paymentTermsBlackbirdPublicKey: string;
  portalURL: string;
  segmentPublicKey: string;
  sentryDSN: string;
  stateDebugEnabled: boolean;
  vwoAccountId: string;
}

// For frontend builds, CI builds once for prod and once for all feature (animal) environment. This means that by
// default, all feature envs share would share the same config as set at build time. As an example, if we wanted
// camel to use a different portal url to wolf, this is something that needs to be replaced at runtime. We therefore
// use separate configs, embedded in the app (the CONFIGS const), which we selectively load at runtime. The only config
// var which needs to be injected at runtime is the `REACT_APP_APP_ENV`.
// In Dockerfile, this `REACT_APP_APP_ENV` arg is called `REACT_APP_APP_ENV` for build args but
// then set as `VITE_REACT_APP_APP_ENV` before running `yarn run build` so that vite can access it.

const CONFIGS: { [key: string]: Config } = {
  ...{
    local: LOCAL_CONFIG,
    dev: DEV_CONFIG,
    production: PRODUCTION_CONFIG,
  },
  ...FEATURE_ENV_CONFIGS,
};

const appEnv = import.meta.env.VITE_REACT_APP_APP_ENV || "local";

const CONFIG: Config = CONFIGS[appEnv] || LOCAL_CONFIG;

window.yc = window.yc || {};
window.yc.appEnv = appEnv;
window.yc.appConfig = CONFIG;

export default CONFIG;
