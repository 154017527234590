import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import { PaymentMethod } from "@/shared/shop/api";
import { makeTestID } from "@/shared/utils/development";
import { useCartStore } from "@/shop/store";
import { PaymentMethodProps } from "@/shop/types";
import React from "react";
import PayButton from "../PayButton";
import Typography from "../../elements/Typography";
import { Color } from "../../../../constants/color";

const useStore = () => ({
  expressEFTPaymentIntent: useCartStore(state => state.expressEFTPaymentIntent),
});

const EFTPaymentMethod: React.FC<PaymentMethodProps> = ({
  onSuccess,
}: PaymentMethodProps) => {
  const { expressEFTPaymentIntent } = useStore();

  const onSubmit = async () => {
    const isSuccessful = await expressEFTPaymentIntent();

    if (isSuccessful) {
      if (onSuccess) {
        onSuccess(PaymentMethod.EFT);
      }
    }
  };

  return (
    <Formik onSubmit={onSubmit}>
      {() => {
        return (
          <Form>
            <div className="mb-32-v2">
              <Typography
                font="grotesk"
                size="body-md"
                text="How does it work?"
                color={Color.Black}
                weight="medium"
                className="my-8-v2"
              />
              <Typography
                font="grotesk"
                size="body-sm"
                text="1. Click ‘Pay by EFT’  to confirm your order."
                color={Color.Black}
                weight="book"
              />
              <Typography
                font="grotesk"
                size="body-sm"
                text="2. You’ll be given Yoco’s banking details, and a unique reference
                  number to use when making the payment."
                color={Color.Black}
                weight="book"
              />
              <Typography
                font="grotesk"
                size="body-sm"
                text="3. You’ll need to upload your Proof of Payment in the Yoco App or
                  Yoco Business Portal."
                color={Color.Black}
                weight="book"
              />
              <Typography
                font="grotesk"
                size="body-md"
                text="Please note:"
                color={Color.Black}
                weight="medium"
                className="mt-8-v2"
              />
              <Typography
                font="grotesk"
                size="body-sm"
                text="Your order will only be processed once proof of payment has been
              received."
                color={Color.Black}
                weight="book"
              />
            </div>

            <PayButton
              type="submit"
              testID={makeTestID("payment", "payByEft")}
              textOverride="Pay by EFT"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default EFTPaymentMethod;
