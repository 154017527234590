import { Cart } from "@/shared/shop/api";

export interface BusinessCategory {
  id: number;
  name: string;
  image_class: string;
  subcategories: BusinessSubcategory[];
}

export interface BusinessSubcategory {
  id: number;
  name: string;
  full_name: string;
  mcc_code: string;
  image_class: string;
  parent_category_id: number;
  synonyms: string;
}

export enum BusinessType {
  SOLE_TRADER = "sole_trader",
  COMPANY = "company",
  NOT_REGISTERED = "not_registered",
  NPO = "npo",
  TRUST = "trust",
}

export interface Experiment {
  key: string;
  platform: string;
  id: number;
  name: string;
  variation: string;
}

export enum Platform {
  APP = "app",
  KHUMO = "khumo",
  MOBILE_WEB = "mobile_web",
  WEB = "web",
}

export enum Service {
  CARD_MACHINE = "card_machine",
  GIFT_VOUCHERS = "gift_vouchers",
  INVOICES = "invoices",
  PAYMENT_REQUEST = "payment_request",
  QR = "qr",
  READER_REGISTRATION = "reader_registration",
  WEBSITE = "website",
  WAITLIST = "waitlist",
  WAITING_FOR_DELIVERY = "waiting_for_delivery",
}

export interface Signup {
  id: string;
  email: string;
  password: string;
  owner_first_name: string;
  owner_last_name: string;
  owner_document_type: string;
  owner_id_passport_number: string;
  mobile_number: string;
  business_name: string;
  business_url: string;
  card_machine_use: string;
  approximate_volume_of_card_payments: string;
  trade_habit: string;
  trading_for: string;
  trading_frequency: string;
  business_type: BusinessType;
  services: Service[];
  primary_service: Service;
  have_previously_accepted_card_payments: boolean;
  serial_number: string;
  referral_code: string;
  reseller_code: string;
  stage: SignupStep;
  status: SignupStatus;
  business_uuid: string;
  user_uuid: string;
  is_created_in_core: boolean;
  business_category_id: number;
  // TODO: business_category?
  // TODO: tracking_attribution?
  business_category_other: string;
  business_registration_number: string;
  cart: Cart;
  cart_uuid: string;
  order_number: string;
  trading_address_line1: string;
  trading_address_line2: string;
  trading_address_suburb?: string;
  trading_address_city: string;
  trading_address_province?: string;
  trading_address_postal_code?: string;
  trading_address_country: string;
  trading_address_latitude: number;
  trading_address_longitude: number;
  trading_address_city_code?: string;
  trading_address_area?: string;
  trading_address_notes?: string;
  feature_flags: string[];
  experiments: Experiment[];
  pre_qualification: Record<string, unknown>;
  user_journey: string;
}

export enum SignupStatus {
  PENDING = "pending",
  COMPLETE = "complete",
  CANCELLED = "cancelled",
}

export enum SignupStep {
  HomePage = "/signup/",
  XneeloLandingPage = "/xneelo/",
  BusinessNamePage = "/signup/:id/business_name/",
  TradingAddressPage = "/signup/:id/trading_address/",
  BusinessTypePage = "/signup/:id/business_type/",
  BusinessOwnerPage = "/signup/:id/business_owner/",
  BusinessCategoryPage = "/signup/:id/business_category/",
  DeveloperDetailsPage = "/signup/:id/developer_details/",
  ServiceSelectionPage = "/signup/:id/services/",
  BusinessUrlPage = "/signup/:id/business_url/",
  BusinessPreQualificationPage = "/signup/:id/pre_qualification/",
  RegisterReaderPage = "/signup/:id/retail/",
  ProductCataloguePage = "/signup/:id/products/",
  DeliveryPage = "/signup/:id/delivery/",
  PaymentPage = "/signup/:id/payment/",
  CompletePage = "/signup/:id/complete/",
}

export interface ValidationResult {
  field_name: string;
  is_valid: boolean;
  error: string;
}
export interface ReferralCodeBusiness {
  uuid: string;
  name: string;
  displayName: string;
  ownerFirstName: string;
  ownerLastName: string;
  email: string;
  referralCode: string;
  createdAt: string;
}
export interface ValidationReferralCodeResult {
  isValid: boolean;
  reason: string;
  business: ReferralCodeBusiness;
}

export enum CountryCode {
  SouthAfrica = "ZA",
  Egypt = "EG",
}

export type LabelValuePair = {
  value: string;
  label: string;
};
