import { PaymentMethod } from "@/shared/shop/api";
import { PaymentMethodProps } from "@/shop/types";
import InstantEft from "./InstantEft";

const InstantEftBlackbirdPaymentMethod = ({
  onSuccess,
}: PaymentMethodProps) => {
  const onTransactionComplete = async result => {
    if (result.status === "succeeded") {
      if (onSuccess) {
        onSuccess(PaymentMethod.INSTANT_EFT_BLACKBIRD);
      }
    }
  };

  return (
    <div>
      <InstantEft onTransactionComplete={onTransactionComplete} />
    </div>
  );
};

export default InstantEftBlackbirdPaymentMethod;
