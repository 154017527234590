import { useFeatureFlags } from "@yoco/feature-flags";
import { BusinessType, Service } from "@yoco/sawubona-sdk";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formatRoute } from "react-router-named-routes";
import shop, { ReasonNotAvailable } from "@/shared/shop/api";
import { useAppConfigStore, useCartStore } from "@/shop/store";
import { useSignupStore } from "@/signup/store";
import { Features, SignupRoute } from "@/signup/types";

import { Bundle, Product } from "@yoco/shop-sdk/lib/types";
import { Color } from "@/constants/color";
import { getRibbonColor } from "@/utils/color";
import CardMachine from "../../../shared/components/cards/Product/CardMachine";
import { getBundleImage } from "../../../assets/images/products";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  business: useAppConfigStore(state => state.business),
  signup: useSignupStore(state => state.signup),
  getSignup: useSignupStore(state => state.getSignup),
  finaliseSignup: useSignupStore(state => state.finaliseSignup),
  updateSignup: useSignupStore(state => state.updateSignup),
});

type Props = {
  bundle: Bundle;
  onAddedBundleToCart?: (bundle: Bundle | Product) => void;
};

const BundleBlock = ({ bundle, onAddedBundleToCart }: Props) => {
  const { cart, business, signup, getSignup, finaliseSignup, updateSignup } =
    useStore();
  const features = useFeatureFlags();
  const navigate = useNavigate();

  const isAvailableOnTerms = useMemo(
    () =>
      (signup?.business_type === BusinessType.SOLE_TRADER ||
        signup?.business_type === BusinessType.COMPANY ||
        signup?.business_type === BusinessType.NOT_REGISTERED) &&
      features.isEnabled(Features.TERMS) &&
      bundle.can_be_purchased,
    [signup, features, bundle]
  );

  const [isOnWaitlist, setIsOnWaitlist] = useState(false);

  const onAddToWaitlist = async () => {
    const result = await shop.waitlist.add({
      email: signup?.email || business?.owner.email,
      mobile_number: signup?.mobile_number || business?.telephone_number,
      product: bundle.id,
    });

    if (result.id) {
      setIsOnWaitlist(true);
      toast.success(
        `You have been added to the waitlist. We'll let you know when it becomes available.`,
        { position: "bottom-center" }
      );
    }
  };

  const isWaitListAvailable = useMemo(() => {
    return !!(bundle.reason_not_available && (signup || business));
  }, [bundle, signup, business]);

  const canFinaliseSignup = useMemo(() => {
    return signup && isOnWaitlist && cart?.items.length === 0;
  }, [signup, isOnWaitlist, cart]);

  const onClickFinalise = async () => {
    const signupServices = {
      services: [Service.WAITLIST],
      primary_service: Service.WAITLIST,
    };
    await updateSignup(signupServices);

    const isSignupFinalised = await finaliseSignup();

    if (isSignupFinalised) {
      const finalisedSignup = getSignup();

      navigate(
        formatRoute(SignupRoute.CompletePage, {
          id: finalisedSignup.id,
        })
      );
    }
  };

  const isBundlePurchasable = useMemo(() => {
    return (
      bundle.can_be_purchased &&
      !isWaitListAvailable &&
      bundle.max_quantity_per_cart !== 0
    );
  }, [bundle, isWaitListAvailable]);

  return (
    <CardMachine
      direction="row"
      imageUrl={getBundleImage(bundle)}
      imageAlt="Card machine"
      item={bundle}
      badgeText={
        bundle.reason_not_available === ReasonNotAvailable.OUT_OF_STOCK
          ? "Sold Out"
          : bundle.ribbon_text
      }
      badgeAccentColor={
        bundle.reason_not_available
          ? Color.Orange
          : getRibbonColor(bundle.ribbon_color)
      }
      calloutLabel={
        isAvailableOnTerms ? "Available over 3 instalments" : undefined
      }
      priceValue={bundle.unit_price}
      priceStrikeSuperscriptText={bundle.is_discounted}
      priceSuperscriptText={
        bundle.is_discounted
          ? `Was ${bundle.original_unit_price_formatted}`
          : undefined
      }
      priceSideScriptText="Once Off"
      onAddedToCart={onAddedBundleToCart}
      isPurchasable={isBundlePurchasable}
      // below props are to support:
      // - out of stock
      // - wait list
      // - not available (!isPurchasable)
      // but will only show if isPurchasable is false
      ctaOnClick={
        canFinaliseSignup
          ? onClickFinalise
          : isWaitListAvailable
          ? onAddToWaitlist
          : () => {}
      }
      ctaText={
        canFinaliseSignup
          ? "Complete signup"
          : isWaitListAvailable
          ? `Reserve your ${bundle.short_name}`
          : "Not available"
      }
      ctaDisabled={!isBundlePurchasable && !isWaitListAvailable}
    />
  );
};

export default BundleBlock;
