import {
  Field as FormikField,
  useFormikContext,
  FieldAttributes,
} from "formik";
import React from "react";

/*
  Using any here due to different types of props being passed

  TODO: R&D The type
*/
type Props = FieldAttributes<any> & {
  name: string;
};

const Field: React.FC<Props> = (props: Props) => {
  const { setFieldTouched, validateField } = useFormikContext();
  const { name } = props;

  const handleBlur = () => {
    setFieldTouched(name, true);

    validateField(name);
  };

  let className = `${
    props?.className ? `${props?.className} ` : ""
  }focus:ring-0 focus:ring-offset-0 `;

  if (props?.type === "checkbox") {
    className = `${className}focus:outline rounded-sm`;
    return (
      <FormikField
        onBlur={handleBlur}
        {...props}
        type="checkbox"
        className={className}
      />
    );
  }

  return <FormikField onBlur={handleBlur} {...props} />;
};

export default Field;
