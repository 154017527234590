import { createPortal } from "react-dom";
import { useFormikContext } from "formik";
import { useCartStore } from "../../../shop/store";
import { Color } from "../../../constants/color";
import { formatPrice } from "../../../utils/price";
import { useIsBreakpoint } from "../../../utils/screen";
import PillButton from "../elements/Buttons/PillButton";
import LockIcon from "../elements/LockIcon";
import { MOBILE_FOOTER_BUTTON_TARGET_ID } from "../checkout/Drawer";

type Props = {
  /**
   * Will fallback to checking formik context
   */
  isSubmitting?: boolean;
  testID: string;
  /**
   * Use this amount when you want to override the cart total
   */
  paymentAmountOverride?: string;
  /**
   * Use this when you want to override the `Pay R*` text
   */
  textOverride?: string;
} & (
  | {
      type: "button";
      onClick: () => void;
    }
  | {
      /**
       * When type is submit,
       * this component needs
       * to be wrapped by `Formik`
       */
      type: "submit";
      onClick?: () => void;
    }
);

const useStore = () => ({
  cartTotal: useCartStore(state => state.cart?.net_amount ?? 0),
});

/**
 * Shared payment button that accepts either `onClick`
 * handler or works as `submit` when when wrapped in a form
 *
 * This will portal into `Drawer` on mobile viewports
 *
 * `showPayment` needs to be set to `true` on
 * `Layout`/`LayoutOrderSummary`
 *  (which passes this down to `Drawer`)
 * for this to work
 *
 * Since there are various onClick/onSubmit handlers for
 * different payments, this component accepts
 * either and renders in the correct place
 * based on the user's viewport width
 */
const PayButton = ({
  isSubmitting,
  testID,
  type = "button",
  onClick,
  paymentAmountOverride,
  textOverride,
}: Props) => {
  const { cartTotal } = useStore();
  const isLaptop = useIsBreakpoint("laptop");

  const { submitForm, isSubmitting: isSubmittingContext } = useFormikContext();

  const portalTarget = document.getElementById(MOBILE_FOOTER_BUTTON_TARGET_ID);

  const button = (
    <PillButton
      text={
        textOverride ?? `Pay ${paymentAmountOverride ?? formatPrice(cartTotal)}`
      }
      color={Color.Blue}
      size={isLaptop ? "medium" : "large"}
      // When we're portalled, we can't bubble up the submit event from the
      // DOM node tree but we can trigger submit from context
      // since this element exists as a child of formik
      // context provider in the virtual dom
      type={isLaptop ? type : "button"}
      style="solid"
      disabled={isSubmitting || isSubmittingContext}
      testID={testID}
      className="mt-12-v2 !w-full"
      icon={<LockIcon className="text-white-v2" />}
      iconPosition="left"
      nonResponsive
      // Destructed to prevent onClick property getting set if not needed
      {...(isLaptop
        ? onClick
          ? { onClick }
          : {}
        : type === "button"
        ? { onClick }
        : // This simply triggers a submit when portalled
          { onClick: submitForm })}
    />
  );
  return isLaptop ? (
    button
  ) : portalTarget ? (
    <>{createPortal(button, portalTarget)}</>
  ) : null;
};

export default PayButton;
