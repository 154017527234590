// TODO: Double check if this component still relevant
import classNames from "classnames";
import { connect, FieldInputProps } from "formik";
import Typography from "../Typography";
import { Color } from "../../../../constants/color";

export interface Option {
  value?: string;
  label: string;
  description?: string;
  image?: string;
  noFlex?: boolean;
  display?: string;
  displayProp?: string;
  padding?: boolean;
}

type FormOptionFieldValue = string | number | readonly string[] | undefined;

type Props = {
  /**
   * V2 Field component will automatically pass this down,
   * so you dont need to pass a value to this if you're using Field
   */
  field?: FieldInputProps<FormOptionFieldValue>;
  value: string;
  type: "checkbox" | "radio";
  option: Option;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 * Form option card.
 *
 * Note: that we have no V2 design for this, so the only change here for V2 is Typography/Colors
 */
const FormOption = ({
  field,
  type,
  option,
  className,
  onChange,
  ...props
}: Props) => {
  return (
    <div
      className={classNames(
        `h-full w-full rounded-lg outline outline-1`,
        field?.checked
          ? "bg-light-blue-v2 outline-blue-v2 "
          : "outline-light-gray-v2",
        className
      )}
    >
      <label className="flex flex-col rounded-lg p-16-v2 w-full h-full">
        <div
          className={
            option?.noFlex
              ? "flex flex-row sm-v2:flex-col sm-v2:items-start items-center font-bold text-lg"
              : "flex flex-row text-left"
          }
        >
          <input
            type={type}
            className={classNames(
              type === "checkbox" ? "rounded-sm" : "rounded-full",
              "focus:ring-0 focus:ring-offset-0 text-blue-v2 mr-16-v2 w-16-v2 h-16-v2",
              option?.image ? option?.display : option?.displayProp
            )}
            {...field}
            {...props}
            onChange={onChange}
          />

          {option?.image && (
            <img
              src={option.image}
              alt=""
              width="60"
              height="60"
              className={
                option.padding ? "sm-v2:m-auto sm-v2:pt-8-v2 mr-8-v2" : ""
              }
            />
          )}
          <div className="flex flex-col sm-v2:w-4/5 sm-v2:mx-auto">
            <span
              className={classNames(
                `block`,
                { "mt-12-v2": !!option.image },
                option?.description ? "font-bold" : ""
              )}
            >
              <Typography
                color={Color.Black}
                size="body-lg"
                text={option.label}
                font="grotesk"
                weight="medium"
              />
            </span>
            {option?.description && (
              <span className="mt-4-v2 ">
                <Typography
                  color={Color.Charcoal}
                  size="body-md"
                  text={option.description}
                  font="grotesk"
                  weight="book"
                  className="opacity-80"
                />
              </span>
            )}
          </div>
        </div>
      </label>
    </div>
  );
};

export default connect(FormOption);
