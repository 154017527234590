import classNames from "classnames";
import { useField } from "formik";
import { useState, useEffect } from "react";

import InputError from "./InputError";
import { Color } from "../../../../constants/color";
import Typography from "../Typography";

import { getTextColorClass } from "../../../../utils/color";

// TODO: Whole input, styling and props is 95% the same as Custom input. Look into refactoring to one code
export interface Props {
  id: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  label: string;
  placeholder?: string;
  color?: Color.White | Color.Charcoal;
  onFocus?: () => void;
  ["data-testid"]?: string;
}

const PhoneNumberInput = ({
  id,
  name,
  onFocus,
  className,
  label,
  placeholder,
  color = Color.Charcoal,
  required = false,
  disabled = false,
  ...props
}: Props): JSX.Element => {
  const [field, meta, helpers] = useField(id);

  const [focus, setFocus] = useState(false);

  const hasValue = !!field.value;
  const hasError = meta.touched && meta.error;
  const hasValueOrFocus = hasValue || focus;

  useEffect(() => {
    helpers.setTouched(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames("w-full", className)}>
      <div className="relative w-full rounded-12-v2">
        <label
          htmlFor={id}
          className={classNames(
            "absolute left-16-v2 transition-all ease-linear duration-200",
            {
              "top-10-v2": hasValueOrFocus,
            },
            {
              "top-20-v2": !hasValueOrFocus,
            }
          )}
        >
          <Typography
            weight={hasValueOrFocus ? "medium" : "book"}
            font="grotesk"
            color={color}
            text={required ? `${label} *` : label}
            size={hasValueOrFocus ? "body-sm-fixed" : "body-md-fixed"}
            className={classNames("transition-all duration-200")}
          />
        </label>

        <input
          id={id}
          {...field}
          {...props}
          name={name}
          type="text"
          maxLength={13}
          value={field.value}
          disabled={disabled}
          placeholder={hasValueOrFocus && placeholder ? placeholder : undefined}
          onBlurCapture={() => setFocus(false)}
          className={classNames(
            "bg-transparent w-full px-16-v2 pb-10-v2 pt-24-v2 border focus:border-blue-v2 focus:ring ring-blue-v2 rounded-12-v2 text-body-md-v2",
            getTextColorClass(color),
            { "border-orange-v2": hasError },
            {
              "border-charcoal-v2/20 hover:border-charcoal-v2":
                color === Color.Charcoal && !hasError,
            },
            {
              "border-white-v2/20 hover:border-white-v2":
                color === Color.White && !hasError,
            }
          )}
          onFocus={() => {
            setFocus(true);
            if (onFocus) {
              onFocus();
            }
          }}
        />
      </div>

      {hasError ? (
        <InputError
          error={`* ${meta.error}`}
          data-testid={`input-error-${props["data-testid"]}`}
        />
      ) : null}
    </div>
  );
};

export default PhoneNumberInput;
