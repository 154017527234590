import classNames from "classnames";
import React, { isValidElement } from "react";
import { isString } from "formik";
import Typography from "../../../elements/Typography";
import { Color } from "../../../../../constants/color";

type Props = {
  label: string;
  subLabel: { value?: string; suffix: string };
  description?: string | React.ReactElement;
  checked: boolean;
  value: string;
  name: string;
  onChange: () => void;
};

const SecondaryPaymentMethodNavItem: React.FC<Props> = ({
  label,
  subLabel,
  description,
  checked,
  name,
  onChange,
}: Props) => {
  return (
    <div className="block bg-white rounded-8-v2">
      <label className="flex items-center flex-1">
        <input
          type="radio"
          className="focus:ring-0 focus:ring-offset-0 absolute text-blue-v2 ml-12-v2 z-5 w-[18px] h-[18px]"
          checked={checked}
          name={name}
          onChange={onChange}
        />
        <div
          className={classNames(
            "pl-32-v2 w-full flex gap-16-v2 border rounded-8-v2 py-20-v2 px-12-v2",
            checked
              ? "bg-light-blue-v2 border-blue-v2 "
              : "border-light-gray-v2"
          )}
        >
          <div className="flex-1 shrink pl-4 flex items-center">
            <div className="flex flex-col gap-4-v2">
              <Typography
                font="grotesk"
                size="body-lg"
                text={label}
                color={Color.Black}
                weight="medium"
              />
              {isValidElement(description) ? (
                description
              ) : isString(description) ? (
                <Typography
                  font="grotesk"
                  size="body-sm"
                  text={description}
                  color={Color.Black}
                  weight="book"
                />
              ) : null}
            </div>
            <span className="ml-auto">
              {subLabel.value ? (
                <>
                  <Typography
                    font="grotesk"
                    size="body-sm"
                    text={subLabel.value}
                    color={Color.Black}
                    weight="medium"
                    className="inline"
                  />
                  <Typography
                    font="grotesk"
                    size="body-sm"
                    text={` ${subLabel.suffix}`}
                    color={Color.Black}
                    weight="book"
                    className="inline"
                  />
                </>
              ) : null}
            </span>
          </div>
        </div>
      </label>
    </div>
  );
};

export default SecondaryPaymentMethodNavItem;
