import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CONFIG from "@/config";
import ScrollToTop from "@/shared/components/ScrollToTop";
import Segment from "@/shared/services/Segment";
import { getArray, getBool, getString } from "@/shared/utils/appConfig";
import { useAppConfigStore } from "@/shop/store";

type Props = {
  children: React.ReactNode;
};

const useStore = () => ({
  setIsEmbed: useAppConfigStore(state => state.setIsEmbed),
  setIsInApp: useAppConfigStore(state => state.setIsInApp),
  setIsInHub: useAppConfigStore(state => state.setIsInHub),
  setProductTypes: useAppConfigStore(state => state.setProductTypes),
  setPaymentSuccessRedirect: useAppConfigStore(
    state => state.setPaymentSuccessRedirect
  ),
});

const Page: React.FC<Props> = ({ children }: Props) => {
  const location = useLocation();
  const {
    setIsEmbed,
    setIsInApp,
    setIsInHub,
    setProductTypes,
    setPaymentSuccessRedirect,
  } = useStore();

  useEffect(() => {
    Segment.trackPageView();
  }, [location]);

  useEffect(() => {
    // The following appConfig store values are configurable from the query string and/or
    // window.yc.config on any page load.
    const inApp = getString("in-app");

    if (inApp) {
      setIsInApp(getBool("in-app"));
    }

    const inHub = getString("in-hub");

    if (inHub) {
      setIsInHub(getBool("in-hub"));
    }

    const productTypes = getArray<string>("type");

    if (productTypes.length) {
      setProductTypes(productTypes);
    }

    const isEmbedded = getBool("embed");

    if (isEmbedded) {
      setIsEmbed(isEmbedded);
    }

    const paymentSuccessRedirect = getString("paymentSuccessRedirect");

    if (paymentSuccessRedirect) {
      setPaymentSuccessRedirect(paymentSuccessRedirect);
    }
  }, [location, setIsEmbed, setIsInApp]);

  return (
    <>
      <Helmet>
        <title>Yoco - Shop</title>

        <script type="text/javascript">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${CONFIG.googleTagManagerContainerID}');`}
        </script>

        <script>
          {`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
          analytics.load("${CONFIG.segmentPublicKey}");
          }}();`}
        </script>
      </Helmet>
      <ToastContainer />
      <ScrollToTop />
      {children}
    </>
  );
};

export default Page;
