import React, { ComponentProps } from "react";
import { Color } from "@/constants/color";
import classNames from "classnames";
import PromoCodeForm from "./PromoCodeForm";
import Layout from "../Layout";
import Typography from "../elements/Typography";
import TextButton from "../elements/Buttons/TextButton";
import { formatPrice } from "../../../utils/price";
import LayoutOrderSummaryItem from "./LayoutOrderSummaryItem";
import { FormHeaderDescription, FormHeaderTitle } from "./FormHeader";
import { CheckoutDrawer } from "./Drawer";
import PriceBreakdownLine from "./PriceBreakdownLine";
import { useCartPrice } from "../../../shop/store/price";
import { useCartItemsStore } from "../../../shop/store/items";

export const DESKTOP_LAYOUT_BUTTON_TARGET_ID = "desktop-layout-button-portal";

type LayoutOrderSummaryProps = {
  title: string;
  description?: string;
  /**
   * Use a portalled SubmitButton to render a submit
   * button above the order summary
   */
  showButtonTarget?: boolean;
  children: React.ReactNode;
  isCartEditable?: boolean;
  mobileFooterVariant?: ComponentProps<
    typeof CheckoutDrawer
  >["mobileFooterVariant"];
};

/**
 * Layout with the order summary on the right
 *
 * @example
 *   <Formik onSubmit={onSubmit}>
 *     {() => (
 *      <Form>
 *        <LayoutOrderSummary title="Choose a product" isCartEditable>
 *          // My form's content
 *        </LayoutOrderSummary>
 *     </Form>
 *    )}
 *  </Formik>
 */
const LayoutOrderSummary = ({
  title,
  children,
  description,
  isCartEditable = false,
  showButtonTarget = false,
  mobileFooterVariant,
}: LayoutOrderSummaryProps) => {
  const { cartItems, cartItemsCount } = useCartItemsStore();
  const { cartTotal, subTotal, cartTax, cartDiscount } = useCartPrice();

  /**
   * This allows us to fall back to our
   * regular layout when there is no
   * need to show the cart summary
   */
  const itemsInCart = !!cartItemsCount;

  return (
    <Layout
      fullWidth
      mobileFooterVariant={mobileFooterVariant}
      isCartEditable={isCartEditable}
    >
      <div className="pt-20-v2 md-v2:pt-48-v2 grid gap-32-v2 md-v2:grid-cols-[1fr_40%] lg-v2:grid-cols-[1fr_35%]">
        <div className="flex flex-col gap-y-16-v2 lg-v2:pl-[208px]">
          <div className="grid gap-8-v2 mb-16-v2">
            <FormHeaderTitle text={title} />
            <FormHeaderDescription text={description} />
          </div>
          {children}
        </div>

        <div
          className={classNames(
            "sticky w-full h-fit hidden max-w-[500px] md-v2:grid gap-48-v2"
          )}
        >
          <div className="rounded-[16px] border border-charcoal-v2/20 p-32-v2">
            <Typography
              size="lead-sm"
              font="grotesk"
              weight="medium"
              text="Order summary"
              color={Color.Black}
            />

            <div
              className={classNames(
                "border-b border-charcoal-v2/20 flex flex-col gap-y-24-v2",
                itemsInCart ? "py-32-v2" : "py-24-v2"
              )}
            >
              {!itemsInCart ? (
                <Typography
                  text="Your cart is empty"
                  size="body-md"
                  font="grotesk"
                  weight="book"
                  color={Color.Charcoal}
                />
              ) : (
                cartItems.map(item => (
                  <LayoutOrderSummaryItem
                    key={item.id}
                    cartItem={item}
                    editable={isCartEditable}
                  />
                ))
              )}
            </div>
            {isCartEditable && (
              <div className="py-[23px] border-b border-charcoal-v2/20">
                <PromoCodeForm />
              </div>
            )}
            <div className="pt-16-v2">
              <div className="space-y-[9px] pb-16-v2">
                <div className="flex justify-between items-center">
                  <Typography
                    text="Subtotal"
                    size="body-md"
                    font="grotesk"
                    weight="medium"
                    color={Color.Black}
                  />
                  <Typography
                    size="body-md"
                    font="grotesk"
                    weight="medium"
                    color={Color.Black}
                    text={
                      subTotal
                        ? formatPrice(subTotal, {
                            shouldShowDecimals: true,
                            hasThousandsSeparator: true,
                          })
                        : "-"
                    }
                  />
                </div>
                <PriceBreakdownLine
                  description="Shipping"
                  value="Free delivery (3 working days)"
                  isPriceNegative
                />
                <PriceBreakdownLine description="Tax (Vat)" price={cartTax} />
                <PriceBreakdownLine
                  description="Discount"
                  price={cartDiscount}
                  isPriceNegative
                />
              </div>
              <div className="flex justify-between items-center border-t pt-16-v2 border-charcoal-v2/20">
                <Typography
                  text="Total"
                  size="body-md"
                  font="grotesk"
                  weight="medium"
                  color={Color.Black}
                />
                <Typography
                  size="body-md"
                  font="grotesk"
                  weight="medium"
                  color={Color.Black}
                  text={
                    cartTotal
                      ? formatPrice(cartTotal, {
                          shouldShowDecimals: true,
                          hasThousandsSeparator: true,
                        })
                      : "-"
                  }
                />
              </div>
              <div className="flex justify-left gap-x-4-v2 mt-16-v2">
                <Typography
                  text="By continuing, I agree to Yoco’s"
                  color={Color.Black}
                  size="body-sm-fixed"
                  font="grotesk"
                  weight="book"
                  className="inline"
                />
                <TextButton
                  text="Terms"
                  textSize="body-sm-fixed"
                  color={Color.Blue}
                  style="text"
                  weight="book"
                  linkUrl="/za/legal"
                  uppercase={false}
                  className="inline"
                />
              </div>
            </div>
          </div>
          {showButtonTarget && (
            <>
              {/* See SubmitButton component */}
              <div id={DESKTOP_LAYOUT_BUTTON_TARGET_ID} />
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default LayoutOrderSummary;
