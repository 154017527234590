// ToDo V2 Design
import Checkbox from "@/shared/components/V1/form/Checkbox";
import Field from "@/shared/components/V1/form/Field";
import { makeTestID } from "@/shared/utils/development";
import Input from "@/shared/components/V1/form/Input";
import { useSignupStore } from "@/signup/store";
import { BusinessType } from "@yoco/sawubona-sdk";
import React, { useState } from "react";
import TermsContract from "./TermsContract";

const useStore = () => ({
  signup: useSignupStore(state => state.signup),
});

const cipcNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
];

const TermsAgreementForm: React.FC = () => {
  const { signup } = useStore();

  const [isContractExpanded, setIsContractExpanded] = useState(false);

  return (
    <div className="mb-6">
      <h3 className="text-base font-bold mb-2">
        {signup?.business_name}&apos;s details
      </h3>
      <div className="mb-2">
        <div className="flex flex-row items-stretch">
          <span className="flex-1">Owner first name:</span>
          <span className="text-right">{signup?.owner_first_name}</span>
        </div>
        <div className="flex flex-row items-stretch">
          <span className="flex-1">Owner last name:</span>
          <span className="text-right">{signup?.owner_last_name}</span>
        </div>
        <div className="flex flex-row items-stretch">
          <span className="flex-1">
            Owner{" "}
            {signup?.owner_document_type === "sa_id_number"
              ? "ID number"
              : "passport number"}
            :
          </span>
          <span className="text-right">{signup?.owner_id_passport_number}</span>
        </div>
      </div>
      {signup?.business_type === BusinessType.COMPANY && (
        <div className="flex flex-col sm:flex-row sm:items-center mb-2">
          <div className="flex-1 mb-2 sm:mb-0">
            Company registration number *
          </div>
          <div className="flex-1">
            <Field
              name="business_registration_number"
              placeholder="0000/000000/00"
              component={Input}
              mask={cipcNumberMask}
              data-testid={makeTestID(
                "paymentPage",
                "businessRegistrationNumber"
              )}
            />
          </div>
        </div>
      )}
      {isContractExpanded && (
        <div>
          <hr className="mt-4" />
          <div
            className="py-4 overflow-auto"
            style={{
              height: 360,
            }}
          >
            <TermsContract />
          </div>
          <hr className="mb-4" />
        </div>
      )}
      <div className="flex flex-row flex-wrap text-left space-x-1">
        <Field
          name="agree_with_terms_and_conditions"
          label={<>I agree and accept Retail Capital&apos;s </>}
          component={Checkbox}
          data-testid={makeTestID("paymentPage", "purchaseAgreement")}
        />
        <span>
          <button
            type="button"
            className="underline text-blue-500 py-1"
            onClick={() => setIsContractExpanded(!isContractExpanded)}
          >
            Purchase Agreement
          </button>
        </span>
      </div>
    </div>
  );
};

export default TermsAgreementForm;
