import React from "react";
import NumberField from "./NumberField";

type Props = {
  className?: string;
};

const OrderNumberField: React.FC<Props> = ({ className }: Props) => {
  return (
    <NumberField
      title="Enter your order number"
      subtitle="You'll find it in your email inbox"
      className={className}
      name="order_number"
      id="orderNumber"
    />
  );
};

export default OrderNumberField;
