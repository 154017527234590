import classNames from "classnames";
import React from "react";

type Props = {
  animateOnHover?: boolean;
  className?: string;
};

const Arrow: React.FC<Props> = ({ animateOnHover = true, className }) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("group", className)}
    >
      <g
        className={classNames({
          "group -translate-x-[30px] group-hover:translate-x-0 group-focus-visible:translate-x-0 transition-transform duration-300":
            animateOnHover,
        })}
      >
        <path
          d="M9.98919 14C10.2716 13.2462 10.6054 12.5462 11.0932 11.7923C12.0946 10.3115 13.2757 9.01923 14.3541 8.18462H0V5.70769H14.277C13.7635 5.27692 13.2243 4.76538 12.7108 4.11923C11.6068 2.88077 10.6568 1.4 10.1432 0H12.8649C13.0959 0.673076 13.4554 1.42692 13.9946 2.26154C15.0216 3.85 16.4851 5.38462 18 6.40769V7.59231C17.2554 8.10385 16.4851 8.69615 15.7662 9.45C14.277 10.9038 13.1473 12.5462 12.7108 14H9.98919Z"
          fill="currentColor"
        />
        {animateOnHover && (
          <path
            d="M9.98919 14C10.2716 13.2462 10.6054 12.5462 11.0932 11.7923C12.0946 10.3115 13.2757 9.01923 14.3541 8.18462H0V5.70769H14.277C13.7635 5.27692 13.2243 4.76538 12.7108 4.11923C11.6068 2.88077 10.6568 1.4 10.1432 0H12.8649C13.0959 0.673076 13.4554 1.42692 13.9946 2.26154C15.0216 3.85 16.4851 5.38462 18 6.40769V7.59231C17.2554 8.10385 16.4851 8.69615 15.7662 9.45C14.277 10.9038 13.1473 12.5462 12.7108 14H9.98919Z"
            fill="currentColor"
            transform="translate(30,0)"
          />
        )}
      </g>
    </svg>
  );
};

export default Arrow;
