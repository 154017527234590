import classNames from "classnames";
import { useField } from "formik";
import React, { useEffect } from "react";

import InputError from "./InputError";
import { Color } from "../../../../constants/color";
import Checkmark from "../Checkmark";
import Typography from "../Typography";

export interface Props {
  label: string;
  id: string;
  image?: string;
  className?: string;
  disabled?: boolean;
  inputClassName?: string;
  required?: boolean;
  ["data-testid"]?: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckBox = ({
  label,
  id,
  image,
  className,
  inputClassName,
  disabled = false,
  required = false,
  checked = false,
  onChange,
  ...props
}: Props): JSX.Element => {
  const [field, meta, helpers] = useField(id);
  const isChecked = checked || !!field.value;
  const hasError = meta.touched && meta.error;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
    helpers.setValue(e.target.checked);
  };

  useEffect(() => {
    helpers.setTouched(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classNames("group relative", className)}>
        <label
          htmlFor={id}
          className={classNames("flex items-center cursor-pointer", {
            "pl-16-v2 border border-solid rounded-[10px]": image,
            "border-blue-v2": isChecked,
            "border-charcoal-v2/10 group-hover:border-charcoal-v2 ": !isChecked,
          })}
        >
          <input
            type="checkbox"
            id={id}
            required={required}
            checked={isChecked}
            {...field}
            onChange={handleChange}
            disabled={disabled}
            className="opacity-0 absolute peer"
            {...props}
          />
          <div
            className={classNames(
              "border rounded-[6px] min-w-20-v2 min-h-20-v2",
              isChecked
                ? "border-blue-v2 bg-blue-v2"
                : "border-charcoal-v2/10 group-hover:border-charcoal-v2 focus:border-charcoal-v2 peer-focus:border-charcoal-v2"
            )}
          >
            <Checkmark
              className={classNames(
                "duration-200 ml-[3px] mt-[5px] stroke-white-v2 stroke-2 w-[11px] h-[8px]",
                isChecked ? "opacity-100" : "opacity-0"
              )}
              alt="checkbox-check"
            />
          </div>
          <Typography
            color={Color.Black}
            text={label}
            font="grotesk"
            size="body-md"
            weight={image ? "medium" : "book"}
            className="ml-10-v2 mt-[2px]"
          />
          {image ? (
            <img
              src={image}
              alt={label}
              width={100}
              className="flex ml-auto overflow-hidden rounded-[9px]"
            />
          ) : null}
        </label>
      </div>
      {hasError && meta.error ? (
        <InputError
          className={inputClassName}
          error={meta.error}
          data-testid={`input-error-${props["data-testid"]}`}
        />
      ) : null}
    </>
  );
};

export default CheckBox;
