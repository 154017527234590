import { useEffect, useState } from "react";
import { useSignupStore } from "../signup/store";

const useStore = () => ({
  pollUntilSignupHasAccountCreated: useSignupStore(
    state => state.pollUntilSignupHasAccountCreated
  ),
});

/**
 * Custom hook to poll for a merchant account creation in core and return various details to consumers:
 * - isMerchantAccountCreated: if the merchant account has been created
 * - merchantAccountError: an error (if any) encountered during polling for the merchant account
 */
export const useWaitUntilMerchantAccountIsCreated = () => {
  const { pollUntilSignupHasAccountCreated } = useStore();
  const [isMerchantAccountCreated, setMerchantAccountCreated] = useState(false);
  const [merchantAccountError, setMerchantAccountError] = useState(null);

  useEffect(() => {
    pollUntilSignupHasAccountCreated()
      .then(() => {
        setMerchantAccountCreated(true);
      })
      .catch(err => {
        setMerchantAccountError(err.message);
      });
  }, [pollUntilSignupHasAccountCreated]);

  return {
    isMerchantAccountCreated,
    merchantAccountError,
  };
};
