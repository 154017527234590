import React from "react";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import { PaymentMethod } from "@/shared/shop/api";
import { useCartStore } from "@/shop/store";
import { PaymentMethodProps } from "@/shop/types";
import PayButton from "../../PayButton";
import { makeTestID } from "../../../../utils/development";

const useStore = () => ({
  expressCardOnDeliveryPaymentIntent: useCartStore(
    state => state.expressCardOnDeliveryPaymentIntent
  ),
});

const CardOnDeliveryPaymentMethod: React.FC<PaymentMethodProps> = ({
  onSuccess,
}: PaymentMethodProps) => {
  const { expressCardOnDeliveryPaymentIntent } = useStore();

  const onSubmit = async () => {
    const isSuccessful = await expressCardOnDeliveryPaymentIntent();

    if (isSuccessful) {
      if (onSuccess) {
        onSuccess(PaymentMethod.CARD_ON_DELIVERY);
      }
    }
  };

  return (
    <Formik onSubmit={onSubmit}>
      {() => {
        return (
          <Form>
            <PayButton
              type="submit"
              // ToDo there is no test for this
              textOverride="Pay on delivery"
              testID={makeTestID("payment", "payOnDelivery")}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CardOnDeliveryPaymentMethod;
