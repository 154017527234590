import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import SignupService from "@/signup/services/SignupService";
import Validator from "@/signup/services/Validator";
import { useSignupStore, useStepStore } from "@/signup/store";
import { SignupRoute } from "@/signup/types";
import { Service, SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues, FormikProps } from "formik";
import { remove, uniq } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import * as yup from "yup";
import classNames from "classnames";
import activateCardIcon from "./images/activate-card.svg";
import buyCardIcon from "./images/buy-card.svg";
import deliveryIcon from "./images/delivery-icon.svg";
import onlineCardPayment from "./images/online-payments.svg";
import OrderNumberField from "./OrderNumberField";
import SerialNumberField from "./SerialNumberField";
import Layout from "../../../shared/components/Layout";
import FormHeader from "../../../shared/components/checkout/FormHeader";
import SubmitButton from "../../../shared/components/checkout/SubmitButton";
import Field from "../../../shared/components/elements/Inputs/Field";
import FormOption from "../../../shared/components/elements/Inputs/FormOption";

const useStore = () => ({
  signup: useSignupStore(state => state.signup),
  getSignup: useSignupStore(state => state.getSignup),
  updateSignup: useSignupStore(state => state.updateSignup),
  isOnFinalStep: useStepStore(state => state.isOnFinalStep),
  finaliseSignup: useSignupStore(state => state.finaliseSignup),
  getNextStep: useStepStore(state => state.getNextStep),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

const ONLINE_SERVICES = "online_payments";

const onlineServices: Service[] = [
  Service.GIFT_VOUCHERS,
  Service.INVOICES,
  Service.PAYMENT_REQUEST,
  Service.WEBSITE,
];

const isSubmitDisabled = (services: string[]) => {
  if (services.length === 0) return true;

  if (services.includes(ONLINE_SERVICES)) {
    const onlinePaymentOptions = [
      Service.WEBSITE,
      Service.PAYMENT_REQUEST,
      Service.INVOICES,
    ];
    const hasOnlinePaymentOption = services.some(service =>
      onlinePaymentOptions.includes(service as Service)
    );
    if (!hasOnlinePaymentOption) return true;
  }

  return false;
};

const ServiceSelectionPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    signup,
    getSignup,
    updateSignup,
    isOnFinalStep,
    finaliseSignup,
    getNextStepLink,
  } = useStore();

  const [isReaderRegistration, setIsReaderRegistration] = useState(
    signup?.services.includes(Service.READER_REGISTRATION)
  );

  const [isWaitingForDelivery, setIsWaitingForDelivery] = useState(
    signup?.services.includes(Service.WAITING_FOR_DELIVERY)
  );

  useEffect(() => {
    setIsReaderRegistration(
      signup?.services.includes(Service.READER_REGISTRATION)
    );
    setIsWaitingForDelivery(
      signup?.services.includes(Service.WAITING_FOR_DELIVERY)
    );
  }, [signup?.services]);

  useEffect(() => {
    Segment.track("web_signup_intent_start");
  }, [location]);

  const initialServices = (services: string[]) => {
    if (services.some(service => onlineServices.includes(service as Service))) {
      services.push(ONLINE_SERVICES as Service);
    }
    return services;
  };

  const initialValues = {
    stage: SignupStep.ServiceSelectionPage,
    services: signup?.services ? initialServices(signup?.services) : [],
    serial_number: signup?.serial_number || "",
    order_number: signup?.order_number || "",
  };

  yup.addMethod(
    yup.string,
    "serialNumberAvailable",
    Validator.validateSerialNumberAvailable
  );

  yup.addMethod(yup.string, "orderNumber", Validator.validateOrderNumber);

  const serialNumberSchema = isReaderRegistration
    ? (yup as any)
        .string()
        .serialNumberAvailable()
        .required()
        .label("serial number")
    : yup.mixed();

  const orderNumberSchema = isWaitingForDelivery
    ? (yup as any).string().orderNumber().required().label("order number")
    : yup.mixed();

  const schema = yup.object({
    services: yup
      .array()
      .required("You must select at least one service.")
      .min(1, "You must select at least one service.")
      .label("services"),
    serial_number: serialNumberSchema,
    order_number: orderNumberSchema,
  });

  const setCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<typeof initialValues>,
    servicesToRemove?: Service[]
  ) => {
    const { name, value } = e.target;
    const initialCheckboxValues: Array<string> = uniq(formik.values[name]);
    let selectedServices: Array<string> = [...formik.values[name], value];

    if (initialCheckboxValues.includes(value)) {
      selectedServices = remove(initialCheckboxValues, item => {
        return item !== value;
      });
    }

    if (servicesToRemove) {
      selectedServices = selectedServices.filter(
        service => !servicesToRemove.includes(service as Service)
      );
    }

    if (
      value === ONLINE_SERVICES &&
      selectedServices.includes(ONLINE_SERVICES)
    ) {
      if (signup?.reseller_code) {
        selectedServices.push(Service.WEBSITE);
      }
    }

    const clearOnlineServices = (services: string[]) => {
      return services.filter(
        service => !onlineServices.includes(service as Service)
      );
    };

    if (!selectedServices.includes(ONLINE_SERVICES)) {
      selectedServices = clearOnlineServices(selectedServices);
    }

    const isReader = selectedServices.includes(Service.READER_REGISTRATION);
    setIsReaderRegistration(isReader);
    if (!isReader) {
      formik.setFieldValue("serial_number", "");
    }

    const isWaiting = selectedServices.includes(Service.WAITING_FOR_DELIVERY);
    setIsWaitingForDelivery(isWaiting);
    if (!isWaiting) {
      formik.setFieldValue("order_number", "");
    }

    formik.setFieldValue(name, selectedServices);
    formik.validateField(name);
  };

  const onSubmit = async (values: FormikValues) => {
    // We use a fake service called ONLINE_SERVICES to show/hide the actual online services.
    // Therefore, we strip this fake service from the services list before submitting.
    const newValues = { ...values };
    newValues.services = newValues.services.filter(
      service => service !== ONLINE_SERVICES
    );

    const isSignupUpdated = await updateSignup(newValues);

    if (isSignupUpdated) {
      Segment.trackWithSignup("web_signup_intent_click_next_button");

      if (isOnFinalStep()) {
        const isSignupFinalised = await finaliseSignup();

        if (isSignupFinalised) {
          const finalisedSignup = getSignup();

          navigate(
            formatRoute(SignupRoute.CompletePage, {
              id: finalisedSignup.id,
            })
          );
        }
      } else {
        navigate(getNextStepLink());
      }
    }
  };

  /**
   * Online payments are disabled if a reseller is signing up due to fraud
   * */
  const isNotResellerSignup =
    !signup?.reseller_code ||
    SignupService.isPartnerCode(signup?.reseller_code);

  return (
    <Layout>
      <FormHeader title="How can we get you started?" />
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {formik => {
          return (
            <Form>
              <div className="mb-32-v2">
                <div className="grid gap-40-v2 mb-4 sm-v2:justify-center">
                  <div
                    className={classNames(
                      "grid sm-v2:grid-cols-3 gap-16-v2",
                      isNotResellerSignup
                        ? "sm-v2:grid-cols-4"
                        : "sm-v2:grid-cols-3"
                    )}
                  >
                    <Field
                      type="checkbox"
                      name="services"
                      value={Service.READER_REGISTRATION}
                      option={{
                        label: "I have my card machine already",
                        image: activateCardIcon,
                        noFlex: true,
                        padding: true,
                        display: "block",
                      }}
                      component={FormOption}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        // noinspection JSIgnoredPromiseFromCall
                        setCheckbox(e, formik, [
                          Service.WAITING_FOR_DELIVERY,
                          Service.CARD_MACHINE,
                        ]);
                      }}
                      data-testid={makeTestID(
                        "serviceSelection",
                        "paymentLinks"
                      )}
                    />

                    {formik.values.services.includes(
                      Service.READER_REGISTRATION
                    ) ? (
                      <SerialNumberField className="block sm-v2:hidden mx-8-v2" />
                    ) : null}

                    <Field
                      type="checkbox"
                      name="services"
                      value={Service.WAITING_FOR_DELIVERY}
                      option={{
                        label: "I've ordered my card machine",
                        image: deliveryIcon,
                        noFlex: true,
                        padding: true,
                        display: "block",
                      }}
                      component={FormOption}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        // noinspection JSIgnoredPromiseFromCall
                        setCheckbox(e, formik, [
                          Service.CARD_MACHINE,
                          Service.READER_REGISTRATION,
                        ]);
                      }}
                      data-testid={makeTestID(
                        "serviceSelection",
                        "paymentLinks"
                      )}
                    />

                    {formik.values.services.includes(
                      Service.WAITING_FOR_DELIVERY
                    ) ? (
                      <OrderNumberField className="block sm-v2:hidden mx-8-v2" />
                    ) : null}

                    <Field
                      type="checkbox"
                      name="services"
                      value={Service.CARD_MACHINE}
                      option={{
                        label: "I want to buy a card machine",
                        image: buyCardIcon,
                        noFlex: true,
                        padding: true,
                        display: "block",
                      }}
                      component={FormOption}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        // noinspection JSIgnoredPromiseFromCall
                        setCheckbox(e, formik, [
                          Service.WAITING_FOR_DELIVERY,
                          Service.READER_REGISTRATION,
                        ]);
                      }}
                      data-testid={makeTestID(
                        "serviceSelection",
                        "paymentLinks"
                      )}
                    />

                    {isNotResellerSignup ? (
                      <Field
                        type="checkbox"
                        name="services"
                        value={ONLINE_SERVICES}
                        option={{
                          label: "I want to take payments online",
                          image: onlineCardPayment,
                          noFlex: true,
                          padding: true,
                          display: "block",
                        }}
                        component={FormOption}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          // noinspection JSIgnoredPromiseFromCall
                          setCheckbox(e, formik);
                        }}
                        data-testid={makeTestID("serviceSelection", "vouchers")}
                      />
                    ) : null}
                  </div>
                  {formik.values.services.includes(
                    Service.READER_REGISTRATION
                  ) ? (
                    <SerialNumberField className="hidden sm-v2:block" />
                  ) : null}

                  {formik.values.services.includes(
                    Service.WAITING_FOR_DELIVERY
                  ) ? (
                    <OrderNumberField className="hidden sm-v2:block" />
                  ) : null}
                </div>

                {formik.values.services.includes(ONLINE_SERVICES) ? (
                  <div className="mb-3 grid gap-16-v2 ">
                    <Field
                      type="checkbox"
                      name="services"
                      value={Service.WEBSITE}
                      option={{
                        label: "Yoco Gateway",
                        description:
                          "Accept payments on your online store with our ecommerce integration. Compatible with WooCommerce, Wix and Shopify.",
                      }}
                      component={FormOption}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        // noinspection JSIgnoredPromiseFromCall
                        setCheckbox(e, formik);
                      }}
                      data-testid={makeTestID(
                        "serviceSelection",
                        "onYourWebsite"
                      )}
                    />
                    <Field
                      type="checkbox"
                      name="services"
                      value={Service.PAYMENT_REQUEST}
                      option={{
                        label: "Yoco Link",
                        description:
                          "Accept payments online without a website. Send payment links to your customers on WhatsApp, email, social media or SMS.",
                      }}
                      component={FormOption}
                      className="mb-2"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        // noinspection JSIgnoredPromiseFromCall
                        setCheckbox(e, formik);
                      }}
                      data-testid={makeTestID(
                        "serviceSelection",
                        "paymentLinks"
                      )}
                    />
                    <Field
                      type="checkbox"
                      name="services"
                      value={Service.INVOICES}
                      option={{
                        label: "Yoco Invoice",
                        description:
                          "Get paid online by sending professional, tax-compliant invoices to your customers with a few clicks.",
                      }}
                      component={FormOption}
                      className="mb-4"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        // noinspection JSIgnoredPromiseFromCall
                        setCheckbox(e, formik);
                      }}
                      data-testid={makeTestID("serviceSelection", "invoices")}
                    />
                  </div>
                ) : null}
              </div>

              <SubmitButton
                disabled={isSubmitDisabled(formik.values.services)}
                testID={makeTestID("serviceSelection", "next")}
              />
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default withFetchSignup(
  ServiceSelectionPage,
  SignupStep.ServiceSelectionPage
);
