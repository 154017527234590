import CONFIG from "@/config";
import ShopApi from "@yoco/shop-sdk";

const shop = new ShopApi(CONFIG.apiURL);

export default shop;
// `*` exports dont seem to be supported in Vite,
// so we export the individual modules that are being used
export {
  type Cart,
  CartType,
  PaymentMethod,
  type Business,
  ReasonNotAvailable,
  type Product,
} from "@yoco/shop-sdk";
