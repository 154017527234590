import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes as ReactRoutes,
} from "react-router-dom";

import ShopApp from "@/shop/App";
import SignupApp from "@/signup/App";
import { NotFoundPage } from "@/signup/pages";

const Routes = () => {
  return (
    <Router>
      <ReactRoutes>
        <Route path="/" element={<Navigate to="/signup" />} />
        <Route path="xneelo" element={<SignupApp />} />
        <Route path="partner/:name" element={<SignupApp />} />
        <Route path="signup/*" element={<SignupApp />} />
        <Route path="shop/*" element={<ShopApp />} />
        {/* Show back to signup link as default not found */}
        <Route path="*" element={<NotFoundPage type="signup" />} />
      </ReactRoutes>
    </Router>
  );
};
export default Routes;
