import { ComponentProps } from "react";
import { Color } from "../../../constants/color";
import { formatPrice } from "../../../utils/price";
import Typography from "../elements/Typography";

type Props = {
  description: string;
  price?: number;
  value?: string;
  isPriceNegative?: boolean;
  size?: ComponentProps<typeof Typography>["size"];
};

const PriceBreakdownLine = ({
  description,
  price = 0,
  value = "-",
  isPriceNegative = false,
  size = "body-sm",
}: Props) => {
  const displayText =
    price > 0
      ? formatPrice(price, {
          shouldShowDecimals: true,
          hasThousandsSeparator: true,
        })
      : value;
  return (
    <div className="flex justify-between items-center">
      <Typography
        className="tracking-tight"
        text={description}
        size={size}
        font="grotesk"
        color={Color.Black}
        weight="book"
      />
      <Typography
        className="text-right tracking-tight"
        text={isPriceNegative && price > 0 ? `-${displayText}` : displayText}
        size={size}
        font="grotesk"
        color={Color.Black}
        weight="book"
      />
    </div>
  );
};

export default PriceBreakdownLine;
