import classNames from "classnames";

/* eslint-disable no-nested-ternary */
type Props = {
  color?: string;
  size?: "small" | "normal" | "large";
  className?: string;
};

/**
 * Note this is called Loader (non-v2) in `yoco-com`
 *
 * Not part of the V2 design system
 */
const Loader = ({
  color = "#222222",
  size = "normal",
  className,
}: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width={size === "small" ? 12 : size === "large" ? 40 : 20}
      height={size === "small" ? 12 : size === "large" ? 40 : 20}
      className={classNames("animate-spin", className)}
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={color}
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
      />
    </svg>
  );
};

export default Loader;
