import { getQueryStringParams } from "@/shared/utils/url";
import React, { useEffect } from "react";

const StitchInstantEFTResultPage = () => {
  const queryStringParams = getQueryStringParams();
  const paymentRequestID = String(queryStringParams.id);
  const { status } = queryStringParams;

  useEffect(() => {
    window.parent.postMessage(
      { event: "RECEIVED_STITCH_INSTANT_EFT_RESULT", status, paymentRequestID },
      "*"
    );
  }, [paymentRequestID, status]);

  return null;
};

export default StitchInstantEFTResultPage;
