import { useCartStore } from "@/shop/store";
import { useSignupStore } from "@/signup/store";
import { SignupRoute } from "@/signup/types";
import { isEmpty } from "lodash";
import React, { ComponentProps, ComponentType, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";

const useStore = () => ({
  signup: useSignupStore(state => state.signup),
  cart: useCartStore(state => state.cart),
});

export default <P extends object>(WrappedComponent: ComponentType<P>) => {
  const RedirectToProductCatalogueIfCartEmpty = (
    props: ComponentProps<typeof WrappedComponent>
  ) => {
    const navigate = useNavigate();
    const { signup, cart } = useStore();

    useEffect(() => {
      if (cart && isEmpty(cart.items)) {
        navigate(
          formatRoute(SignupRoute.ProductCataloguePage, { id: signup?.id })
        );
      }
    }, [cart, signup, navigate]);

    return <WrappedComponent {...props} />;
  };

  return RedirectToProductCatalogueIfCartEmpty;
};
