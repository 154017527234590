import { CartSource, StoreFront } from "@/libs/shop-sdk/types";
import ProductCataloguePage from "@/shop/pages/ProductCataloguePage";
import { useAppConfigStore, useCartStore } from "@/shop/store";
import Loader from "@/shared/components/elements/Loader";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const useStore = () => ({
  fetchBusiness: useAppConfigStore(state => state.fetchBusiness),
  resetCart: useCartStore(state => state.reset),
  isEmbed: useAppConfigStore(state => state.isEmbed),
  isInApp: useAppConfigStore(state => state.isInApp),
  storefront: useAppConfigStore(state => state.storefront),
  setCartSource: useCartStore(state => state.setCartSource),
});

const ExistingBusinessHomePage = () => {
  const { businessUUID } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const {
    fetchBusiness,
    resetCart,
    storefront,
    isEmbed,
    isInApp,
    setCartSource,
  } = useStore();

  useEffect(() => {
    resetCart();
  }, [location, resetCart]);

  useEffect(() => {
    if (isInApp) {
      setCartSource(CartSource.APP);
    } else if (storefront === StoreFront.PORTAL && isEmbed) {
      setCartSource(CartSource.PORTAL);
    } else if (storefront === StoreFront.HUB) {
      setCartSource(CartSource.HUB);
    }
  }, [setCartSource, isEmbed, isInApp, storefront]);

  useEffect(() => {
    if (businessUUID) {
      const loadAndFetchBusiness = async () => {
        setLoading(true);

        const isSuccessful = await fetchBusiness(businessUUID);

        if (isSuccessful) {
          setLoading(false);
        } else {
          toast.error(
            <>
              We are currently experiencing some technical difficulties.
              <br />
              <br />
              If the problem persists, please don&apos;t hesitate to contact our
              support team at{" "}
              <a href="mailto:support@yoco.com">support@yoco.com</a>
            </>
          );
        }
      };

      loadAndFetchBusiness();
    }
  }, [businessUUID, fetchBusiness]);

  return loading ? <Loader /> : <ProductCataloguePage />;
};

export default ExistingBusinessHomePage;
