import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import { findIndex, get } from "lodash";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import * as yup from "yup";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import { getParentIndex } from "@/shared/utils/helpers/general";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import {
  useFormHelperStore,
  useSignupStore,
  useStepStore,
} from "@/signup/store";
import { SignupRoute } from "@/signup/types";
import Layout from "../../../shared/components/Layout";
import FormHeader from "../../../shared/components/checkout/FormHeader";
import FormItemTitle from "../../../shared/components/checkout/FormItemTitle";
import DropdownInput from "../../../shared/components/elements/Inputs/DropdownInput";
import SubmitButton from "../../../shared/components/checkout/SubmitButton";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  getSignup: useSignupStore(state => state.getSignup),
  updateSignup: useSignupStore(state => state.updateSignup),
  businessCategories: useFormHelperStore(state => state.businessCategories),
  fetchBusinessCategories: useFormHelperStore(
    state => state.fetchBusinessCategories
  ),
  isOnFinalStep: useStepStore(state => state.isOnFinalStep),
  finaliseSignup: useSignupStore(state => state.finaliseSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
  getNextStep: useStepStore(state => state.getNextStep),
});

const BusinessCategoryPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    signup,
    getSignup,
    updateSignup,
    businessCategories,
    fetchBusinessCategories,
    isOnFinalStep,
    finaliseSignup,
    getNextStepLink,
    getNextStep,
  } = useStore();

  useEffect(() => {
    Segment.track("web_signup_business_details_mcc_group_start");
  }, [location]);

  useEffect(() => {
    fetchBusinessCategories();
  }, [fetchBusinessCategories]);

  // TODO: Change API to return parent category id
  const parentIndex: string = String(
    getParentIndex(businessCategories, `"id":${signup?.business_category_id}`)
  );

  const initialValues = {
    stage: SignupStep.BusinessCategoryPage,
    business_category_parent_id:
      (signup?.business_category_id &&
        String(businessCategories[parentIndex]?.id)) ||
      undefined,
    business_category_id:
      (signup?.business_category_id && String(signup?.business_category_id)) ||
      undefined,
  };

  const schema = yup.object({
    business_category_parent_id: yup
      .number()
      .required("You must select a business category.")
      .label("business category"),
    business_category_id: yup
      .number()
      .required("You must select a business subcategory.")
      .label("business subcategory"),
  });

  const onSubmit = async (values: FormikValues) => {
    const lastStep = isOnFinalStep();
    const isSignupUpdated = await updateSignup({
      business_category_id: Number(values.business_category_id),
      stage: lastStep
        ? SignupRoute.CompletePage
        : SignupRoute.BusinessCategoryPage,
    });

    if (isSignupUpdated) {
      Segment.trackWithSignup(
        "web_signup_business_details_mcc_group_next_button_clicked"
      );
      Segment.trackWithSignup("Opportunity Created", "milestones");
      if (lastStep) {
        const isSignupFinalised = await finaliseSignup();

        if (isSignupFinalised) {
          const finalisedSignup = getSignup();
          navigate(
            formatRoute(SignupRoute.CompletePage, {
              id: finalisedSignup.id,
            })
          );
        }
      } else {
        navigate(getNextStepLink());
      }
    }
  };

  return (
    <Layout>
      <FormHeader
        title="What do people pay you for?"
        description="Select category that best describes what your business does:"
      />
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => {
          const selectedId = get(values, "business_category_parent_id");

          const defaultBusinessCategories = findIndex(businessCategories, [
            "id",
            Number(selectedId),
          ]);

          const subCategories =
            businessCategories[defaultBusinessCategories]?.subcategories;
          return (
            <Form>
              <div className="flex flex-col gap-32-v2">
                <div>
                  <FormItemTitle title="Select your business category" />
                  <DropdownInput
                    name="business_category_parent_id"
                    id="business_category_parent_id"
                    label="Select an option"
                    onChange={e => {
                      setFieldValue(
                        "business_category_parent_id",
                        e.target.value
                      );
                      // Clear business_category_id when we change parent
                      // to prevent user from submitting old category
                      setFieldValue("business_category_id", "", false);
                    }}
                    options={
                      businessCategories?.map(category => ({
                        label: category.name,
                        value: String(category.id),
                      })) ?? []
                    }
                    data-testid={makeTestID("businessCategory")}
                  />
                </div>

                <div>
                  <FormItemTitle title="Select your business subcategory" />
                  <DropdownInput
                    name="business_category_id"
                    id="business_category_id"
                    label="Select an option"
                    disabled={!values.business_category_parent_id}
                    options={
                      subCategories?.map(subCategory => ({
                        label: subCategory.name,
                        value: String(subCategory.id),
                      })) ?? []
                    }
                    data-testid={makeTestID("businessSubcategory")}
                  />
                </div>

                <SubmitButton testID={makeTestID("businessCategory", "next")} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default withFetchSignup(
  BusinessCategoryPage,
  SignupStep.BusinessCategoryPage
);
