import { CartItem } from "@yoco/shop-sdk";
import { useState } from "react";
import { DrawerItemImage } from "./ItemImage";
import QuantityInput from "../../elements/Inputs/QuantityInput";
import Typography from "../../elements/Typography";
import { Color } from "../../../../constants/color";
import { formatPrice } from "../../../../utils/price";
import { useCartStore } from "../../../../shop/store";

const useStore = () => ({
  addProduct: useCartStore(state => state.add),
  addBundle: useCartStore(state => state.addBundle),
  removeProduct: useCartStore(state => state.remove),
  removeBundle: useCartStore(state => state.removeBundle),
});

type DrawerItemProps = {
  cartItem: CartItem;
  editable?: boolean;
};

export const DrawerItem = ({ cartItem, editable = false }: DrawerItemProps) => {
  const { addProduct, removeProduct, addBundle, removeBundle } = useStore();

  const [isQuantityLoading, setIsQuantityLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);

  const handleUpdateQuantity = async (newQuantity: number) => {
    setIsQuantityLoading(true);
    if (newQuantity > cartItem.quantity) {
      if (cartItem.bundle) {
        await addBundle(cartItem.bundle.id);
      } else {
        await addProduct(cartItem.product.id);
      }
    }
    if (newQuantity < cartItem.quantity) {
      if (cartItem.bundle) {
        await removeBundle(cartItem.bundle.id);
      } else {
        await removeProduct(cartItem.product.id);
      }
    }
    setIsQuantityLoading(false);
  };

  const handleRemoveItem = async () => {
    setIsRemoveLoading(true);
    if (cartItem.bundle) {
      await removeBundle(cartItem.bundle.id, cartItem.quantity);
    } else {
      await removeProduct(cartItem.product.id, cartItem.quantity);
    }
    setIsRemoveLoading(false);
  };

  return (
    <div className="flex w-full gap-12-v2 md-v2:items-center md-v2:grid md-v2:grid-cols-[60px_2fr_1fr]">
      <div className="h-60-v2 w-60-v2 md-v2:px-10-v2">
        <DrawerItemImage product={cartItem.product} bundle={cartItem.bundle} />
      </div>
      <div className="flex flex-col gap-2-v2 w-full">
        <Typography
          text={
            cartItem?.bundle
              ? cartItem.bundle.short_name
              : cartItem.product.short_name
          }
          weight="medium"
          font="grotesk"
          size="body-md-fixed"
          color={Color.Black}
        />
        <Typography
          text={formatPrice(
            cartItem?.bundle
              ? cartItem.bundle.unit_price
              : cartItem.product.unit_price,
            {
              hasThousandsSeparator: true,
              shouldShowDecimals: true,
            }
          )}
          weight="book"
          font="grotesk"
          size="body-md-fixed"
          color={Color.Black}
        />
      </div>
      <div className="ml-auto flex">
        {editable ? (
          <QuantityInput
            value={cartItem.quantity}
            maxValue={
              cartItem?.bundle
                ? cartItem.bundle.max_quantity_per_cart ?? undefined
                : cartItem.product.max_quantity_per_cart ?? undefined
            }
            onQuantityUpdate={async quantity => handleUpdateQuantity(quantity)}
            disabled={isQuantityLoading || isRemoveLoading}
            isDeleteEnabled
            onDelete={handleRemoveItem}
          />
        ) : (
          <div className="inline-flex gap-4-v2 mt-24-v2">
            <Typography
              text="Qty:"
              size="body-md-fixed"
              font="grotesk"
              color={Color.Black}
              weight="book"
              className="inline"
            />
            <Typography
              text={cartItem.quantity.toString()}
              size="body-md-fixed"
              font="grotesk"
              color={Color.Black}
              weight="medium"
              className="inline"
            />
          </div>
        )}
      </div>
    </div>
  );
};
