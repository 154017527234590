import { SignupStep } from "@yoco/sawubona-sdk";
import React, { useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Segment from "@/shared/services/Segment";
import { PaymentMethod } from "@/shared/shop/api";
import { useCartStore } from "@/shop/store";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import withRedirectToProductCatalogueIfCartEmpty from "@/signup/middleware/withRedirectToProductCatalogueIfCartEmpty";
import SignupService from "@/signup/services/SignupService";
import { useSignupStore } from "@/signup/store";
import { SignupRoute } from "@/signup/types";
import LayoutOrderSummary from "../../../shared/components/checkout/LayoutOrderSummary";
import PaymentMethodNav from "../../../shared/components/payment/PaymentMethodNav";
import DynamicPaymentMethod from "../../../shared/components/payment/DynamicPaymentMethod";

const useStore = () => ({
  updateSignup: useSignupStore(state => state.updateSignup),
  cart: useCartStore(state => state.cart),
  availablePaymentMethods: useCartStore(
    state => state.cart?.available_payment_methods
  ),
  intendedPaymentMethod: useCartStore(state => state.intendedPaymentMethod),
  setIntendedPaymentMethod: useCartStore(
    state => state.setIntendedPaymentMethod
  ),
});

const PaymentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    cart,
    availablePaymentMethods,
    intendedPaymentMethod,
    setIntendedPaymentMethod,
    updateSignup,
  } = useStore();

  useEffect(() => {
    Segment.track("web_signup_payment_start");
  }, [location]);

  useEffect(() => {
    if (cart?.balance_due.toString() === "0.00") {
      setIntendedPaymentMethod(PaymentMethod.FREE);
    }
  }, [cart, setIntendedPaymentMethod]);

  const onPaymentSuccess = useCallback(
    async (_: PaymentMethod, isPaid?: boolean) => {
      await useSignupStore.getState().refreshSignup();
      const signup = useSignupStore.getState().getSignup();

      Segment.trackWithSignup("web_signup_payment_click_pay_button");

      SignupService.onFinaliseComplete(signup);

      // The CompletePage will by default look at the payment state on the cart.  For certain payment methods which do
      // as async callback to us, we don't know that the order is paid for by the time the customer sees this page.
      // We therefore allow for an "isPaid" query string param to be passed. This only affects what the customer sees
      // on this page, not the actual payment state of the order.
      let url = formatRoute(SignupRoute.CompletePage, { id: signup?.id });
      if (isPaid !== undefined) {
        url += `?isPaid=${isPaid ? "true" : "false"}`;
      }
      await updateSignup({
        stage: SignupRoute.CompletePage,
      });
      navigate(url);
    },
    [navigate]
  );

  return (
    <LayoutOrderSummary title="Payment" mobileFooterVariant="payment">
      <div className="flex flex-col gap-32-v2 pb-[200px] md:mb-0">
        {!availablePaymentMethods?.includes(
          PaymentMethod.CREDIT_CARD_CHECKOUT
        ) && <PaymentMethodNav />}

        <DynamicPaymentMethod
          name={intendedPaymentMethod}
          onSuccess={onPaymentSuccess}
        />
      </div>
    </LayoutOrderSummary>
  );
};

export default withFetchSignup(
  withRedirectToProductCatalogueIfCartEmpty(PaymentPage),
  SignupStep.PaymentPage
);
