import classNames from "classnames";
import React, { ReactNode, useCallback, useMemo } from "react";

import { Link } from "react-router-dom";
import Segment from "@/shared/services/Segment";
import { Color } from "../../../../constants/color";

import { useIsBreakpoint } from "../../../../utils/screen";
import Arrow from "../Arrow";
import Typography, { TypographySize } from "../Typography";
import {
  getBorderColorClass,
  getBackgroundColorClass,
  getBackgroundHoverColorClass,
  getAccentColor,
  getTextColorClass,
  getTextHoverColorClass,
} from "../../../../utils/color";

type Props = {
  style: "solid" | "bordered";
  size: "large" | "medium" | "small";
  type?: "button" | "submit";
  color: Color;
  overrideTextColor?: Color;
  text: string;
  mobileText?: string;
  linkUrl?: string;
  mobileLinkUrl?: string;
  onClick?: () => void;
  hideIcon?: boolean;
  icon?: ReactNode;
  iconPosition?: "left" | "right";
  disabled?: boolean;
  trackingEvent?: string;
  trackingEventKey?: string;
  trackingEventValue?: string;
  className?: string;
  nonResponsive?: boolean;
  testID?: string;
  secondaryText?: string;
  fontSize?: TypographySize;
};

const PillButton: React.FC<Props> = ({
  style,
  size = "large",
  type = "button",
  color,
  overrideTextColor = getAccentColor(color),
  text,
  mobileText,
  linkUrl,
  mobileLinkUrl,
  onClick,
  hideIcon = false,
  icon = <Arrow />,
  disabled = false,
  trackingEvent,
  trackingEventKey,
  trackingEventValue,
  className,
  iconPosition = "right",
  nonResponsive = false,
  testID,
  secondaryText,
  fontSize,
}) => {
  const isMobile = useIsBreakpoint("mobile") && nonResponsive;

  const sizeOverride = isMobile ? "medium" : size;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>, link?: string) => {
      if (trackingEvent) {
        const event =
          trackingEventKey && trackingEventValue
            ? { [trackingEventKey]: trackingEventValue }
            : undefined;
        // using the segment tracker from this lib
        Segment.track(trackingEvent, event);
      }

      if (onClick) {
        onClick();
      } else if (link && link.startsWith("#")) {
        e.preventDefault();
        const element = document.querySelector(link);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    [trackingEvent, onClick, trackingEventKey, trackingEventValue]
  );

  const buttonClasses = useMemo(
    () =>
      classNames(
        getBorderColorClass(color),
        style === "solid"
          ? getBackgroundColorClass(color)
          : [
              "bg-transparent transition-colors duration-300",
              getBackgroundHoverColorClass(color),
            ],
        { "opacity-50 pointer-events-none": disabled },
        "group rounded-32-v2 border flex gap-10-v2 items-center w-full sm-v2:w-max justify-center",
        {
          "px-16-v2 py-12-v2 md-v2:px-[18px] md-v2:py-[14px] lg-v2:px-20-v2 lg-v2:py-16-v2":
            ["large", "medium"].includes(sizeOverride) && !isMobile,
        },
        {
          "px-20-v2 py-16-v2":
            ["large", "medium"].includes(sizeOverride) && isMobile,
        },
        {
          "px-16-v2 py-10-v2": size === "small",
        },
        { "flex-row-reverse": iconPosition === "left" },
        className
      ),
    [color, style, disabled, sizeOverride, isMobile, className, size]
  );

  const buttonContentClasses = classNames(
    "!leading-none transition-colors duration-300",
    style === "bordered" && getTextHoverColorClass(getAccentColor(color))
  );

  const buttonContent = (
    <>
      <div className="pt-[3px]">
        {mobileText ? (
          <Typography
            text={isMobile ? mobileText : text}
            weight="medium"
            font="grotesk"
            color={overrideTextColor}
            size={fontSize || (nonResponsive ? "body-lg-fixed" : "body-lg")}
            className={buttonContentClasses}
          />
        ) : (
          <Typography
            text={text}
            weight="medium"
            font="grotesk"
            color={overrideTextColor}
            size={
              fontSize ||
              (["small", "medium"].includes(size)
                ? nonResponsive
                  ? "body-md-fixed"
                  : "body-md"
                : nonResponsive
                ? "body-lg-fixed"
                : "body-lg")
            }
            className={buttonContentClasses}
          />
        )}

        {secondaryText && (
          <Typography
            text={secondaryText}
            weight="medium"
            font="grotesk"
            color={overrideTextColor}
            size={
              fontSize || (size === "large" ? "body-lg-fixed" : "body-md-fixed")
            }
            className={buttonContentClasses}
          />
        )}
      </div>
      {!hideIcon && (
        <div
          className={classNames(
            getTextColorClass(overrideTextColor),
            "w-20-v2 md-v2:w-[22px] lg-v2:w-24-v2",
            { "ml-10-v2": text },
            buttonContentClasses
          )}
        >
          {icon}
        </div>
      )}
    </>
  );

  if (linkUrl && mobileLinkUrl) {
    return (
      <Link
        to={isMobile ? mobileLinkUrl : linkUrl}
        onClick={e => handleClick(e, isMobile ? mobileLinkUrl : linkUrl)}
        className={buttonClasses}
      >
        {buttonContent}
      </Link>
    );
  }
  if (linkUrl && !mobileLinkUrl) {
    return (
      <Link
        to={linkUrl}
        onClick={e => handleClick(e, linkUrl)}
        className={buttonClasses}
      >
        {buttonContent}
      </Link>
    );
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={e => handleClick(e)}
      className={buttonClasses}
      disabled={disabled}
      data-testid={testID}
    >
      {buttonContent}
    </button>
  );
};

export default PillButton;
