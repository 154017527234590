// ToDo V2 Design
import { FormikValues } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import useScript from "react-script-hook";
import * as yup from "yup";
import { ObjectShape } from "yup/lib/object";
import buttonLoaderImage from "@/assets/images/loader.gif";
import CONFIG from "@/config";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import SubmitButton from "@/shared/components/V1/form/SubmitButton";
import Loader from "@/shared/components/V1/Loader";
import { showError } from "@/shared/middleware/withHandleNetworkRequestErrors";
import shop, { PaymentMethod } from "@/shared/shop/api";
import { makeTestID } from "@/shared/utils/development";
import CardIcons from "@/shop/pages/PaymentPage/CardIcons";
import padlockIcon from "@/shop/pages/PaymentPage/images/padlock.svg";
import { useCartStore } from "@/shop/store";

type Props<TValues extends FormikValues = FormikValues> = {
  amount?: string;
  validationSchema?: ObjectShape;
  initialValues?: TValues;
  onSubmit?: (values: FormikValues) => Promise<boolean>;
  onTransactionComplete?: (result: TValues) => Promise<void>;
  tokenize?: boolean;
  apiPublicKey?: string;
  paymentMethod?: PaymentMethod;
  children?: React.ReactNode;
};

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  intendedPaymentMethod: useCartStore(state => state.intendedPaymentMethod),
});

const CreditCardForm: React.FC<Props> = ({
  amount,
  onTransactionComplete,
  tokenize = false,
  validationSchema,
  initialValues,
  apiPublicKey = CONFIG.blackbirdPublicKey,
  paymentMethod = PaymentMethod.CREDIT_CARD_BLACKBIRD,
  children,
  ...props
}: Props) => {
  const { cart, intendedPaymentMethod } = useStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [isScriptLoading, scriptError] = useScript({
    src: CONFIG.blackbirdSDKUrl,
    checkForExisting: false,
  });
  // TODO: R&D The type
  const [blackbirdForm, setBlackbirdForm] = useState<any>();
  const [isCreditCardFormValid, setCreditCardFormValid] =
    useState<boolean>(false);
  const isScriptLoaded = !isScriptLoading && scriptError === null;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isScriptLoaded && window.BlackbirdSDK) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const sdk = new window.BlackbirdSDK({
        publicKey: apiPublicKey,
      });

      const inline = sdk.inline({
        layout: "basic",
        showErrors: true,
      });

      // @ts-ignore
      inline.on(window.BlackbirdSDK.Events.VALIDITY_CHANGE, () => {
        setCreditCardFormValid(inline.isValid());
      });

      // TODO: We need to generate a unique id for the component
      inline.mount("#card-frame");

      setBlackbirdForm(inline);
    }
  }, [isScriptLoaded, setBlackbirdForm, apiPublicKey]);

  const schema = yup.object(validationSchema || {});

  const onSubmit = async (values: FormikValues) => {
    if (!isCreditCardFormValid) {
      return;
    }

    setLoading(true);

    try {
      const isOnSubmitSuccessful = props.onSubmit
        ? await props.onSubmit(values)
        : true;

      if (isOnSubmitSuccessful) {
        const initiatePaymentResponse =
          await shop.carts.initiateBlackbirdPayment(
            cart?.id || "",
            paymentMethod,
            tokenize,
            amount
          );

        const object = tokenize
          ? {
              id: initiatePaymentResponse.id,
              customer: initiatePaymentResponse.blackbird_customer_id,
              paymentMethodDetails: {
                save: tokenize,
                usable: "offline",
              },
            }
          : {
              id: initiatePaymentResponse.id,
              customer: initiatePaymentResponse.blackbird_customer_id,
            };

        const result = await blackbirdForm.submit(object);

        if (onTransactionComplete) {
          await onTransactionComplete(result);
        }

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      showError(error);
      setLoading(false);
    }
  };

  const paymentAmount = useMemo(() => {
    return intendedPaymentMethod === PaymentMethod.PAYMENT_TERMS
      ? cart?.instalment_plan_proposal.instalments[0].amount_formatted
      : cart?.balance_due_formatted;
  }, [intendedPaymentMethod, cart]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {() => {
        const button = (
          <SubmitButton
            disabled={loading}
            testID={makeTestID("payment", "payByCard")}
          >
            {!loading ? (
              <div className="flex flex-row justify-center space-x-4">
                <img src={padlockIcon} alt="pad lock icon" />
                <span>Pay {paymentAmount}</span>
              </div>
            ) : (
              <img
                alt="Loading..."
                className="mx-auto"
                width={25}
                src={buttonLoaderImage}
              />
            )}
          </SubmitButton>
        );

        return (
          <Form>
            {children}
            {isScriptLoaded ? (
              <div>
                <div className="float-right">
                  {" "}
                  <CardIcons />
                </div>
                <br />
                <div id="card-frame" className="card-frame" />
              </div>
            ) : (
              <Loader />
            )}
            <div className="hidden md:block mt-8">{button}</div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreditCardForm;
