import {
  Formik as BaseFormik,
  FormikHelpers,
  FormikProps,
  FormikValues,
} from "formik";
import React from "react";
import { AnyObjectSchema } from "yup";

type Props<TValues extends FormikValues> = {
  children: (formik: FormikProps<TValues>) => React.ReactNode;
  initialValues?: TValues;
  validationSchema?: AnyObjectSchema;
  onSubmit: (values: TValues, actions: FormikHelpers<TValues>) => void;
  [x: string]: unknown;
};

const Formik = <TValues extends FormikValues>({
  children,
  initialValues = {} as TValues,
  validationSchema,
  onSubmit,
  ...props
}: Props<TValues>) => {
  return (
    <BaseFormik<TValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      {...props}
    >
      {children}
    </BaseFormik>
  );
};

export default Formik;
