import { sawubona } from "@yoco/sawubona-sdk";
import { AxiosError, AxiosResponse } from "axios";
import { useFormikContext, FormikProps } from "formik";
import { isEmpty } from "lodash";
import React, { ReactElement, useEffect } from "react";
import { toast } from "react-toastify";

const getErrorMessageFromResponse = (
  response: AxiosResponse
): ReactElement | string => {
  switch (response.status) {
    case 400:
      // The backend returns validation errors in a format such as:
      // { "email": ["The email is already in use."] }
      // We'll grab the first error we got back, and toastify that

      if (response.data && !isEmpty(response.data)) {
        return String(Object.values(response.data)[0]);
      }

      return " Please make sure all required fields are filled out correctly.";
    case 429:
      return (
        <>
          We have received too many requests from your internet browser over the
          last minute.
          <br />
          <br />
          Please wait a minute before trying again, and if the problem persists,
          reach out to our support at{" "}
          <a href="mailto:support@yoco.com">support@yoco.com</a>
        </>
      );
    case 503:
      return "We are currently performing some scheduled maintenance. We will be back as soon as possible.";
    default:
      return (
        <>
          We are currently experiencing some technical difficulties.
          <br />
          <br />
          If the problem persists, please don&apos;t hesitate to contact our
          support team at <a href="mailto:support@yoco.com">support@yoco.com</a>
        </>
      );
  }
};
const getErrorMessage = (error: AxiosError): ReactElement | string => {
  if (error.response) {
    return getErrorMessageFromResponse(error.response);
  }

  return (
    <>
      An unexpected error occurred.
      <br />
      <br />
      If the problem persists, please don&apos;t hesitate to contact our support
      team at <a href="mailto:support@yoco.com">support@yoco.com</a>
    </>
  );
};

const setFormikFieldErrors = (
  formik: FormikProps<unknown>,
  error: AxiosError
) => {
  // If this http error was caused by a server-side 400, we'll do some special handling, and try and show
  // the "fields" which failed validation, next to their respective form controls.
  if (
    error.response &&
    error.response.status === 400 &&
    error.response.data &&
    !isEmpty(error.response.data)
  ) {
    const errors = { ...error.response.data };

    if ("non_field_errors" in errors) {
      delete errors.non_field_errors;
    }

    formik.setErrors(errors);

    const fields = Object.keys(errors);

    fields.forEach(field => () => {
      formik.setFieldTouched(field, true);
    });
  }
};

/** Will attempt to parse the error and display an appropriate message to the user.
 * If the error is unknown, displays a generic error message. */
export const showError = error => toast.error(getErrorMessage(error));

export default WrappedComponent => {
  const HandleNetworkRequestErrors = props => {
    const formik = useFormikContext();

    useEffect(() => {
      const { axios } = sawubona.http;

      const interceptor = axios.interceptors.response.use(
        response => {
          return response;
        },
        error => {
          showError(error);

          setFormikFieldErrors(formik, error);

          return Promise.reject(error);
        }
      );

      return () => {
        axios.interceptors.response.eject(interceptor);
      };
    }, [formik]);

    return <WrappedComponent {...props} />;
  };

  return HandleNetworkRequestErrors;
};
