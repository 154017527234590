import { ReactNode } from "react";
import { Drawer } from "vaul";

import { devtools } from "zustand/middleware";
import create from "zustand";
import { useIsBreakpoint } from "../../../../utils/screen";

type Props = {
  children: ReactNode;
};

type CartDrawerState = {
  cartDrawerOpen: boolean;
  setCartDrawerOpen: (open: boolean) => void;
};

export const useCartDrawerStore = create<CartDrawerState>()(
  devtools(set => ({
    cartDrawerOpen: false,
    setCartDrawerOpen: (open: boolean) => set({ cartDrawerOpen: open }),
  }))
);

export const CheckoutDrawerRoot = ({ children }: Props) => {
  const isLaptop = useIsBreakpoint("laptop");
  const { cartDrawerOpen, setCartDrawerOpen } = useCartDrawerStore();

  return (
    <Drawer.Root
      open={cartDrawerOpen}
      onOpenChange={open => setCartDrawerOpen(open)}
      direction={isLaptop ? "right" : "bottom"}
    >
      {children}
    </Drawer.Root>
  );
};
