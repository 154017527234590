import { CartSource } from "@/libs/shop-sdk/types";
import ProductCataloguePage from "@/shop/pages/ProductCataloguePage";
import { useCartStore } from "@/shop/store";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useStore = () => ({
  resetCart: useCartStore(state => state.reset),
  setCartSource: useCartStore(state => state.setCartSource),
});

const HomePage = () => {
  const location = useLocation();
  const { resetCart, setCartSource } = useStore();

  useEffect(() => {
    resetCart();
  }, [location, resetCart]);

  useEffect(() => {
    setCartSource(CartSource.SHOP);
  }, [location, setCartSource]);
  return <ProductCataloguePage />;
};

export default HomePage;
