import {
  useAppConfigStore,
  useSignupStore,
  useStepStore,
} from "@/signup/store";
import { SignupRoute } from "@/signup/types";
import { SignupStatus, SignupStep } from "@yoco/sawubona-sdk";
import { indexOf } from "lodash";
import React, { ComponentProps, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { validate as isValidUUID } from "uuid";
import shallow from "zustand/shallow";

const useStore = () => ({
  signup: useSignupStore(state => state.signup),
  getSignup: useSignupStore(state => state.getSignup),
  fetchSignup: useSignupStore(state => state.fetchSignup),
  refreshSteps: useStepStore(state => state.refreshSteps),
  steps: useStepStore(state => state.steps, shallow),
  setActiveStep: useStepStore(state => state.setActiveStep),
  getStepAfter: useStepStore(state => state.getStepAfter),
  userJourney: useAppConfigStore(state => state.userJourney),
  skipServiceSelectionStep: useAppConfigStore(
    state => state.skipServiceSelectionStep
  ),
  skipProductCatalogueStep: useAppConfigStore(
    state => state.skipProductCatalogueStep
  ),
});

export default <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  currentStep?: SignupStep
) => {
  const FetchSignup = (props: ComponentProps<typeof WrappedComponent>) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const {
      signup,
      getSignup,
      fetchSignup,
      refreshSteps,
      setActiveStep,
      steps,
      userJourney,
      skipServiceSelectionStep,
      skipProductCatalogueStep,
      getStepAfter,
    } = useStore();

    useEffect(() => {
      refreshSteps();
    }, [
      signup,
      userJourney,
      skipServiceSelectionStep,
      skipProductCatalogueStep,
      refreshSteps,
    ]);

    useEffect(() => {
      if (id && isValidUUID(id)) {
        fetchSignup(id)
          .then(() => {
            const { activeStep } = useStepStore.getState();
            const { status, stage } = getSignup();
            const isOnCompleteStep = activeStep === SignupStep.CompletePage;

            if (status === SignupStatus.COMPLETE && !isOnCompleteStep) {
              navigate(
                formatRoute(SignupRoute.CompletePage, {
                  id,
                })
              );
            } else if (status === SignupStatus.CANCELLED) {
              navigate(SignupRoute.HomePage);
            } else if (
              currentStep &&
              !(status === SignupStatus.COMPLETE && isOnCompleteStep)
            ) {
              const nextStep = getStepAfter(stage);

              const isAhead =
                indexOf(steps, currentStep) > indexOf(steps, nextStep) ||
                indexOf(steps, currentStep) === -1;
              if (nextStep && isAhead) {
                navigate(formatRoute(nextStep, { id }));
              } else setActiveStep(currentStep);
            }
          })
          .catch(() => {
            navigate(SignupRoute.HomePage);
          });
      } else {
        navigate(SignupRoute.HomePage);
      }
    }, [
      id,
      navigate,
      fetchSignup,
      getSignup,
      refreshSteps,
      getStepAfter,
      setActiveStep,
      steps,
    ]);

    return <WrappedComponent {...props} />;
  };

  return FetchSignup;
};
