// ToDo V2 Design
import React from "react";

const TermsContract = () => {
  return (
    <article className="text-xs">
      <header className="text-center mb-2">
        <h1>SALE AND CESSION AGREEMENT</h1>
        <p>(CARD MACHINE FINANCE)</p>
      </header>
      <section className="mb-6">
        <ol className="list-decimal list-inside">
          <li className="mb-2">
            This agreement is entered into between:
            <ol className="list-decimal list-outside pl-8">
              <li>__________________ (“You” or “the Merchant”);</li>
              <li>Retail Capital Limited (“Retail Capital”); and</li>
              <li>Yoco Technologies (Pty) Ltd (“Yoco”).</li>
            </ol>
          </li>
          <li className="mb-2">
            Key pricing terms
            <ol className="list-decimal list-outside pl-8">
              <li>
                Deposit <br /> (your initial payment to Yoco):
                <span style={{ float: "right" }}>R ___</span>
              </li>
              <li>
                Purchase Price <br /> (amount funded by Retail Capital and paid
                to Yoco on your behalf)
                <span style={{ float: "right" }}>R ___</span>
              </li>
              <li>
                Purchased Amount <br /> (amount to be paid by you to Retail
                Capital in the form of 2 monthly debit payments)
                <span style={{ float: "right" }}>R ___</span>
              </li>
            </ol>
          </li>
          <li className="mb-2">
            Introduction <br />
            <ol className="list-decimal list-outside pl-8">
              <li>You are purchasing a card machine from Yoco.</li>
              <li>
                The price of the card machine is made up of (i) the Deposit and
                (ii) the Purchase Price.
              </li>
              <li>
                You agree to pay the immediate Deposit to Yoco, upon purchasing
                the card machine.
              </li>
              <br />
              <p>
                You have chosen to fund the Purchase Price through Retail
                Capital, Yoco’s partner, that offers card machine financing.{" "}
              </p>
              <br />
              <li>
                For this purpose, you agree to sell to Retail Capital your right
                to receive an agreed value of future payments from your
                customers (“<strong>Future Receivables</strong>”). You hereby
                cede and transfer, and Retail Capital purchases and accepts
                cession of, the Future Receivables, up to the amount of the
                Purchase Amount.
              </li>
              <li>
                As consideration for purchasing your Future Receivables, Retail
                Capital shall pay the{" "}
                <strong>Purchase Price to Yoco, on your behalf.</strong>
              </li>
              <li>
                This agreement comes into effect and shall be binding upon you
                on the date on which you have clicked to indicate your
                acceptance of this agreement (“Acceptance Date”) on the Yoco
                payments page, where you’ve selected the card machine financing
                option (“Offer Page”).
              </li>
            </ol>
          </li>
          <li className="mb-2">
            Collection and remittance of the Purchased Amount
            <ol className="list-decimal list-outside pl-8">
              <li>
                You are hereby appointed, which appointment you hereby accept,
                to collect the Purchased Amount from your customers, as agent
                for and on behalf of Retail Capital, and to pay from the amounts
                so collected, <strong>two (2) equal amounts</strong>. The first
                such payment shall be made one month after the Acceptance Date,
                with the next payment one (1) month later.
              </li>
              <li>
                Your payments to Retail Capital shall be made by way of{" "}
                <strong>debit- or credit card payments</strong>, which payments
                you hereby authorise. The payments shall be processed against
                your designated debit- or credit card account, the details of
                which shall be as provided by you to Yoco from time to time.
              </li>
              <li>
                You hereby agree and authorise Yoco to suspend and to disable
                the Yoco card payment device if you default in honouring your
                payment obligations under this agreement. Any such suspension
                and disablement may remain in place as long as you remain in
                default of your payment obligations.
              </li>
            </ol>
          </li>
          <li className="mb-2">
            <strong>
              You hereby consent and authorise Yoco to share information that
              pertains to Future Receivables with Retail Capital for the
              purposes of this agreement, for the duration of this agreement.
            </strong>
          </li>
          <li className="mb-2">
            You may request an account reconciliation from Retail Capital, to
            indicate your payment obligations.
          </li>
          <li className="mb-2">
            This agreement, which includes the attached Standard Terms and
            Conditions, shall be in addition to any existing agreement that you
            may have with Retail Capital.
          </li>
        </ol>
      </section>
      <section>
        <h3 className="text-center font-bold">STANDARD TERMS AND CONDITIONS</h3>
        <ol className="list-decimal list-inside">
          <li className="mb-2">
            <strong>DATA PERMISSIONS AND CONSENTS</strong>
            <ol className="list-decimal list-outside pl-8">
              <li className="mb-2">
                <strong>
                  By entering into this Agreement, the Merchant confirms,
                  acknowledges and agrees that
                </strong>{" "}
                Retail Capital may collect, process and use the particulars of
                the Merchant as contained in this Agreement.
              </li>
              <li className="mb-2">
                The Merchant acknowledges that information required by Retail
                Capital under this Agreement is necessary for the conclusion of
                the Agreement and for the implementation of the Agreement and,
                without such information, this Agreement cannot be concluded.
              </li>
              <li className="mb-2">
                Retail Capital will retain the Merchant’s personal information
                for as long as it is necessary to perform its obligations under
                this Agreement or as may be required by law.
              </li>
              <li className="mb-2">
                The Merchant shall be entitled to access its personal
                information at Retail Capital and to rectify its personal
                information if it is incorrect or inaccurate. The Merchant may
                also request that its personal information be deleted by Retail
                Capital, subject to applicable law and subject to Retail
                Capital’s right to retain all information reasonably required to
                perform its functions and to implement and enforce its rights
                under this Agreement.
              </li>
              <li className="mb-2">
                Retail Capital shall protect the Merchant’s personal information
                through appropriate technical and organizational security
                measures to minimize risks associated with data loss, misuse,
                unauthorized access, and unauthorized disclosure and alteration.
                Retail Capital will notify the Merchant of any confirmed data
                breaches that has occurred and it is the Merchant’s
                responsibility to notify relevant supervisory authorities of the
                data breach.
              </li>
              <li className="mb-2">
                Retail Capital does not warrant the effectiveness of its
                technical and organizational security measures to minimize risks
                associated with data loss, misuse, unauthorized access, and
                unauthorized disclosure and alteration and shall under no
                circumstances become liable to the Merchant for any damages,
                losses costs or otherwise in this respect, unless it was a
                direct result of gross negligence or intentional misconduct on
                the part of Retail Capital.
              </li>
              <li className="mb-2">
                The Merchant may complain to the Information Regulator at
                https://justice.gov.za/inforeg/ if the Merchant is unhappy with
                the way Retail Capital handles its personal information under
                this Agreement.
              </li>
            </ol>
          </li>
          <li className="mb-2">
            <strong>GENERAL</strong>
            <ol className="list-decimal list-outside pl-8">
              <li className="mb-2">
                <span className="underline">Whole agreement</span>. This
                agreement constitutes the whole agreement between the Parties
                relating to the matters dealt with in this agreement and save to
                the extent otherwise provided herein, no undertaking,
                representation, term or condition relating to the subject matter
                of this agreement not incorporated in this agreement shall be
                binding on any of the Parties.
              </li>
              <li className="mb-2">
                <span className="underline">Severability</span>. Each provision
                of this agreement is severable from the other provisions. Should
                any provision be found by a Court of competent jurisdiction to
                be invalid or unenforceable for any reason, the Parties will
                consult with one another in good faith in order to agree, if
                possible, an alternative provision in accordance with the intent
                and tenor of this agreement. The remaining provisions of this
                agreement shall nevertheless remain binding and continue with
                full force and effect.
              </li>
              <li className="mb-2">
                <span className="underline">No Cession or Assignment</span>.
                Save as otherwise herein provided, neither this agreement nor
                any part, share or interest therein nor any rights or
                obligations hereunder may be ceded, assigned, or otherwise
                transferred by the Merchant without Retail Capital’s prior
                written consent. The Merchant further hereby irrevocably and
                unconditionally consents to the cession, delegation, assignment
                or other transferral by Retail Capital of all or any of its
                rights or obligations in terms of this Agreement,
                notwithstanding that such cession or assignment, if made to more
                than one person and/or of any but not all of Retail Capital’s
                rights, may result in a splitting of claims. The Merchant shall,
                if so required by any cessionary or cessionaries, perform all
                such obligations and make any payments direct to such cessionary
                or cessionaries.
              </li>
              <li className="mb-2">
                <span className="underline">Non-Variation</span>. No variation,
                addition, deletion, or agreed cancellation of this agreement,
                including this clause, will be of any force or effect unless in
                writing and signed by or on behalf of the Parties hereto.
              </li>
              <li className="mb-2">
                <span className="underline">No Waiver</span>. No waiver of any
                of the terms and conditions of this agreement will be binding or
                effectual for any purpose unless in writing and signed by or on
                behalf of the Party giving the same. Any such waiver will be
                effective only in the specific instance and for the purpose
                given. No failure or delay on the part of either Party hereto in
                exercising any right, power or privilege hereunder will
                constitute or be deemed to be a waiver thereof, nor will any
                single or partial exercise of any right, power or privilege
                preclude any other or further exercise thereof or the exercise
                of any other right, power or privilege.
              </li>
              <li className="mb-2">
                <span className="underline">Signature on Counterparts</span>.
                This agreement may be executed in any number of counterparts.
                This has the same effect as if the signatures on the
                counterparts were on a single copy of this agreement.
              </li>
            </ol>
          </li>
        </ol>
      </section>
      <section>
        <p className="text-xs text-gray-600 text-center">
          Retail Capital Limited, Reg no: (2010/019149/06). The Palms, 145 Sir
          Lowry Road, Woodstock, Cape Town, 7915, South Africa. Switchboard 0861
          17 17 17. www.retailcapital.co.za Executive Directors: K.H Westvig
          (CEO), T Posthumus Non-executive Directors: G.W de Jager, K Gordon, A
          Narrandes, S.W Van Der Merwe, N. Chigwada, D.C Sparrow
        </p>
      </section>
    </article>
  );
};

export default TermsContract;
