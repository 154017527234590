import xneeloImage from "@/assets/images/xneelo-img.png";
import logoImage from "@/assets/images/xneelo-logo.svg";
// ToDo V2 Design
import Text from "@/shared/components/V1/Text";
import { useSignupStore } from "@/signup/store";
import { UserJourney } from "@/signup/types";
import Typography from "../../../shared/components/elements/Typography";
import { Color } from "../../../constants/color";
import FormHeader, {
  FormHeaderDescription,
  FormHeaderTitle,
  FormHeaderWrapper,
} from "../../../shared/components/checkout/FormHeader";

const useStore = () => ({
  referralDetails: useSignupStore(state => state.referralDetails),
});
type Props = {
  hasReferralCode: boolean;
  userJourney: UserJourney | null;
};

// TODO investigate if we need a V2 Design for this
const XneeloHeader = () => {
  return (
    <FormHeaderWrapper>
      <div className="logo">
        <img
          className="yoco-logo motion-reduce:transform-none hover:-translate-x-1w hover:scale-110 transition ease-in-out duration-300 mt-2 w-32"
          src={logoImage}
          alt="Yoco Logo"
        />
      </div>
      <FormHeaderTitle
        color={Color.Green}
        text="Grow your business with xneelo and Yoco"
      />
      <div className="sm:w-3/4 sm:mx-auto mb-3 mt-3">
        <img src={xneeloImage} alt="Woman using a laptop" />
      </div>
      <FormHeaderDescription
        text="Use the form below to sign up for Yoco's payment gateway and enjoy
        zero transaction fees for the first three months"
      />
      <Typography
        size="subscript-sm"
        font="grotesk"
        weight="book"
        color={Color.Black}
        text="*Transaction fees will be billed, but fully refunded at the end of each
        month."
        className="text-center"
      />
    </FormHeaderWrapper>
  );
};

// TODO investigate if we need a V2 Design for this
const ReferralHeader = ({
  userJourney = UserJourney.BANANA,
}: Omit<Props, "hasReferralCode">) => {
  const { referralDetails } = useStore();

  return (
    <div className="mx-auto mb-4 sm:mb-3 flex justify-center flex-col">
      <div className="mt-6 mb-8 sm:mb-12 sm:w-3/4 md:w-full md:max-w-lg sm:mx-auto">
        <div className="sharp-grotesk relative text-center bg-yoco-dark-green leading-tight rounded-3xl lg:rounded-6xl mb-10 lg:mb-12">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl py-5 px-2">
            <span className="text-white">
              You&apos;ve been referred{" "}
              <span role="img" aria-labelledby="eyes">
                👀
              </span>{" "}
              get{" "}
            </span>
            <span className="text-yoco-yellow-100">R100 off</span>
          </h1>

          <div className="absolute w-full text-yoco-dark-green -mt-3.5 sm:-mt-4.5 lg:-mt-5">
            <svg
              version="1.1"
              viewBox="0 0 49 98"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              className="fill-current rotate-90 h-12 sm:h-16 lg:h-20 w-12 sm:w-16 lg:w-20 mx-auto"
            >
              <path d="M-0.00117166017,0 C-0.00117166017,27.0155468 21.9252768,48.9228862 49,48.9964948 C21.9252768,49.0771138 -0.00117166017,70.9832848 -0.00117166017,98 C-0.00117166017,70.9832848 -0.00117166017,27.0155468 -0.00117166017,0 Z" />
            </svg>
          </div>
        </div>

        <Text size="h4" className="text-center">
          <span className="font-normal">
            {referralDetails?.ownerFirstName || "Your Friend"} thinks you need a
            Yoco card machine for less{" "}
            <span role="img" aria-labelledby="smirk">
              😏
            </span>
          </span>

          <span className="font-bold"> get R100 off. It&apos;s on us!</span>
        </Text>
      </div>

      {userJourney === UserJourney.CINNAMON && (
        <h6 className="block text-base text-black-600">
          Please fill in the business owner&apos;s details if you&apos;re doing
          this for them.
        </h6>
      )}
    </div>
  );
};

const YocoShopHeader = () => {
  return <FormHeader title="Create your profile" />;
};

const DefaultHeader = ({
  userJourney = UserJourney.BANANA,
}: Omit<Props, "hasReferralCode">) => {
  return (
    <FormHeader
      title={
        userJourney === UserJourney.DEVELOPER
          ? "Sign up for a developer account"
          : "Let's get started"
      }
      description={
        userJourney === UserJourney.CINNAMON
          ? `Please fill in the business owner's details if you're doing
          this for them.`
          : undefined
      }
    />
  );
};

const LeadFormHeader = ({
  hasReferralCode,
  userJourney = UserJourney.BANANA,
}: Props) => {
  if (userJourney === UserJourney.XNEELO) {
    return <XneeloHeader />;
  }

  if (hasReferralCode) {
    return <ReferralHeader userJourney={userJourney} />;
  }

  if (userJourney === UserJourney.YOCOSHOP) {
    return <YocoShopHeader />;
  }

  return <DefaultHeader userJourney={userJourney} />;
};

export default LeadFormHeader;
