import classNames from "classnames";
import { ComponentProps } from "react";
import { CheckoutDrawerRoot } from "./checkout/Drawer/Root";
import { CheckoutDrawer } from "./checkout/Drawer";

import Header from "./Header";
import Section from "./elements/Section";

type LayoutProps = {
  children: React.ReactNode;
  headerVariant?: "desktop" | "mobile";
  /**
   * Set this to show a fixed mobile footer
   */
  mobileFooterVariant?: ComponentProps<
    typeof CheckoutDrawer
  >["mobileFooterVariant"];
  /**
   * Use this prop for pages where we have explicit page width
   * (eg: in `LayoutOrderSummary`)
   */
  fullWidth?: boolean;
  /**
   * Allow editing cart items
   * and setting a promo code
   */
  isCartEditable?: boolean;
};

const Layout = ({
  children,
  headerVariant,
  mobileFooterVariant,
  fullWidth,
  isCartEditable = true,
}: LayoutProps) => {
  return (
    <CheckoutDrawerRoot>
      <CheckoutDrawer
        mobileFooterVariant={mobileFooterVariant}
        isCartEditable={isCartEditable}
      />
      <div className="flex min-h-screen flex-col">
        <Header headerVariant={headerVariant} />
        <Section>
          <div
            className={classNames(
              { "sm:w-[600px] mx-auto": !fullWidth },
              // Bottom padding to prevent mobile footer
              // rendering over content
              { "pb-144-v2": !!mobileFooterVariant },
              "mb-32-v2"
            )}
          >
            {children}
          </div>
        </Section>
      </div>
    </CheckoutDrawerRoot>
  );
};

export default Layout;
