import { Address } from "@/shared/types";
import { useFormikContext } from "formik";
import { useSharedFormHelperStore } from "@/shared/store/formHelper";
import React, { useCallback, useEffect } from "react";
import CommonAddressFormField, {
  updateCommonAddressValues,
  updateZAAddressValues,
} from "./CommonAddressFormField";
import TextInput from "../TextInput";
import DropdownInput from "../DropdownInput";

const useStore = () => ({
  fetchProvinces: useSharedFormHelperStore(state => state.fetchProvinces),
  provinces: useSharedFormHelperStore(state => state.provinces),
});

type Props = {
  address?: Address;
  readonly?: boolean;
};

const SouthAfricaAddressFormField: React.FC<Props> = ({
  address,
  readonly = false,
}: Props) => {
  const { setFieldValue, setFieldTouched, validateField } =
    useFormikContext<Address>();
  const { fetchProvinces, provinces } = useStore();

  useEffect(() => {
    fetchProvinces();
  }, [fetchProvinces]);

  const onBlurField = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name } = e.target;

    // We've hooked in to the onBlur event here, which means that we've actually overridden the native one in the
    // Field component.  We therefore need to mimic the original onBlur business logic which marks the field as touched
    // and kicks off validation.
    setFieldTouched(name, true);
    validateField(name);
  };

  const updateAddressValues = useCallback(
    (adrs?: Address) => {
      updateCommonAddressValues(setFieldValue, adrs, setFieldTouched);
      updateZAAddressValues(setFieldValue, adrs, setFieldTouched);
    },
    [setFieldTouched, setFieldValue]
  );

  useEffect(() => {
    if (!address) {
      return;
    }

    updateAddressValues(address);
  }, [address, setFieldValue, setFieldTouched, updateAddressValues]);

  const handleChange = (data: Address) => {
    updateAddressValues(data);
  };

  return (
    <div>
      <CommonAddressFormField
        addressLine1Placeholder="Address line 1"
        addressLine2PlaceHolder="Address Line 2 (Optional)"
        onChange={handleChange}
        readonly={readonly}
      />
      <div className="grid mt-12-v2 sm:mt-16-v2 gap-12-v2 sm:gap-16-v2">
        <TextInput
          id="suburb"
          name="suburb"
          type="text"
          label="Suburb"
          placeholder="Suburb"
          disabled={readonly}
          onBlur={onBlurField}
        />
        <TextInput
          id="city"
          name="city"
          type="text"
          placeholder="City"
          label="City"
          disabled={readonly}
          onBlur={onBlurField}
        />
        <TextInput
          id="postal_code"
          name="postal_code"
          type="text"
          placeholder="Postal code"
          label="Postal code"
          disabled={readonly}
          onBlur={onBlurField}
        />
        <DropdownInput
          id="province"
          name="province"
          label="Province"
          disabled={readonly}
          options={provinces}
          onBlur={onBlurField}
        />
      </div>
    </div>
  );
};

export default SouthAfricaAddressFormField;
