// ToDo V2 Design
import React from "react";
import mastercardImage from "./images/mastercard.svg";
import visaImage from "./images/visa.svg";

const CardIcons = () => {
  return (
    <div className="flex flex-row space-x-1">
      <div>
        <img src={visaImage} alt="visa-card" />
      </div>

      <div>
        <img src={mastercardImage} alt="master-card" />
      </div>
    </div>
  );
};

export default CardIcons;
