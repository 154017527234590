import { BusinessType } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React from "react";
import * as yup from "yup";
import { ObjectShape } from "yup/lib/object";
import CONFIG from "@/config";
import { PaymentMethod } from "@/shared/shop/api";
import { useCartStore } from "@/shop/store";
import { PaymentMethodProps } from "@/shop/types";
import { useSignupStore } from "@/signup/store";
import CreditCardForm from "../CreditCardForm";
import InstalmentPlan from "./InstalmentPlan";
import TermsAgreementForm from "./TermsAgreementForm";

const isValidRegistrationNumber = value => /\d{4}\/\d{6}\/\d{2}/.test(value);

const useStore = () => ({
  signup: useSignupStore(state => state.signup),
  cart: useCartStore(state => state.cart),
  updateSignup: useSignupStore(state => state.updateSignup),
  processBlackbirdCreditCardPaymentAndActivateInstalmentPlan: useCartStore(
    state => state.processBlackbirdCreditCardPaymentAndActivateInstalmentPlan
  ),
});

const TermsPaymentMethod = ({ onSuccess }: PaymentMethodProps) => {
  const {
    signup,
    cart,
    updateSignup,
    processBlackbirdCreditCardPaymentAndActivateInstalmentPlan,
  } = useStore();
  const instalmentPlanProposal = cart?.instalment_plan_proposal;
  const deposit = instalmentPlanProposal?.instalments[0];

  const isBusinessRegistrationNumberRequired =
    signup?.business_type === BusinessType.COMPANY;

  const onSubmit = async (values: FormikValues) => {
    if (isBusinessRegistrationNumberRequired) {
      return updateSignup(values);
    }

    return true;
  };

  const initialValues = {
    agree_with_terms_and_conditions: false,
    business_registration_number: signup?.business_registration_number || "",
  };

  const validationSchema: ObjectShape = {
    agree_with_terms_and_conditions: yup
      .boolean()
      .oneOf([true], "You must accept the purchase agreement"),
  };

  if (isBusinessRegistrationNumberRequired) {
    validationSchema.business_registration_number = yup
      .string()
      .required()
      .test(
        "isValidRegistrationNumber",
        "You must enter a valid company registration number",
        isValidRegistrationNumber
      )
      .label("company registration number");
  }

  const onTransactionComplete = async result => {
    if (result.status === "succeeded") {
      const isSuccessful =
        await processBlackbirdCreditCardPaymentAndActivateInstalmentPlan(
          result.id
        );

      if (isSuccessful) {
        if (onSuccess) {
          onSuccess(PaymentMethod.PAYMENT_TERMS);
        }
      }
    }
  };

  return (
    <CreditCardForm
      amount={deposit?.amount}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onTransactionComplete={onTransactionComplete}
      apiPublicKey={CONFIG.paymentTermsBlackbirdPublicKey}
      paymentMethod={PaymentMethod.PAYMENT_TERMS}
      tokenize
    >
      <InstalmentPlan />
      <hr className="mb-20-v2" />
      <TermsAgreementForm />
      <hr className="mb-20-v2" />
    </CreditCardForm>
  );
};

export default TermsPaymentMethod;
