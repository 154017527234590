type Props = {
  className?: string;
  width?: React.SVGAttributes<SVGRectElement>["width"];
  height?: React.SVGAttributes<SVGRectElement>["height"];
  strokeWidth?: React.SVGAttributes<SVGRectElement>["height"];
};

const PlusIcon = ({
  className,
  width = "18",
  height = "18",
  strokeWidth = "2",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Group 632037">
        <rect
          id="Rectangle 1697"
          x="10"
          width="18"
          height={strokeWidth}
          transform="rotate(90 10 0)"
        />
        <rect id="Rectangle 1698" y="8" width="18" height={strokeWidth} />
      </g>
    </svg>
  );
};

export default PlusIcon;
