// TODO: Double check if this component is still relevant
import ErrorMessage from "@/shared/components/V1/form/ErrorMessage";
import { connect, FieldInputProps, FormikProps, FormikValues } from "formik";
import React from "react";

type CheckFieldValue = string | number | readonly string[] | undefined;

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  form: FormikProps<FormikValues>;
  field: FieldInputProps<CheckFieldValue>;
  label?: string;
  className?: string;
};

const Checkbox: React.FC<Props> = ({
  form: { touched, errors },
  field,
  label,
  className,
  ...props
}: Props) => {
  return (
    <div className="mb-1 w-100">
      <label className="-label w-100 block py-1">
        <input
          type="checkbox"
          className={`w-5 h-5 pl-2 align-middle -mt-1 focus:ring-0 focus:ring-offset-0 focus:outline rounded-sm text-yoco-green ${className}`}
          {...field}
          {...props}
        />
        {label && <span className="ml-3">{label}</span>}
      </label>
      {errors[field.name] && touched[field.name] && (
        <ErrorMessage>{errors[field.name] as string}</ErrorMessage>
      )}
    </div>
  );
};

export default connect(Checkbox);
