import { Address } from "@/shared/types";
import CONFIG from "@/config";
import { CountryCode } from "@/libs/sawubona-sdk/types";
import { connect } from "formik";
import React from "react";
import { ObjectShape } from "yup/lib/object";
import EgyptAddressFormField from "./EgyptAddressFormField";
import SouthAfricaAddressFormField from "./SouthAfricaAddressFormField";
import EgyptAddressSchema from "./ValidationSchema/EgyptAddressSchema";
import SouthAfricaAddressSchema from "./ValidationSchema/SouthAfricaAddressSchema";

type Props = {
  address?: Address;
  readonly?: boolean;
};

const AddressFormFields: React.FC<Props> = ({
  address,
  readonly = false,
}: Props) => {
  return CONFIG.countryCode === CountryCode.Egypt ? (
    <EgyptAddressFormField />
  ) : (
    <SouthAfricaAddressFormField address={address} readonly={readonly} />
  );
};

export default connect(AddressFormFields);

// We have different schema for trading and deliver address. Depending on which country, a schema will be selected
export const getValidationSchema = (): ObjectShape => {
  if (CONFIG.countryCode === CountryCode.Egypt) {
    return EgyptAddressSchema.schema();
  }

  return SouthAfricaAddressSchema.schema();
};
