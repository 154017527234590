import { sawubona } from "@yoco/sawubona-sdk";
import { debounce } from "lodash";
import * as yup from "yup";
import { TestContext } from "yup";

const DEBOUNCE_THRESHOLD = 300; // milliseconds

const isValidEmail = (value: string): boolean => {
  const schema = yup.string().email();
  return schema.isValidSync(value);
};

const isValidBusinessName = (businessName: string): boolean => {
  // Whilst we want to be lenient with what the merchant captures as their business name, the payment processors
  // and 3rd parties have different requirements.
  // eg: If the user enters "Pick 'n Pay (Pty) Ltd.", we actually send "Pick n Pay Pty Ltd" to the payment processor.
  // The requirements below have been put in place by the Merchant Account team.
  const normalisedBusinessName = businessName
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .trim();

  // The normalised business name must be > 3 chars, whilst the original business name can't be more than 150 chars.
  return normalisedBusinessName.length >= 3 && businessName.length < 150;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const validateEmailAvailable = (args: string | yup.TestOptions) => {
  const validator = debounce(
    async (
      context: TestContext,
      value: string | undefined,
      resolve: (value: boolean | yup.ValidationError) => void
    ) => {
      if (value) {
        if (isValidEmail(value)) {
          try {
            const result = await sawubona.validation.validateSignupEmail(value);

            if (result.is_valid) {
              resolve(true);
            } else {
              resolve(
                context.createError({
                  message: result.error,
                })
              );
            }
          } catch (error) {
            // If we fail to hit our backend, or our backend fails to validate, we'll fail silently
            resolve(true);
          }
        } else {
          resolve(
            context.createError({
              // eslint-disable-next-line no-template-curly-in-string
              message: "The ${path} is not a valid email address.",
            })
          );
        }
      } else {
        resolve(
          context.createError({
            // eslint-disable-next-line no-template-curly-in-string
            message: "The ${path} field is required.",
          })
        );
      }
    },
    DEBOUNCE_THRESHOLD
  );

  return (yup as any)
    .string()
    .test(
      "is-email-available",
      args,
      function test(this: TestContext, value: string | undefined) {
        return new Promise(resolve => validator(this, value, resolve));
      }
    );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const validatePassword = (args: string | yup.TestOptions) => {
  const validator = debounce(
    async (
      context: TestContext,
      value: string | undefined,
      resolve: (value: boolean | yup.ValidationError) => void
    ) => {
      if (value) {
        try {
          const result = await sawubona.validation.validateSignupPassword(
            value
          );
          if (result.is_valid) {
            resolve(true);
          } else {
            resolve(
              context.createError({
                message: result.error,
              })
            );
          }
        } catch (error) {
          // If we fail to hit our backend, or our backend fails to validate with core, we'll fail silently
          // and allow the customer to continue ahead with the signup process.
          resolve(true);
        }
      } else {
        resolve(
          context.createError({
            // eslint-disable-next-line no-template-curly-in-string
            message: "The ${path} field is required.",
          })
        );
      }
    },
    DEBOUNCE_THRESHOLD
  );

  return (yup as any)
    .string()
    .test(
      "is-password-valid",
      args,
      function test(this: TestContext, value: string | undefined) {
        return new Promise(resolve => validator(this, value, resolve));
      }
    );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const validateSerialNumberAvailable = (args: string | yup.TestOptions) => {
  const validator = debounce(
    async (
      context: TestContext,
      value: string | undefined,
      resolve: (value: boolean | yup.ValidationError) => void
    ) => {
      if (value) {
        try {
          const result = await sawubona.validation.validateSerialNumber(value);

          if (result.is_valid) {
            resolve(true);
          } else {
            resolve(
              context.createError({
                message: result.error,
              })
            );
          }
        } catch (error) {
          resolve(
            context.createError({
              message:
                "We are experiencing a temporary issue verifying your serial number.",
            })
          );
        }
      } else {
        resolve(
          context.createError({
            message: "The serial number field is required.",
          })
        );
      }
    },
    DEBOUNCE_THRESHOLD
  );

  return (yup as any)
    .string()
    .test(
      "is-serial-number-available",
      args,
      function test(this: TestContext, value: string | undefined) {
        return new Promise(resolve => validator(this, value, resolve));
      }
    );
};

const validateOrderNumber = (args: string | yup.TestOptions) => {
  const validator = debounce(
    async (
      context: TestContext,
      value: string | undefined,
      resolve: (value: boolean | yup.ValidationError) => void
    ) => {
      if (value) {
        try {
          const result = await sawubona.validation.validateOrderNumber(value);
          if (result.is_valid) {
            resolve(true);
          } else {
            resolve(
              context.createError({
                message: result.error,
              })
            );
          }
        } catch (error) {
          resolve(true);
        }
      } else {
        resolve(
          context.createError({
            message: "The order number field is required",
          })
        );
      }
    },
    DEBOUNCE_THRESHOLD
  );

  return (yup as any)
    .string()
    .test(
      "is-order-number-valid",
      args,
      function test(this: TestContext, value: string | undefined) {
        return new Promise(resolve => validator(this, value, resolve));
      }
    );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const validateBusinessName = (args: string | yup.TestOptions) => {
  const validator = debounce(
    async (
      context: TestContext,
      value: string | undefined,
      resolve: (value: boolean | yup.ValidationError) => void
    ) => {
      if (value) {
        if (isValidBusinessName(value)) {
          try {
            const result = await sawubona.validation.validateBusinessName(
              value
            );

            if (result.is_valid) {
              resolve(true);
            } else {
              resolve(
                context.createError({
                  message: result.error,
                })
              );
            }
          } catch (error) {
            // If we fail to hit our backend, or our backend fails to validate, we'll fail silently
            resolve(true);
          }
        } else {
          resolve(
            context.createError({
              message: "The business name is invalid.",
            })
          );
        }
      } else {
        resolve(
          context.createError({
            message: "The business name field is required",
          })
        );
      }
    },
    DEBOUNCE_THRESHOLD
  );

  return (yup as any)
    .string()
    .test(
      "is-business-name-valid",
      args,
      function test(this: TestContext, value: string | undefined) {
        return new Promise(resolve => validator(this, value, resolve));
      }
    );
};

const validateSouthAfricanMobile = (
  number: string
): { is_valid: boolean; message: string } => {
  const cleaned = number.replace(/[\s-]/g, "");
  const digitsOnly = cleaned.replace(/^\+27|^0/, "");

  if (!/^\+?[\d]+$/.test(cleaned)) {
    return {
      is_valid: false,
      // eslint-disable-next-line no-template-curly-in-string
      message: "The ${path} should only contain digits.",
    };
  }
  // Invalid if number not in local SA or international format
  if (!cleaned.startsWith("+27") && !cleaned.startsWith("0")) {
    return {
      is_valid: false,
      message:
        // eslint-disable-next-line no-template-curly-in-string
        "The ${path} must start with +27 or 0.",
    };
  }

  // Validate local landline (01 - 05) and mobile (06 - 08)
  // Validate international landline (+271 - +275) and mobile (+276 - +278)
  // Landline numbers start with 01-05 (e.g., 011, 021, 031).
  // Mobile numbers start with 06, 07, 08 (e.g., 082, 076, 061)
  if (!/^[1-8][0-9]/.test(digitsOnly)) {
    return {
      is_valid: false,
      message:
        // eslint-disable-next-line no-template-curly-in-string
        "The ${path} must start with a valid South African mobile or landline prefix after +27 or 0.",
    };
  }

  if (digitsOnly.length !== 9) {
    return {
      is_valid: false,
      // eslint-disable-next-line no-template-curly-in-string
      message: "The ${path} must have 9 digits after +27 or 0.",
    };
  }

  return { is_valid: true, message: "Valid number!" };
};

const phoneNumberAvailable = (args: string | yup.TestOptions) => {
  return (yup as any)
    .string()
    .test(
      "is-phone-number-available",
      args,
      function test(this: TestContext, value: string | undefined) {
        if (!value) {
          return this.createError({
            // eslint-disable-next-line no-template-curly-in-string
            message: "The ${path} field is required.",
          });
        }

        const valid = validateSouthAfricanMobile(value);

        if (!valid.is_valid) {
          return this.createError({ message: valid.message });
        }

        return true;
      }
    );
};

export default {
  isValidEmail,
  isValidBusinessName,
  validateEmailAvailable,
  validatePassword,
  validateSerialNumberAvailable,
  validateOrderNumber,
  validateBusinessName,
  phoneNumberAvailable,
};
