/* eslint-disable jsx-a11y/anchor-is-valid */
// ToDo decide if we're happy to have onClick on anchors (like in `yoco-com`) and disable this globally
import classNames from "classnames";
import React, { ReactNode, useCallback, useMemo } from "react";

import Segment from "@/shared/services/Segment";
import { Color } from "../../../../constants/color";

import Typography, { TypographySize } from "../Typography";

import { getTextColorClass } from "../../../../utils/color";
import Link from "../Link";

type TextButtonProps = {
  text: string;
  mobileText?: string;
  textSize?: TypographySize;
  style: "icon" | "underlined" | "text";
  weight?: "book" | "medium";
  color: Color;
  linkUrl?: string;
  mobileLinkUrl?: string;
  icon?: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  trackingEvent?: string;
  trackingEventKey?: string;
  trackingEventValue?: string;
  className?: string;
  uppercase?: boolean;
  opensNewTab?: boolean;
};

const TextButton: React.FC<TextButtonProps> = ({
  text,
  icon,
  color,
  style,
  weight = "medium",
  onClick,
  linkUrl,
  mobileText,
  mobileLinkUrl,
  textSize = style === "text" ? "subscript-lg" : "body-lg",
  trackingEvent,
  trackingEventKey,
  disabled = false,
  trackingEventValue,
  uppercase = style === "text",
  className,
  opensNewTab,
}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>, link?: string) => {
      if (trackingEvent) {
        const event =
          trackingEventKey && trackingEventValue
            ? { [trackingEventKey]: trackingEventValue }
            : undefined;
        Segment.track(trackingEvent, event);
      }

      if (onClick) {
        onClick();
      } else if (link && link.startsWith("#")) {
        e.preventDefault();
        const element = document.querySelector(link);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    [trackingEvent, onClick, trackingEventKey, trackingEventValue]
  );

  const buttonClasses = useMemo(
    () =>
      classNames(
        "group inline-flex pb-4-v2 pt-[3px] max-w-fit",
        getTextColorClass(color),
        { "opacity-50 pointer-events-none": disabled },
        { "items-center gap-8-v2": style === "icon" },
        {
          "gap-[2px] flex-col after:content-[''] after:block after:bg-current after:h-px after:transition-[width] after:duration-300":
            style === "underlined" || style === "text",
        },
        {
          "after:w-full hover:after:w-[80%] focus-visible:after:w-[80%] items-center":
            style === "underlined",
        },
        {
          "after:w-0 hover:after:w-full focus-visible:after:w-full items-start":
            style === "text",
        },
        { uppercase },
        className
      ),
    [color, disabled, style, uppercase, className]
  );

  const buttonContent = (
    <>
      {mobileText ? (
        <>
          <Typography
            text={mobileText}
            weight={weight}
            font="grotesk"
            color={color}
            size={textSize}
            leadingNone
            className="block md-v2:hidden"
          />
          <Typography
            text={text}
            weight={weight}
            font="grotesk"
            color={color}
            size={textSize}
            leadingNone
            className="hidden md-v2:block"
          />
        </>
      ) : (
        <Typography
          text={text}
          weight={weight}
          font="grotesk"
          color={color}
          size={textSize}
          leadingNone
        />
      )}
      {style === "icon" && icon && (
        <div
          className={classNames(
            getTextColorClass(color),
            "w-20-v2 md-v2:w-[22px] lg-v2:w-24-v2"
          )}
        >
          {icon}
        </div>
      )}
    </>
  );

  if (linkUrl && mobileLinkUrl) {
    return (
      <>
        <Link
          link={linkUrl}
          onClick={e => handleClick(e, linkUrl)}
          className={classNames("hidden md-v2:inline-flex", buttonClasses)}
        >
          {buttonContent}
        </Link>
        <Link
          link={mobileLinkUrl}
          onClick={e => handleClick(e, mobileLinkUrl)}
          className={classNames("md-v2:hidden", buttonClasses)}
        >
          {buttonContent}
        </Link>
      </>
    );
  }
  if (linkUrl && !mobileLinkUrl) {
    return (
      <Link
        link={linkUrl}
        onClick={e => handleClick(e, linkUrl)}
        className={buttonClasses}
        opensNewTab={opensNewTab}
      >
        {buttonContent}
      </Link>
    );
  }

  return (
    <button
      type="button"
      onClick={e => handleClick(e)}
      className={buttonClasses}
      disabled={disabled}
    >
      {buttonContent}
    </button>
  );
};

export default TextButton;
