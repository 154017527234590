import React, { useEffect, useState } from "react";
import { useAppConfigStore, useSignupStore } from "@/signup/store";
import { useWaitUntilMerchantAccountIsCreated } from "@/utils/hooks";
// V2 Imports
import { LoaderIcon } from "../../../shared/components/elements/Loader";
import PillButton from "../../../shared/components/elements/Buttons/PillButton";

import { Color } from "../../../constants/color";

const useStore = () => ({
  yocoAuthToken: useSignupStore(state => state.yocoAuthToken),
  isInHub: useAppConfigStore(state => state.isInHub),
});

const ContinueToAppButton = () => {
  const { yocoAuthToken, isInHub } = useStore();
  const { isMerchantAccountCreated } = useWaitUntilMerchantAccountIsCreated();
  const [isCompleteSetupSelected, setCompleteSetupSelected] =
    useState<boolean>(false);

  const onClickContinueToAppButton = () => {
    setCompleteSetupSelected(true);
  };

  useEffect(() => {
    if (!isMerchantAccountCreated || !isCompleteSetupSelected) {
      return;
    }

    const baseRedirectURL = isInHub
      ? "yocohub://login/landing"
      : "yocopos://link/autoLogin";
    const authPart =
      yocoAuthToken && isMerchantAccountCreated ? `/?yat=${yocoAuthToken}` : "";
    window.location.href = `${baseRedirectURL}${authPart}`;
  }, [isCompleteSetupSelected, yocoAuthToken, isMerchantAccountCreated]);

  return (
    <PillButton
      size="large"
      style="solid"
      color={Color.Blue}
      text={!isCompleteSetupSelected ? "Complete setup now" : ""}
      className="sm:w-full! mx-auto"
      hideIcon={!isCompleteSetupSelected}
      disabled={isCompleteSetupSelected}
      onClick={onClickContinueToAppButton}
      icon={
        isCompleteSetupSelected ? (
          <LoaderIcon width={32} className="text-white-v2" />
        ) : undefined
      }
    />
  );
};

export default ContinueToAppButton;
