import React from "react";
import ContinueToAppButton from "./ContinueToAppButton";
import portalIcon from "./images/portal-icon.svg";
// V2 Imports
import Layout from "../../../shared/components/Layout";
import FormHeader from "../../../shared/components/checkout/FormHeader";
import Typography from "../../../shared/components/elements/Typography";

import { Color } from "../../../constants/color";

const InAppContent = () => {
  const next_steps_info: string[] = [
    "Complete your setup!",
    "Start accepting payments and grow your business",
  ];

  return (
    <Layout isCartEditable={false}>
      <FormHeader title="Get ready for business!" />
      <div className="flex flex-col gap-32-v2">
        <img
          src={portalIcon}
          alt="Yoco POS on phone and tablet"
          className="mx-auto"
        />
        <div>
          <Typography
            element="p"
            size="body-md"
            font="grotesk"
            weight="medium"
            text="Next Steps"
            color={Color.Black}
          />
          <ol className="list-decimal list-outside ml-4">
            {next_steps_info.map((step, index) => (
              <li key={`${index + 1}`}>
                <Typography
                  text={step}
                  element="p"
                  size="body-sm"
                  font="grotesk"
                  weight="book"
                  color={Color.Black}
                />
              </li>
            ))}
          </ol>
        </div>
        <ContinueToAppButton />
      </div>
    </Layout>
  );
};

export default InAppContent;
