/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFeatureFlags } from "@yoco/feature-flags";
import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import paymentLogoXneelo from "@/assets/images/xneelo-payment-logo.png";
import CONFIG from "@/config";
import Segment from "@/shared/services/Segment";
import { getString } from "@/shared/utils/appConfig";
import { makeTestID } from "@/shared/utils/development";
import { useCartStore } from "@/shop/store";
import Validator from "@/signup/services/Validator";
import {
  useAppConfigStore,
  useSignupStore,
  useStepStore,
} from "@/signup/store";
import { UserJourney } from "@/signup/types";
import Formik from "@/shared/components/form/Formik";
import Form from "@/shared/components/form/Form";
import PasswordStrengthBar from "../../components/PasswordStrengthBar";
import LeadFormHeader from "./LeadFormHeader";
import TextInput from "../../../shared/components/elements/Inputs/TextInput";
import PhoneNumberInput from "../../../shared/components/elements/Inputs/PhoneNumberInput";
import CheckBox from "../../../shared/components/elements/Inputs/Checkbox";
import Link from "../../../shared/components/elements/Link";
import Typography from "../../../shared/components/elements/Typography";
import { Color } from "../../../constants/color";
import Field from "../../../shared/components/elements/Inputs/Field";
import SubmitButton from "../../../shared/components/checkout/SubmitButton";

const useStore = () => ({
  createSignup: useSignupStore(state => state.createSignup),
  getSignup: useSignupStore(state => state.getSignup),
  resetSignup: useSignupStore(state => state.reset),
  resetCart: useCartStore(state => state.reset),
  resetSignupSteps: useStepStore(state => state.reset),
  addToCart: useCartStore(state => state.add),
  updateCart: useCartStore(state => state.updateCart),
  fetchCart: useCartStore(state => state.fetchCart),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
  userJourney: useAppConfigStore(state => state.userJourney),
  services: useAppConfigStore(state => state.services),
  autoAddProductID: useAppConfigStore(state => state.autoAddProductID),
  referralCode: useAppConfigStore(state => state.referralCode),
  resellerCode: useAppConfigStore(state => state.resellerCode),
  salesSmartProposalId: useAppConfigStore(state => state.salesSmartProposalId),
  promoCode: useAppConfigStore(state => state.promoCode),
  readerSerialNumber: useAppConfigStore(state => state.readerSerialNumber),
  preQualification: useAppConfigStore(state => state.preQualification),
  isInApp: useAppConfigStore(state => state.isInApp),
  isInHub: useAppConfigStore(state => state.isInHub),
});

const LeadForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const features = useFeatureFlags();
  const {
    createSignup,
    getSignup,
    resetSignup,
    resetSignupSteps,
    resetCart,
    addToCart,
    updateCart,
    fetchCart,
    getNextStepLink,
    userJourney,
    services,
    autoAddProductID,
    referralCode,
    resellerCode,
    salesSmartProposalId,
    promoCode,
    readerSerialNumber,
    preQualification,
    isInApp,
    isInHub,
  } = useStore();

  useEffect(() => {
    resetSignup();
    resetCart();
    resetSignupSteps();
    Segment.track("web_signup_start");
    const cartID = getString("cartUUID");
    if (cartID) {
      fetchCart(cartID);
    }
  }, [location, resetSignup, resetCart, resetSignupSteps, fetchCart]);

  const emailFromQueryString = getString("email")
    ? decodeURIComponent(getString("email") as string)
    : "";

  const mobileNumberFromQueryString = getString("mobileNumber")
    ? decodeURIComponent(getString("mobileNumber") as string)
    : "";

  const initialValues = {
    stage: SignupStep.HomePage,
    email: emailFromQueryString,
    password: "",
    mobile_number: mobileNumberFromQueryString,
    services,
    referral_code: referralCode || "",
    reseller_code: resellerCode || "",
    sales_smart_proposal_id: salesSmartProposalId || "",
    serial_number: readerSerialNumber || "",
    user_journey: userJourney || CONFIG.defaultUserJourney,
    marketing_opt: false,
    pre_qualification: preQualification,
  };

  yup.addMethod(yup.string, "emailAvailable", Validator.validateEmailAvailable);
  yup.addMethod(yup.string, "password", Validator.validatePassword);
  yup.addMethod(
    yup.string,
    "phoneNumberAvailable",
    Validator.phoneNumberAvailable
  );

  const schema = yup.object({
    email: (yup as any)
      .string()
      .email()
      .emailAvailable()
      .required()
      .label("email"),
    password: (yup as any)
      .string()
      .password()
      .required()
      .min(8)
      .label("password"),
    mobile_number: (yup as any)
      .string()
      .phoneNumberAvailable()
      .required()
      .label("mobile number"),
  });

  const onSubmit = async (values: FormikValues) => {
    const featureFlags = features.getEnabledFeatureNames();
    // eslint-disable-next-line no-param-reassign
    values.feature_flags = featureFlags;

    // eslint-disable-next-line no-param-reassign
    values.cart_uuid = getString("cartUUID");

    const isSignupCreated = await createSignup(values);

    if (isSignupCreated) {
      const signup = getSignup();
      Segment.identify({
        email: String(signup.email),
        signupUUID: String(signup.id),
        businessUUID: String(signup.business_uuid),
        userUUID: String(signup.user_uuid),
      });
      Segment.alias(String(signup.user_uuid));

      Segment.trackWithSignup("web_signup_submit_form");
      Segment.trackWithSignup("Lead Created", "milestones");

      const isCartEditable = !signup.cart.is_order;

      if (autoAddProductID && isCartEditable) {
        await addToCart(autoAddProductID);
      }

      if (featureFlags && isCartEditable) {
        await updateCart({
          feature_flags: featureFlags,
        });
      }

      // We update the promo code separately because if the promo code fails validation we don't
      // want to block other cart updates in the initial phase.
      if (promoCode && isCartEditable) {
        await updateCart({
          promo_code_str: promoCode,
        });
      }

      navigate(getNextStepLink());
    }
  };

  const isUserJourneyXneelo = userJourney === UserJourney.XNEELO;
  const hasReferralCode = !!referralCode;

  return (
    <div>
      <LeadFormHeader
        userJourney={userJourney}
        hasReferralCode={hasReferralCode}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {formik => (
          <Form className="flex flex-col gap-16-v2">
            <Field
              component={TextInput}
              name="email"
              id="email"
              type="email"
              label="Email address"
              placeholder="Email"
              data-testid={makeTestID("homePage", "email")}
            />
            <Field
              component={PhoneNumberInput}
              name="mobile_number"
              id="mobile_number"
              label="Mobile Number"
              placeholder="Mobile number"
              data-testid={makeTestID("homePage", "mobile")}
            />

            <div className="flex flex-col gap-2">
              <Field
                component={TextInput}
                type="password"
                name="password"
                id="password"
                label="Create a password"
                placeholder="Create a Password"
                data-testid={makeTestID("homePage", "password")}
              />
              <Typography
                text="The password must be a minimum of 8 characters."
                className="mt-1"
                size="body-sm"
                color={Color.Black}
                font="grotesk"
                weight="book"
              />

              <PasswordStrengthBar password={formik.values.password} />
            </div>
            <CheckBox
              id="marketing_opt"
              label="Be the first to know about our offers and upcoming releases."
            />
            <div className="flex flex-wrap">
              <CheckBox
                label="I agree to Yoco's"
                id="acceptTerms"
                data-testid="terms"
                className="order-1"
                inputClassName="order-3 w-full"
                required
              />
              <Link
                link="https://www.yoco.com/za/legal/"
                className="order-2 ml-4-v2 self-end"
              >
                <Typography
                  font="grotesk"
                  weight="medium"
                  size="body-md"
                  color={Color.Black}
                  text="terms"
                  className="underline underline-offset-2 mb-[1px] lg:-mb-[1px]"
                />
              </Link>
            </div>

            <SubmitButton
              testID={makeTestID("homePage", "continue")}
              text={isUserJourneyXneelo ? "Sign up for 3 months free" : "Next"}
            />

            {!isInApp && !isInHub && (
              <div className="mx-auto flex gap-2">
                <Typography
                  size="body-sm"
                  text="Already using Yoco?"
                  color={Color.Black}
                  weight="book"
                  font="grotesk"
                />
                <Link link="https://app.yoco.com">
                  <Typography
                    size="body-sm"
                    color={Color.Blue}
                    font="grotesk"
                    text="Login"
                    weight="book"
                  />
                </Link>
              </div>
            )}
          </Form>
        )}
      </Formik>

      {/* ToDo determine if we still need these icons for Xneelo since we dont use them for default */}
      <div className="flex sm:flex-row-reverse">
        {isUserJourneyXneelo ? (
          <div className="sm:w-16 mt-6 w-24 mx-auto">
            <img src={paymentLogoXneelo} alt="Payment logos" />
          </div>
        ) : null}
      </div>

      {isUserJourneyXneelo && (
        <div className="mt-8-v2">
          <Typography
            size="body-sm"
            color={Color.Black}
            font="grotesk"
            weight="book"
            className="inline"
            text="All account and technical queries around Yoco payment services can be directed to the Yoco support team. You can reach them"
          />
          <Link link="https://www.yoco.com/za/contact/">
            <Typography
              font="grotesk"
              weight="medium"
              size="body-md"
              color={Color.Black}
              text="here"
              className="underline underline-offset-2 inline ml-4-v2"
            />
          </Link>
        </div>
      )}
    </div>
  );
};

export default LeadForm;
