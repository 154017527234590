import { useCartStore } from "@/shop/store";
import { useAppConfigStore, useSignupStore } from "@/signup/store";
import { Signup } from "@yoco/sawubona-sdk";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Page from "@/signup/components/Page";
import {
  BusinessPreQualificationPage,
  HomePage,
  ProductCataloguePage,
  ServiceSelectionPage,
  PaymentPage,
  ContinuePage,
  DeliveryPage,
  BusinessNamePage,
  TradingAddressPage,
  BusinessTypePage,
  BusinessOwnerPage,
  BusinessCategoryPage,
  DeveloperDetailsPage,
  RegisterReaderPage,
  BusinessUrlPage,
  CompletePage,
  NotFoundPage,
} from "./pages";

const useStore = () => ({
  setCart: useCartStore(state => state.setCart),
});

const App = () => {
  const { setCart } = useStore();

  window.yc = window.yc || {};
  window.yc.config = window.yc.config || {};
  window.yc.appConfigStore = useAppConfigStore;

  useEffect(() => {
    // The signup and cart entities are decoupled on the backend.  A signup can have a cart, but a cart doesn't
    // necessarily have to belong to a signup.  We therefore keep these as separate stores on the frontend.  The
    // current signup journey uses the id of the signup as a pk, and doesn't actually allow us to access or pay for
    // a specific cart.  We therefore have a light coupling; and as such, whenever the state of a signup changes, we'll
    // listen for that state change and set that signup.cart as the current cart state.

    const unsubscribe = useSignupStore.subscribe(
      state => state.signup,
      signup => {
        const cart = (signup as Signup | null | undefined)?.cart || null;

        setCart(cart, "setCartFromSignup");
      }
    );

    return () => {
      unsubscribe();
    };
  }, [setCart]);

  return (
    <Page>
      <Routes>
        {/* ToDo V2 Pages and components for signup app */}
        <Route path="/" element={<HomePage />} />
        <Route path="xneelo" element={<HomePage />} />
        <Route path="partner/:name" element={<HomePage />} />
        <Route path=":id/continue" element={<ContinuePage />} />
        {/* 
        <Route path=":id/business_details" element={<BusinessNamePage />} />
        */}

        <Route
          path=":id/pre_qualification"
          element={<BusinessPreQualificationPage />}
        />

        <Route path=":id/business_name" element={<BusinessNamePage />} />

        <Route path=":id/trading_address" element={<TradingAddressPage />} />
        <Route path=":id/business_type" element={<BusinessTypePage />} />

        <Route path=":id/business_owner" element={<BusinessOwnerPage />} />

        <Route
          path=":id/business_category"
          element={<BusinessCategoryPage />}
        />

        <Route
          path=":id/developer_details"
          element={<DeveloperDetailsPage />}
        />

        <Route path=":id/services" element={<ServiceSelectionPage />} />
        <Route path=":id/business_url" element={<BusinessUrlPage />} />
        <Route path=":id/retail" element={<RegisterReaderPage />} />
        <Route path=":id/products" element={<ProductCataloguePage />} />
        {/* START ToDo these forms still need their styling updated  */}
        <Route path=":id/payment" element={<PaymentPage />} />
        <Route path=":id/delivery" element={<DeliveryPage />} />
        {/* END ToDo */}
        <Route path=":id/complete" element={<CompletePage />} />
        <Route path="*" element={<NotFoundPage type="signup" />} />
      </Routes>
    </Page>
  );
};

export default App;
