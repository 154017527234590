import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Segment from "@/shared/services/Segment";
import { Address } from "@/shared/types";
import { makeTestID } from "@/shared/utils/development";
import { useCartStore } from "@/shop/store";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import { useSignupStore, useStepStore } from "@/signup/store";
import { Signup, SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import CONFIG from "@/config";
import AddressFormFields, {
  getValidationSchema as getTradingAddressValidationSchema,
} from "../../../shared/components/elements/Inputs/Address/AddressFormFields";
import Layout from "../../../shared/components/Layout";
import FormHeader from "../../../shared/components/checkout/FormHeader";
import SubmitButton from "../../../shared/components/checkout/SubmitButton";
import FormItemTitle from "../../../shared/components/checkout/FormItemTitle";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  updateCart: useCartStore(state => state.updateCart),
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

type TradingAddressReturnValues = Omit<
  Address,
  "line1" | "line2" | "cityCode"
> & {
  address_line1: string;
  address_line2: string;
  city_code: string;
};

const getTradingAddressInitialValues = (
  signup?: Signup | null
): TradingAddressReturnValues => {
  return {
    address_line1: signup?.trading_address_line1 || "",
    address_line2: signup?.trading_address_line2 || "",
    suburb: signup?.trading_address_suburb || "",
    city: signup?.trading_address_city || "",
    province: signup?.trading_address_province || "",
    postal_code: signup?.trading_address_postal_code || "",
    country: CONFIG.countryCode,
    latitude: signup?.trading_address_latitude || null,
    longitude: signup?.trading_address_longitude || null,
    // City code is need for Egypt structure and default is 00001:Cairo
    city_code: signup?.trading_address_city_code || "00001",
    area: signup?.trading_address_area || "",
    description: signup?.trading_address_notes || "",
  };
};

const getInitialTradingAddress = (signup?: Signup | null): Address => {
  const initialTradingAddressFormValues =
    getTradingAddressInitialValues(signup);
  return {
    line1: initialTradingAddressFormValues.address_line1,
    line2: initialTradingAddressFormValues.address_line2,
    suburb: initialTradingAddressFormValues.suburb,
    city: initialTradingAddressFormValues.city,
    province: initialTradingAddressFormValues.province,
    postal_code: initialTradingAddressFormValues.postal_code,
    country: initialTradingAddressFormValues.country,
    latitude: initialTradingAddressFormValues.latitude,
    longitude: initialTradingAddressFormValues.longitude,
    city_code: initialTradingAddressFormValues.city_code,
    area: initialTradingAddressFormValues.area,
    description: initialTradingAddressFormValues.description,
  } as Address;
};

const TradingAddressPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { signup, updateSignup, getNextStepLink } = useStore();

  const [address] = useState(getInitialTradingAddress(signup));

  useEffect(() => {
    Segment.track("web_signup_trading_address_start");
  }, [location]);

  const initialValues = {
    ...getTradingAddressInitialValues(signup),
  };

  const schema = yup.object({
    ...getTradingAddressValidationSchema(),
  });

  const onSubmit = async (values: FormikValues) => {
    const newValues = { ...values };

    newValues.stage = SignupStep.TradingAddressPage;

    // Set the specific trading address field values from the generic form address fields
    newValues.trading_address_line1 = newValues.address_line1;
    newValues.trading_address_line2 = newValues.address_line2;
    newValues.trading_address_suburb = newValues.suburb;
    newValues.trading_address_city = newValues.city;
    newValues.trading_address_province = newValues.province;
    newValues.trading_address_postal_code = newValues.postal_code;
    newValues.trading_address_country = newValues.country;
    newValues.trading_address_latitude = newValues.latitude;
    newValues.trading_address_longitude = newValues.longitude;
    newValues.trading_address_city_code = newValues.city_code;
    newValues.trading_address_area = newValues.area;
    newValues.trading_address_notes = newValues.description;

    const isSignupUpdated = await updateSignup(newValues);

    if (isSignupUpdated) {
      Segment.trackWithSignup("web_signup_trading_address_next_button_clicked");
      navigate(getNextStepLink());
    }
  };

  return (
    <Layout>
      <FormHeader
        title="Where do you trade from?"
        description={[
          `This is where you operate from, and where your customers and mail can find you - it may also be your home address.`,
          `*We won't ask you for proof of address.`,
        ]}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <div className="flex flex-col gap-32-v2">
                <div>
                  <FormItemTitle title="Business trading address" />
                  <AddressFormFields address={address} />
                </div>
                <SubmitButton testID={makeTestID("tradingAddress", "next")} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default withFetchSignup(
  TradingAddressPage,
  SignupStep.TradingAddressPage
);
