import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

class HttpClient {
  public axios: AxiosInstance;

  constructor(apiURL: string) {
    this.axios = axios.create({
      baseURL: apiURL,
      responseType: "json",
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  async get<T>(path: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.axios.get(path, config);
    return response.data;
  }

  async post<T, D = unknown>(
    path: string,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const response = await this.axios.post<T>(path, data, config);
    return response.data;
  }

  async put<T, D = unknown>(path: string, data?: D): Promise<T> {
    const response = await this.axios.put(path, data);
    return response.data;
  }

  async patch<T, D = unknown>(path: string, data?: D): Promise<T> {
    const response = await this.axios.patch(path, data);
    return response.data;
  }
}

export default HttpClient;
