import classNames from "classnames";
import { ReactNode } from "react";
import Typography from "../elements/Typography";
import { Color } from "../../../constants/color";

// Header is broken down into elements to be able to support the custom
// composition that we have in LeadFormHeader

export const FormHeaderWrapper = ({
  children,
  className,
}: {
  className?: string;
  children: ReactNode;
}) => (
  <div
    className={classNames(
      "flex flex-col gap-32-v2 sm:gap-12-v2 my-32-v2 sm-v2:my-40-v2",
      className
    )}
  >
    {children}
  </div>
);

export const FormHeaderTitle = ({
  text,
  color = Color.Black,
}: {
  text: string;
  color?: Color;
}) => (
  <Typography
    font="grotesk"
    size="lead-md"
    text={text}
    element="h2"
    color={color}
    weight="medium"
  />
);

export const FormHeaderDescription = ({ text }: { text?: string }) => {
  if (!text) return null;
  return (
    <Typography
      font="grotesk"
      size="body-md"
      text={text}
      element="p"
      color={Color.Black}
      weight="book"
    />
  );
};

const FormHeader = ({
  title,
  titleColor = Color.Black,
  description,
  className,
}: {
  title: string;
  titleColor?: Color;
  /**
   * Pass in an array to render more than one description paragraph
   */
  description?: string | string[];
  /**
   * Pass in className to allow FormHeaderWrapper class override
   */
  className?: string;
}) => {
  return (
    <FormHeaderWrapper className={className}>
      <FormHeaderTitle text={title} color={titleColor} />
      {Array.isArray(description) ? (
        <div className="flex flex-col gap-16-v2">
          {description.map(d => (
            <FormHeaderDescription key={d} text={d} />
          ))}
        </div>
      ) : (
        <FormHeaderDescription text={description} />
      )}
    </FormHeaderWrapper>
  );
};

export default FormHeader;
