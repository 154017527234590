import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppConfigStore, useCartStore } from "@/shop/store";
import Loader from "@/shared/components/elements/Loader";
import DeliveryPage from "@/shop/pages/DeliveryPage";

const useStore = () => ({
  fetchBusiness: useAppConfigStore(state => state.fetchBusiness),
  fetchCart: useCartStore(state => state.fetchCart),
  updateCart: useCartStore(state => state.updateCart),
});

const errorContent = (
  <>
    We are currently experiencing some technical difficulties.
    <br />
    <br />
    If the problem persists, please don&apos;t hesitate to contact our support
    team at <a href="mailto:support@yoco.com">support@yoco.com</a>
  </>
);

const ExistingBusinessDeliveryPage = () => {
  const { businessUUID, id } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const { fetchBusiness, fetchCart, updateCart } = useStore();

  useEffect(() => {
    if (businessUUID && id) {
      const loadAndFetchBusiness = async () => {
        setLoading(true);

        const [isBusinessFetched, isCartFetched] = await Promise.all([
          fetchBusiness(businessUUID),
          fetchCart(id),
        ]);

        if (isBusinessFetched && isCartFetched) {
          const { business } = useAppConfigStore.getState();

          const isCartUpdated = await updateCart({
            business_uuid: businessUUID,
            shipping_email: business?.owner.email,
            shipping_mobile_number: business?.telephone_number,
            shipping_first_name: business?.owner.first_name,
            shipping_last_name: business?.owner.last_name,
          });

          if (isCartUpdated) {
            setLoading(false);
          } else {
            toast.error(errorContent);
          }
        } else {
          toast.error(errorContent);
        }
      };

      loadAndFetchBusiness();
    }
  }, [businessUUID, fetchBusiness, fetchCart, id, updateCart]);

  return loading ? <Loader /> : <DeliveryPage />;
};

export default ExistingBusinessDeliveryPage;
