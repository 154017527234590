import React from "react";
import NumberField from "./NumberField";

type Props = {
  className?: string;
};

const SerialNumberField: React.FC<Props> = ({ className }: Props) => {
  return (
    <NumberField
      title="Register a card machine"
      subtitle="Enter the serial number on your existing card machine"
      className={className}
      name="serial_number"
      id="serialNumber"
    />
  );
};

export default SerialNumberField;
