import Cookies from "js-cookie";
import queryString from "query-string";

import Feature from "./Feature";
import FeatureState from "./FeatureState";
import { StateChangeEventListenerCallback } from "./types";

class FeatureFlagManager {
  private readonly features: Feature[] = [];

  private cookieName = "ft";

  private onStateChangeEventListeners: StateChangeEventListenerCallback[] = [];

  constructor(features: (Feature | string)[] = [], cookieName = "ft") {
    this.features = features.map(feature =>
      feature instanceof Feature ? feature : new Feature(feature)
    );
    this.cookieName = cookieName;
  }

  setCookieName(cookieName: string): FeatureFlagManager {
    this.cookieName = cookieName;

    return this;
  }

  subscribeToStateChange(
    listener: StateChangeEventListenerCallback
  ): FeatureFlagManager {
    this.onStateChangeEventListeners.push(listener);

    return this;
  }

  unsubscribeFromStateChange(
    listener: StateChangeEventListenerCallback
  ): FeatureFlagManager {
    this.onStateChangeEventListeners = this.onStateChangeEventListeners.filter(
      i => i !== listener
    );

    return this;
  }

  register(name: string | Feature): FeatureFlagManager {
    const feature = name instanceof Feature ? name : new Feature(name);

    if (!this.exists(feature.name)) {
      this.features.push(feature);
    }

    return this;
  }

  exists(name: string): boolean {
    return this.features.some(
      feature => feature.name.toLowerCase() === name.toLowerCase()
    );
  }

  getFeatures(): Feature[] {
    return this.features;
  }

  getEnabledFeatures(): Feature[] {
    return this.features.filter(feature => feature.enabled);
  }

  getEnabledFeatureNames(): string[] {
    return this.getEnabledFeatures().map(feature => feature.name);
  }

  getFeatureByName(name: string): Feature {
    const match = this.features.find(
      feature => feature.name.toLowerCase() === name.toLowerCase()
    );

    if (!match) {
      throw new Error(`${name} is not a valid feature.`);
    }

    return match;
  }

  protected resolveFeature(name: string | Feature): Feature {
    return name instanceof Feature ? name : this.getFeatureByName(name);
  }

  setState(name: string | Feature, state: boolean): FeatureFlagManager {
    const feature = this.resolveFeature(name);

    if (feature.enabled !== state) {
      feature.enabled = state;

      this.saveToCookie();

      this.onStateChangeEventListeners.forEach(onStateChangeEventListener =>
        onStateChangeEventListener(feature, state)
      );
    }

    return this;
  }

  enable(name: string | Feature): FeatureFlagManager {
    return this.setState(name, true);
  }

  disable(name: string | Feature): FeatureFlagManager {
    return this.setState(name, false);
  }

  isEnabled(name: string | Feature): boolean {
    try {
      return this.resolveFeature(name).enabled;
    } catch (e) {
      // If the feature doesn't exist, don't bork
      return false;
    }
  }

  canBeToggled(name: string | Feature): boolean {
    return this.resolveFeature(name).toggleable;
  }

  protected parseFeatureStateFromStr(value: string): FeatureState | null {
    // The feature name in a cookie or query string can be written as the following:
    // featureName (this sets to on)
    // featureName:on
    // featureName:off

    let parsedName = "";
    let enabled = true;
    const position = value.lastIndexOf(":");

    if (position === -1) {
      parsedName = value;
    } else {
      parsedName = value.substring(0, position);
      enabled = value.substring(position + 1) === "on";
    }

    if (!this.exists(parsedName)) {
      return null;
    }

    const feature = this.getFeatureByName(parsedName);

    return new FeatureState(feature, enabled);
  }

  protected parseFeatureStatesFromCookie(): FeatureState[] {
    const cookieValue = Cookies.get(this.cookieName);

    try {
      const values = JSON.parse(cookieValue as string);

      return this.parseFeatureStatesFromArray(values);
    } catch (e) {
      return [];
    }
  }

  protected parseFeatureStatesFromQueryString(): FeatureState[] {
    // We allow for feature flags to be toggled via the query string. eg: ?ft=foo:on&ft=bar:on
    const queryStringParams = queryString.parse(window.location.search);

    // The query string lib returns a string if a single value is given in the url, or an array if multiple values are
    // given.  We therefore normalise this so we always work with an array of names.
    const values = queryStringParams.ft || [];

    return this.parseFeatureStatesFromArray(values);
  }

  protected parseFeatureStatesFromArray(
    values: string | (string | null)[]
  ): FeatureState[] {
    if (!values || values.length < 0) {
      return [];
    }

    const arr = Array.isArray(values) ? values : [values];

    return arr
      .map(value => (value ? this.parseFeatureStateFromStr(value) : null))
      .filter((featureState): featureState is FeatureState => {
        return featureState !== null && featureState.feature.toggleable;
      });
  }

  restoreFromCookie(): FeatureFlagManager {
    this.parseFeatureStatesFromCookie().forEach(featureState => {
      this.setState(featureState.feature, featureState.enabled);
    });

    return this;
  }

  toggleFromQueryString(): FeatureFlagManager {
    this.parseFeatureStatesFromQueryString().forEach(featureState => {
      this.setState(featureState.feature, featureState.enabled);
    });

    return this;
  }

  saveToCookie(): FeatureFlagManager {
    const values = this.features
      .filter(feature => feature.toggleable)
      .map(feature => {
        return feature.enabled ? feature.name : `${feature.name}:off`;
      });

    Cookies.set(this.cookieName, JSON.stringify(values), { expires: 365 });

    return this;
  }

  resetToDefaultValues(): FeatureFlagManager {
    this.features.forEach(feature => {
      this.setState(feature, feature.originalValue);
    });

    return this;
  }
}

export default FeatureFlagManager;
