const theme = {
  screens: {
    // Everything else is mobile
    // Tablet
    "sm-v2": "667px",
    // Laptop
    "md-v2": "900px",
    // Desktop
    "lg-v2": "1200px",
    // Large desktop
    "xl-v2": "1512px",
    // massive desktop
    "4k-v2": "2560px",
  },
};

type YocoComTailwindTheme = typeof theme;

export default theme as YocoComTailwindTheme;
