import classNames from "classnames";
import { useCallback } from "react";

import DeleteIcon from "@/shared/components/elements/DeleteIcon";
import MinusIcon from "@/shared/components/elements/MinusIcon";
import PlusIcon from "@/shared/components/elements/PlusIcon";
import { Color } from "../../../../constants/color";
import Typography from "../Typography";
import { DEFAULT_MAX_QUANTITY } from "../../../../utils/item";

interface Props {
  value: number;
  maxValue?: number;
  minValue?: number;
  className?: string;
  disabled?: boolean;
  isDeleteEnabled?: boolean;
  onQuantityUpdate?: (quantity: number) => void;
  onDelete?: () => void;
}

const buttonStyles =
  "rounded-[5px] h-full cursor-pointer w-full flex items-center";

const QuantityInput = ({
  value,
  maxValue = DEFAULT_MAX_QUANTITY,
  minValue = 1,
  className,
  disabled,
  isDeleteEnabled,
  onQuantityUpdate,
  onDelete,
}: Props) => {
  const decreaseQuantity = useCallback(() => {
    if (value === minValue && isDeleteEnabled && onDelete) {
      onDelete();
    } else if (onQuantityUpdate)
      onQuantityUpdate(Math.max(value - 1, minValue));
  }, [value, isDeleteEnabled, onDelete, onQuantityUpdate, minValue]);

  const increaseQuantity = useCallback(() => {
    if (onQuantityUpdate) onQuantityUpdate(Math.min(value + 1, maxValue));
  }, [onQuantityUpdate, value, maxValue]);

  return (
    <div
      className={classNames(
        "relative flex h-[36px] w-[84px] items-center gap-4-v2 rounded-8-v2 border border-charcoal-v2/20",
        className
      )}
    >
      <button
        type="button"
        aria-label="Decrease quantity"
        onClick={decreaseQuantity}
        disabled={disabled || (value === minValue && !onDelete)}
        data-test-id="decrease-quanitity-button"
        className={classNames(buttonStyles, "group", {
          "pointer-events-none border-black-v2/20 bg-transparent":
            disabled || (value === minValue && !onDelete),
        })}
      >
        {isDeleteEnabled && value === minValue ? (
          <DeleteIcon
            className={classNames(
              "ml-[11px] stroke-black-v2 group-hover:stroke-orange-v2",
              { "stroke-charcoal-v2/20": disabled }
            )}
          />
        ) : (
          <MinusIcon
            className={classNames(
              "ml-[13px] h-[2px] w-8-v2 bg-charcoal-v2 group-hover:bg-blue-v2",
              { "bg-charcoal-v2/20": disabled || value === minValue }
            )}
          />
        )}
      </button>
      <div
        className={classNames(
          "mt-[3px] select-none content-center text-center"
        )}
      >
        <Typography
          text={value.toString()}
          font="grotesk"
          color={Color.Black}
          size="body-md-fixed"
          weight="medium"
          data-test-id="quanitity-input-box"
          className="w-16-v2"
        />
      </div>

      <button
        type="button"
        aria-label="Increase quantity"
        onClick={increaseQuantity}
        disabled={disabled || value === maxValue}
        data-test-id="increase-quanitity-button"
        className={classNames(buttonStyles, "group", {
          "pointer-events-none border-black-v2/20 bg-transparent":
            disabled || value === maxValue,
        })}
      >
        <PlusIcon
          className={classNames(
            "ml-[3px] h-10-v2 fill-charcoal-v2 group-hover:fill-blue-v2",
            { "fill-charcoal-v2/20": disabled || value === maxValue }
          )}
          strokeWidth="3"
        />
      </button>
    </div>
  );
};

export default QuantityInput;
