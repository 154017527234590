import React from "react";
import { PaymentMethod } from "@/shared/shop/api";
import EFTPaymentMethod from "./tabs/EFTPaymentMethod";
import InstantEftBlackbirdPaymentMethod from "./tabs/InstantEftBlackbirdPaymentMethod";
import InstantEFTPaymentMethod from "./tabs/InstantEFTPaymentMethod";
import CreditCardPaymentMethod from "./tabs/CreditCardPaymentMethod";
import FreePaymentMethod from "./FreePaymentMethod";

type Props = {
  name: PaymentMethod;
  onSuccess?: (paymentMethod: PaymentMethod, isPaid?: boolean) => void;
};

const DynamicPaymentMethod = ({ name, onSuccess }: Props) => {
  switch (name) {
    case PaymentMethod.CREDIT_CARD_BLACKBIRD:
    case PaymentMethod.PAYMENT_TERMS:
    case PaymentMethod.CARD_ON_DELIVERY:
    case PaymentMethod.CREDIT_CARD_CHECKOUT:
      return <CreditCardPaymentMethod onSuccess={onSuccess} />;
    case PaymentMethod.EFT:
      return <EFTPaymentMethod onSuccess={onSuccess} />;
    case PaymentMethod.INSTANT_EFT_STITCH:
      return <InstantEFTPaymentMethod onSuccess={onSuccess} />;
    case PaymentMethod.INSTANT_EFT_BLACKBIRD:
      return <InstantEftBlackbirdPaymentMethod onSuccess={onSuccess} />;
    case PaymentMethod.FREE:
      return <FreePaymentMethod onSuccess={onSuccess} />;
    default:
      return null;
  }
};

export default DynamicPaymentMethod;
