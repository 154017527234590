import { useFeatureFlags } from "@yoco/feature-flags";
import { Service, SignupStep } from "@yoco/sawubona-sdk";
import Cookies from "js-cookie";
import { remove } from "lodash";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  getArray,
  getBool,
  getNumber,
  getString,
} from "@/shared/utils/appConfig";
import { getQueryStringParam } from "@/shared/utils/url";
import { useAppConfigStore as useShopAppConfigStore } from "@/shop/store";
import SignupService from "@/signup/services/SignupService";
import {
  useAppConfigStore,
  useSignupStore,
  useStepStore,
} from "@/signup/store";
import { ReferralCampaign, UserJourney } from "@/signup/types";
import Layout from "../../../shared/components/Layout";
import Typography from "../../../shared/components/elements/Typography";
import { Color } from "../../../constants/color";
import LeadForm from "./LeadForm";

const useStore = () => ({
  setUserJourney: useAppConfigStore(state => state.setUserJourney),
  setReferralCode: useAppConfigStore(state => state.setReferralCode),
  setResellerCode: useAppConfigStore(state => state.setResellerCode),
  setSalesSmartProposalId: useAppConfigStore(
    state => state.setSalesSmartProposalId
  ),
  setPromoCode: useAppConfigStore(state => state.setPromoCode),
  setAutoAddProductID: useAppConfigStore(state => state.setAutoAddProductID),
  setReaderSerialNumber: useAppConfigStore(
    state => state.setReaderSerialNumber
  ),
  setServices: useAppConfigStore(state => state.setServices),
  setSkipServiceSelectionStep: useAppConfigStore(
    state => state.setSkipServiceSelectionStep
  ),
  setSkipProductCatalogueStep: useAppConfigStore(
    state => state.setSkipProductCatalogueStep
  ),
  setActiveStep: useStepStore(state => state.setActiveStep),
  setReferralDetails: useSignupStore(state => state.setReferralDetails),
  setStorefront: useShopAppConfigStore(state => state.setStorefront),
  setPreQualification: useAppConfigStore(state => state.setPreQualification),
});

const HomePage = () => {
  const location = useLocation();
  const {
    setUserJourney,
    setReferralCode,
    setReferralDetails,
    setResellerCode,
    setSalesSmartProposalId,
    setPromoCode,
    setAutoAddProductID,
    setReaderSerialNumber,
    setServices,
    setSkipServiceSelectionStep,
    setSkipProductCatalogueStep,
    setActiveStep,
    setStorefront,
    setPreQualification,
  } = useStore();
  const features = useFeatureFlags();
  const { name: partnerName } = useParams();

  useEffect(() => {
    const userJourneyFromQueryString = getString(
      "userJourney"
    ) as UserJourney | null;

    // The "/xneelo" page is a special baby where we serve a custom user journey
    // In the event that the user is seeing the "/xneelo" home page, we'll force the user journey to "xneelo".
    const userJourney = location.pathname.toLowerCase().startsWith("/xneelo")
      ? UserJourney.XNEELO
      : userJourneyFromQueryString;

    let promoCode = getString("promoCode");

    let referralCode: string | null = null;
    let resellerCode = getQueryStringParam("resellerCode");
    const salesSmartProposalId = getQueryStringParam("salesSmartProposalId");

    if (!resellerCode) {
      // This signup did not come from a reseller link generated from the new reseller platform.
      // We'll check to see if this person signed up using an "mbsy".  This is a legacy query string we still need to
      // support which was used by the external Ambassador referral platform.

      const mbsy = getQueryStringParam("mbsy");

      if (mbsy) {
        const referralCampaignID = parseInt(
          getQueryStringParam("campaignid"),
          10
        );

        if (
          referralCampaignID === ReferralCampaign.AMBASSADOR_RESELLER ||
          SignupService.isResellerCode(mbsy)
        ) {
          resellerCode = mbsy;
        } else {
          referralCode = mbsy;
        }
      } else {
        referralCode = Cookies.get("referral_code") as string;
      }
    }

    // A user coming from another business' referral link will have a promo code automagically applied to their cart
    if (referralCode) {
      promoCode = "ReferralDiscount";
      SignupService.isResellerCode(referralCode);
    }

    if (resellerCode) {
      // A user coming from a reseller link will have a promo code, set to the same code as that of the reseller,
      // applied to their cart.  The reseller team doesn't always create a promo code for a reseller, so this may or may
      // not exist.
      if (!promoCode) {
        promoCode = resellerCode;
      }
    }

    // The "reader registration" and "purchase / add to cart" flows are mutually exclusive.  If the user is registering
    // a reader, we don't allow them to auto add to cart.
    const readerSerialNumber =
      getString("readerSerialNumber") || getString("serialNumber");
    const autoAddProductID = readerSerialNumber ? null : getNumber("productID");

    let services = getArray<Service>("services");

    // eslint-disable-next-line no-underscore-dangle
    const _removeService = (name: string) => {
      remove(services, service => {
        return service === name;
      });
    };

    if (services.includes("retail" as Service)) {
      // The "retail" service was renamed to "reader_registration"
      _removeService("retail");
      services.push(Service.READER_REGISTRATION);
    }

    // Let's make sure that we only include valid services, and not any other junk that may be passed in.
    services = services.filter(service =>
      Object.values(Service).includes(service)
    );

    if (readerSerialNumber) {
      // If we have a pre-populated reader serial number, the "reader registration" service must be selected.
      if (!services.includes(Service.READER_REGISTRATION)) {
        services.push(Service.READER_REGISTRATION);
      }

      _removeService(Service.CARD_MACHINE);
    } else if (autoAddProductID) {
      // If we're auto adding a product to cart, the "card machine" service must be selected.
      if (!services.includes(Service.CARD_MACHINE)) {
        services.push(Service.CARD_MACHINE);
      }

      _removeService(Service.READER_REGISTRATION);
    }

    if (userJourney === UserJourney.YOCOSHOP) {
      if (!services.includes(Service.WAITING_FOR_DELIVERY)) {
        services.push(Service.WAITING_FOR_DELIVERY);
      }
    }

    // The "card machine" and "reader registration" services are mutually exclusive - you can only select one
    // or the other.
    if (services.includes(Service.CARD_MACHINE)) {
      _removeService(Service.READER_REGISTRATION);
    } else if (services.includes(Service.READER_REGISTRATION)) {
      _removeService(Service.CARD_MACHINE);
    }

    // If this is a referral and no service is selected, we'll pre-select the "card machine" service
    if (services.length === 0 && referralCode) {
      services.push(Service.CARD_MACHINE);
    }

    let activeStep = SignupStep.HomePage;

    if (userJourney === UserJourney.XNEELO) {
      services.push(
        Service.GIFT_VOUCHERS,
        Service.PAYMENT_REQUEST,
        Service.WEBSITE
      );
      activeStep = SignupStep.XneeloLandingPage;
      resellerCode = "xneelo";
    }

    if (location.pathname.toLowerCase().startsWith("/partner/")) {
      // We use partner platforms and websites, such as xneelo, to acquire customers.  These aren't actual resellers,
      // but rather, affiliate partners; however since we don't have a separate field to identity that a given signup is
      // from xyz affiliate, we store this attribution in the reseller code field.
      resellerCode = partnerName;
    }

    const skipProductCatalogueStep = autoAddProductID
      ? getBool("skipProductCatalogueStep")
      : false;
    const skipServiceSelectionStep =
      services.length > 0 &&
      !!(getBool("skipServiceSelectionStep") || referralCode);

    const preQualification = getString("preQualification");
    const preQualificationData = preQualification
      ? serializePreQualification(preQualification)
      : null;

    setReferralCode(referralCode);
    setResellerCode(resellerCode);
    setSalesSmartProposalId(salesSmartProposalId);
    setPromoCode(promoCode);
    setAutoAddProductID(autoAddProductID);
    setReaderSerialNumber(readerSerialNumber);
    setServices(services);
    setSkipProductCatalogueStep(skipProductCatalogueStep);
    setSkipServiceSelectionStep(skipServiceSelectionStep);
    setActiveStep(activeStep);
    setUserJourney(userJourney);
    setReferralDetails(referralCode);
    if (resellerCode) {
      setStorefront("reseller");
    } else if (referralCode) {
      setStorefront("referral");
    }
    setPreQualification(preQualificationData);
  }, [
    partnerName,
    location,
    features,
    setReferralCode,
    setResellerCode,
    setPromoCode,
    setAutoAddProductID,
    setReaderSerialNumber,
    setServices,
    setSkipProductCatalogueStep,
    setSkipServiceSelectionStep,
    setUserJourney,
    setStorefront,
    setActiveStep,
    setReferralDetails,
    setSalesSmartProposalId,
    setPreQualification,
  ]);

  return (
    <Layout>
      <LeadForm />
    </Layout>
  );
};

function serializePreQualification(string: string) {
  try {
    return JSON.parse(decodeURIComponent(string));
  } catch {
    return null;
  }
}

export default HomePage;
