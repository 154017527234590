import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import { useSignupStore, useStepStore } from "@/signup/store";
import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Layout from "../../../shared/components/Layout";
import FormHeader from "../../../shared/components/checkout/FormHeader";
import Field from "../../../shared/components/elements/Inputs/Field";
import TextInput from "../../../shared/components/elements/Inputs/TextInput";
import SubmitButton from "../../../shared/components/checkout/SubmitButton";

const useStore = () => ({
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

const BusinessUrlPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { signup, updateSignup, getNextStepLink } = useStore();

  useEffect(() => {
    Segment.track("web_signup_business_url_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.BusinessUrlPage,
    business_url: signup?.business_url || "",
  };

  const schema = Yup.object().shape({
    business_url: Yup.string()
      .url("Must be a valid URL")
      .required("Business URL is required"),
  });

  const onSubmit = async (value: FormikValues) => {
    const isSignupUpdated = await updateSignup(value);

    if (isSignupUpdated) {
      Segment.trackWithSignup("web_signup_business_url_next_button_clicked");

      navigate(getNextStepLink());
    }
  };

  return (
    <Layout>
      <FormHeader
        title="What's your business url?"
        description="We need it to validate your online store."
      />
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <div className="flex flex-col gap-32-v2">
                <Field
                  type="text"
                  id="business_url"
                  name="business_url"
                  label="Business URL"
                  placeholder="https://www.example.com"
                  component={TextInput}
                  data-testid={makeTestID("businessUrl", "businessUrl")}
                />
                <SubmitButton testID={makeTestID("businessName", "next")} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default withFetchSignup(BusinessUrlPage, SignupStep.BusinessUrlPage);
