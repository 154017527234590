import { makeTestID } from "@/shared/utils/development";
import { useSignupStore } from "@/signup/store";
import { BusinessType } from "@yoco/sawubona-sdk";
import React, { useState } from "react";
import TermsContract from "./TermsContract";
import Typography from "../../../../elements/Typography";
import { Color } from "../../../../../../constants/color";
import Field from "../../../../elements/Inputs/Field";
import TextInput from "../../../../elements/Inputs/TextInput";
import CheckBox from "../../../../elements/Inputs/Checkbox";
import TextButton from "../../../../elements/Buttons/TextButton";

const useStore = () => ({
  signup: useSignupStore(state => state.signup),
});

const cipcNumberMask = "0000/000000/00";

const TermsAgreementForm: React.FC = () => {
  const { signup } = useStore();

  const [isContractExpanded, setIsContractExpanded] = useState(false);

  return (
    <div className="mb-20-v2">
      <Typography
        font="grotesk"
        size="body-lg"
        text={`${signup?.business_name}'s details`}
        color={Color.Black}
        weight="medium"
        element="h3"
      />

      <div className="my-8-v2">
        <div className="flex flex-row items-stretch">
          <Typography
            font="grotesk"
            size="body-md"
            text="Owner first name:"
            color={Color.Black}
            weight="book"
            className="flex-1"
          />
          <Typography
            font="grotesk"
            size="body-md"
            text={signup?.owner_first_name || ""}
            color={Color.Black}
            weight="book"
            className="flex-right"
          />
        </div>
        <div className="flex flex-row items-stretch">
          <Typography
            font="grotesk"
            size="body-md"
            text="Owner last name:"
            color={Color.Black}
            weight="book"
            className="flex-1"
          />
          <Typography
            font="grotesk"
            size="body-md"
            text={signup?.owner_last_name || ""}
            color={Color.Black}
            weight="book"
            className="flex-right"
          />
        </div>
        <div className="flex flex-row items-stretch">
          <Typography
            font="grotesk"
            size="body-md"
            text={`Owner ${
              signup?.owner_document_type === "sa_id_number"
                ? "ID number"
                : "passport number"
            }:`}
            color={Color.Black}
            weight="book"
            className="flex-1"
          />
          <Typography
            font="grotesk"
            size="body-md"
            text={signup?.owner_id_passport_number || ""}
            color={Color.Black}
            weight="book"
            className="flex-right"
          />
        </div>
      </div>
      {signup?.business_type === BusinessType.COMPANY && (
        <div className=" mb-8-v2">
          <Field
            id="business_registration_number"
            name="business_registration_number"
            label="Company registration number"
            placeholder="0000/000000/00"
            component={TextInput}
            mask={cipcNumberMask}
            type="text"
            data-testid={makeTestID(
              "paymentPage",
              "businessRegistrationNumber"
            )}
          />
        </div>
      )}

      <div className="flex flex-col gap-8-v2">
        <Field
          id="agree_with_terms_and_conditions"
          name="agree_with_terms_and_conditions"
          label="I agree and accept Retail Capital's Purchase Agreement"
          component={CheckBox}
          data-testid={makeTestID("paymentPage", "purchaseAgreement")}
          className="inline"
        />
        <TextButton
          style="text"
          onClick={() => setIsContractExpanded(!isContractExpanded)}
          text={`${isContractExpanded ? "Hide" : "Show"} Purchase Agreement`}
          textSize="body-sm"
          color={Color.Blue}
          className="mx-auto"
        />
        {isContractExpanded && (
          <div>
            <hr className="mt-4" />
            <div
              className="py-4 overflow-auto"
              style={{
                height: 360,
              }}
            >
              <TermsContract />
            </div>
            <hr className="mb-4" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TermsAgreementForm;
