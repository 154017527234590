import React, { useEffect } from "react";
import { PaymentMethod } from "@/shared/shop/api";
import { makeTestID } from "@/shared/utils/development";
import DynamicSecondaryPaymentMethod from "@/shop/pages/PaymentPage/tabs/CreditCardPaymentMethod/DynamicSecondaryPaymentMethod";
import SecondaryPaymentMethodNavItem from "@/shop/pages/PaymentPage/tabs/CreditCardPaymentMethod/SecondaryPaymentMethodNavItem";
import { useCartStore } from "@/shop/store";
import { PaymentMethodNavItem, PaymentMethodProps } from "@/shop/types";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  intendedPaymentMethod: useCartStore(state => state.intendedPaymentMethod),
  setIntendedPaymentMethod: useCartStore(
    state => state.setIntendedPaymentMethod
  ),
  availablePaymentMethods: useCartStore(
    state => state.cart?.available_payment_methods
  ),
});

const CreditCardPaymentMethod: React.FC<PaymentMethodProps> = ({
  onSuccess,
}: PaymentMethodProps) => {
  const {
    availablePaymentMethods,
    cart,
    intendedPaymentMethod,
    setIntendedPaymentMethod,
  } = useStore();

  useEffect(() => {
    if (availablePaymentMethods?.includes(PaymentMethod.CREDIT_CARD_CHECKOUT)) {
      setIntendedPaymentMethod(PaymentMethod.CREDIT_CARD_CHECKOUT);
    }
  }, [availablePaymentMethods, setIntendedPaymentMethod]);

  const balance = cart?.balance_due_formatted;
  const instalmentPlanProposal = cart?.instalment_plan_proposal;
  const deposit = instalmentPlanProposal?.instalments[0];
  const minMonthlyInstalment = deposit?.amount_formatted;
  const navItems: PaymentMethodNavItem[] = [
    {
      paymentMethod: PaymentMethod.CREDIT_CARD_CHECKOUT,
      label: "Pay now",
      // eslint-disable-next-line react/display-name
      subLabel: (
        <>
          <strong>{balance}</strong> once-off
        </>
      ),
      description: "Online with your card or instant eft.",
    },
    {
      paymentMethod: PaymentMethod.CARD_ON_DELIVERY,
      label: "Pay on delivery",
      subLabel: (
        <div>
          <strong>{balance}</strong> once-off
        </div>
      ),
      description: (
        <div>The courier will take card payment when they deliver.</div>
      ),
    },
    {
      paymentMethod: PaymentMethod.PAYMENT_TERMS,
      label: "Instalments",
      // eslint-disable-next-line react/display-name
      subLabel: (
        <div>
          <strong>{minMonthlyInstalment}</strong> per month
        </div>
      ),
    },
  ];

  const visibleNavItems = navItems.filter(navItem =>
    availablePaymentMethods?.includes(navItem.paymentMethod)
  );

  const onChangePaymentMethod = (paymentMethod: PaymentMethod) => {
    setIntendedPaymentMethod(paymentMethod);
  };

  return (
    <>
      {visibleNavItems.length > 1 ? (
        <div className="flex flex-col mb-4">
          {visibleNavItems.map(navItem => (
            <SecondaryPaymentMethodNavItem
              key={navItem.paymentMethod}
              name="secondary_payment_method"
              type="radio"
              value={navItem.paymentMethod}
              label={navItem.label}
              description={navItem.description}
              subLabel={navItem.subLabel}
              onChange={() => onChangePaymentMethod(navItem.paymentMethod)}
              checked={intendedPaymentMethod === navItem.paymentMethod}
              data-testid={makeTestID(
                "payment",
                "secondaryMethod",
                navItem.label
              )}
            />
          ))}
        </div>
      ) : null}

      <DynamicSecondaryPaymentMethod
        name={intendedPaymentMethod}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default CreditCardPaymentMethod;
