import classNames from "classnames";
import { Product } from "@yoco/shop-sdk";
import { isNumber } from "lodash";
import { Bundle } from "@yoco/shop-sdk/lib/types";
import { Color } from "../../../../constants/color";
import Badge from "../../elements/Badge";
import AddToCartButton from "../../elements/Buttons/AddToCartButton";
import PillButton from "../../elements/Buttons/PillButton";
import TextButton from "../../elements/Buttons/TextButton";
import NumberDisplay from "../../elements/NumberDisplay";
import Typography from "../../elements/Typography";
import {
  getAccentColor,
  getBackgroundColorClass,
} from "../../../../utils/color";

type Props = {
  imageUrl: string;
  imageAlt: string;
  priceValue?: string | number;
  priceSuperscriptText?: string;
  priceAdornmentText?: string;
  priceStrikeSuperscriptText?: boolean;
  priceSubscriptText?: string;
  priceSideScriptText?: string;
  learnMoreButtonUrl?: string;
  calloutLabel?: string;
  badgeText?: string;
  badgeAccentColor?: Color;
  isPurchasable?: boolean;
  item: Product | Bundle;
  bundleId?: string;
  bundleName?: string;
  ctaText?: string;
  ctaLink?: string;
  /**
   * When using `row` will still render `column`
   * layout on mobile to prevent horizontal overflow
   */
  direction?: "column" | "row";
  ctaOnClick?: () => void;
  ctaDisabled?: boolean;
  onAddedToCart?: (item: Product | Bundle) => void;
};

const CardMachine = ({
  imageUrl,
  imageAlt,
  priceValue,
  priceSuperscriptText,
  priceAdornmentText = "R",
  priceStrikeSuperscriptText = false,
  priceSideScriptText,
  priceSubscriptText,
  ctaText = "Buy Now",
  ctaLink,
  ctaOnClick,
  ctaDisabled = false,
  learnMoreButtonUrl,
  calloutLabel,
  badgeText,
  badgeAccentColor = Color.Orange,
  isPurchasable = false,
  direction = "column",
  onAddedToCart,
  item,
  ...props
}: Props) => {
  const backgroundColor = Color.LightGray;
  return (
    <div
      className={classNames(
        getBackgroundColorClass(backgroundColor),
        "col-span-4 flex rounded-20-v2 sm-v2:col-span-2 md-v2:col-span-3 md-v2:rounded-24-v2 lg-v2:col-span-4 lg-v2:rounded-32-v2",
        direction === "column" ? "flex-col" : "flex-col sm-v2:flex-row"
      )}
      {...props}
    >
      <div className="relative flex flex-none items-center justify-center">
        <img
          // ToDo: pull these images in from api rather
          src={imageUrl}
          alt={imageAlt}
          className="w-[225px]"
        />

        {badgeText && (
          <Badge
            text={badgeText}
            textColor={getAccentColor(badgeAccentColor)}
            accentColor={badgeAccentColor}
            style="solid"
            className="absolute left-[14px] top-[14px] md-v2:left-20-v2 md-v2:top-20-v2"
          />
        )}
        {calloutLabel && (
          <div className="absolute right-8-v2 top-0 flex h-full items-center md-v2:right-[9px] lg-v2:right-[10px]">
            <Typography
              font="grotesk"
              weight="book"
              size="subscript-lg"
              color={getAccentColor(backgroundColor)}
              text={calloutLabel}
              className="origin-center rotate-180 uppercase opacity-50 [writing-mode:vertical-lr]"
            />
          </div>
        )}
      </div>

      <div
        className={classNames(
          "flex grow flex-col gap-16-v2 px-20-v2 pb-24-v2 md-v2:gap-20-v2 md-v2:px-24-v2 md-v2:pb-32-v2 lg-v2:gap-24-v2 lg-v2:px-32-v2 lg-v2:pb-40-v2",
          direction === "column"
            ? "pt-8-v2 md-v2:pt-16-v2"
            : "pt-8-v2 sm-v2:pt-24-v2"
        )}
      >
        <div className="grow">
          <Typography
            font="grotesk"
            weight="medium"
            size="lead-md"
            color={getAccentColor(backgroundColor)}
            text={item.short_name}
            className="mb-4-v2"
          />
          <Typography
            font="grotesk"
            weight="book"
            size="body-md"
            color={getAccentColor(backgroundColor)}
            text={item.description}
          />
        </div>
        <div className="flex flex-none flex-col gap-16-v2 md-v2:gap-20-v2 lg-v2:gap-24-v2">
          <NumberDisplay
            type="price"
            size="small"
            value={
              priceValue
                ? isNumber(priceValue)
                  ? priceValue
                  : parseInt(priceValue, 10)
                : undefined
            }
            superscriptText={priceSuperscriptText}
            adornmentText={priceAdornmentText}
            strikeSuperscriptText={priceStrikeSuperscriptText}
            adornmentTextPosition="left"
            textColor={getAccentColor(backgroundColor)}
            subscriptText={priceSubscriptText}
            sideScriptText={priceSideScriptText}
          />
          <div className="flex w-full flex-col items-center gap-16-v2">
            <div className="w-full">
              {isPurchasable ? (
                <AddToCartButton
                  style="bordered"
                  item={item}
                  buttonColor={Color.Blue}
                  className="!w-full border-[1.5px]"
                  onAdd={onAddedToCart}
                />
              ) : (
                (ctaLink || ctaOnClick) && (
                  <PillButton
                    hideIcon
                    size="large"
                    text={ctaText}
                    style="bordered"
                    color={Color.Blue}
                    linkUrl={ctaLink}
                    disabled={ctaDisabled}
                    onClick={ctaOnClick}
                    overrideTextColor={getAccentColor(backgroundColor)}
                    className="!w-full border-[1.5px]"
                  />
                )
              )}
            </div>
            {learnMoreButtonUrl && (
              <TextButton
                linkUrl={learnMoreButtonUrl}
                opensNewTab
                style="text"
                text="LEARN MORE"
                color={getAccentColor(backgroundColor)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardMachine;
