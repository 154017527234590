import React from "react";

type Props = {
  children: React.ReactNode;
  wider?: boolean;
};

const CardMain: React.FC<Props> = ({ children, wider }: Props) => {
  return (
    <div
      className={`bg-white mb-4 max-w-full w-content rounded-xl ${
        wider ? "lg:w-auto max-w-6xl" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default CardMain;
