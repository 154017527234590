type Props = {
  className?: string;
};

const DeleteIcon = ({ className }: Props) => {
  return (
    <svg
      width={11}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      strokeWidth={1.5}
    >
      <path d="M1.75 1.75h7.5v10.5h-7.5z" />
      <path d="M.5 1.5h10v1H.5zM3.5.5h4v1h-4z" />
    </svg>
  );
};

export default DeleteIcon;
