import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";
import CONFIG from "@/config";
import Validator from "@/shared/services/Validator";

import { Features } from "@/signup/types";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Feature } from "@yoco/feature-flags";
import { useCallback } from "react";

import { setLocale } from "yup";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FeatureFlagProvider } from "./libs/feature-flags/FeatureFlags";
import { getQueryStringParams } from "./shared/utils/url";
import { useCartStore } from "./shop/store";
import { useSignupStore } from "./signup/store";
import Routes from "./Routes";

if (CONFIG.sentryDSN) {
  Sentry.init({
    dsn: CONFIG.sentryDSN,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
    release: CONFIG.gitCommitSha,
    // Ignore errors which are largely out of our control:
    // - Network issues
    // - 3rd party lib errors
    // - Errors from user actions (like reloading a page during a request resulting in a browser error)
    ignoreErrors: [
      "TypeError: cancelled",
      "Error: Request aborted",
      "TypeError: Failed to fetch",
      "TypeError: Load failed",
      "TypeError: t is undefined",
      "TypeError: null is not an object (evaluating 't.bubbles')",
      "Error: Network Error",
      "TypeError: Illegal invocation",
      "UnhandledRejection: Non-Error promise rejection captured with value",
      "Error: Couldn't find your IP",
    ],
  });
}

setLocale(Validator.messages);

const useStore = () => ({
  setSignupFeatureFlag: useSignupStore(state => state.setSignupFeatureFlag),
  setCartFeatureFlag: useCartStore(state => state.setCartFeatureFlag),
});

function App() {
  window.yc = window.yc || {};

  const { setSignupFeatureFlag, setCartFeatureFlag } = useStore();

  const onFeatureFlagStateChange = useCallback(
    (feature: Feature, state: boolean) => {
      // This callback is triggered when a frontend feature flag state changes.
      // If we have a signup or cart at this stage, we'll let our store sync the change to the backend.

      const { signup } = useSignupStore.getState();
      const { cart } = useCartStore.getState();

      if (cart) {
        setCartFeatureFlag(feature, state);
      }

      if (signup) {
        setSignupFeatureFlag(feature, state);
      }
    },
    [setCartFeatureFlag, setSignupFeatureFlag]
  );

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <HelmetProvider>
        <Helmet>
          <html lang="en" id="v2-app" className="v2" />
        </Helmet>
        <FeatureFlagProvider
          features={[
            Features.TERMS,
            Features.SWAP_GO_FOR_KHUMO,
            Features.DISABLE_CHECKOUT_PAYMENT_METHOD,
          ]}
          onStateChange={onFeatureFlagStateChange}
        >
          {features => {
            window.yc.ft = features;

            // We use VWO (A/B testing platform) to segment users into cohorts and conditionally enable feature flags on
            // hello-yoco.  When a customer opts out of all VWO tracking and experiments, we need to restore all feature
            // flags to their default values.  This is because feature flag values are persisted in cookies; so opting out
            // of experiments would otherwise never restore to default.
            const queryStringParams = getQueryStringParams();
            if (
              queryStringParams.vwo_opt_out &&
              queryStringParams.vwo_opt_out === "1"
            ) {
              features.resetToDefaultValues();
            }

            return <Routes />;
          }}
        </FeatureFlagProvider>
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
