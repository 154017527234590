import { SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import { useSignupStore, useStepStore } from "@/signup/store";
import Layout from "../../../shared/components/Layout";
import FormHeader from "../../../shared/components/checkout/FormHeader";
import Field from "../../../shared/components/elements/Inputs/Field";
import TextInput from "../../../shared/components/elements/Inputs/TextInput";
import Typography from "../../../shared/components/elements/Typography";
import { Color } from "../../../constants/color";
import TextButton from "../../../shared/components/elements/Buttons/TextButton";
import SubmitButton from "../../../shared/components/checkout/SubmitButton";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

const schema = yup.object({
  owner_first_name: yup.string().required().label("business owner name(s)"),
  owner_last_name: yup.string().required().label("business owner surname"),
});

const BusinessOwnerPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { setActiveStep, signup, updateSignup, getNextStepLink } = useStore();

  useEffect(() => {
    setActiveStep(SignupStep.BusinessOwnerPage);
  }, [setActiveStep]);

  useEffect(() => {
    Segment.track("web_signup_business_owner_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.BusinessOwnerPage,
    owner_first_name: signup?.owner_first_name || "",
    owner_last_name: signup?.owner_last_name || "",
  };

  const onSubmit = async (values: FormikValues) => {
    const isSignupUpdated = await updateSignup({
      ...values,
      pre_qualification: {
        ...signup?.pre_qualification,
        name: `${values.owner_first_name} ${values.owner_last_name}`,
      },
    });

    if (isSignupUpdated) {
      Segment.trackWithSignup("web_signup_business_owner_next_button_clicked");

      // The marketing team currently uses this event to track that this signup is now an "opportunity".  Whilst we
      // have a plan to move to a semantic event indicating that an opportunity has been created, we need this as an
      // interim solution.
      Segment.trackWithSignup(
        "web_signup_business_details_info_next_button_clicked"
      );

      navigate(getNextStepLink());
    }
  };

  return (
    <Layout>
      <FormHeader
        title="What's your name?"
        description="You must be the sole trader, owner or a director, partner, member or trustee of the business you're signing up for."
      />

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <div className="flex flex-col gap-32-v2">
                <div className="flex flex-col gap-16-v2">
                  <Field
                    name="owner_first_name"
                    id="owner_first_name"
                    type="text"
                    placeholder="First Name"
                    label="First Name"
                    required
                    component={TextInput}
                    data-testid={makeTestID(
                      "businessOwner",
                      "businessOwner",
                      "firstName"
                    )}
                  />
                  <Field
                    name="owner_last_name"
                    id="owner_last_name"
                    type="text"
                    placeholder="Surname"
                    label="Surname"
                    required
                    component={TextInput}
                    data-testid={makeTestID(
                      "businessOwner",
                      "businessOwner",
                      "lastName"
                    )}
                  />
                </div>
                <Typography
                  font="grotesk"
                  size="body-md"
                  element="p"
                  color={Color.Black}
                  weight="book"
                  text="We'll use these details to verify you, and get you paid."
                />
                <SubmitButton testID={makeTestID("businessOwner", "next")} />
                <div>
                  <Typography
                    font="grotesk"
                    size="body-md"
                    element="p"
                    color={Color.Black}
                    weight="book"
                    text="Not the owner? Get more details about what you need to sign
                    up and get set up to get paid out "
                    className="inline"
                  />
                  <TextButton
                    text="here"
                    textSize="body-md"
                    style="underlined"
                    color={Color.Blue}
                    opensNewTab
                    linkUrl="https://support.yoco.help/en/articles/109534-setup-verification-basics"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default withFetchSignup(BusinessOwnerPage, SignupStep.BusinessOwnerPage);
