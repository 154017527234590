import { Address } from "@/shared/types";
import AddressService from "@/shop/services/AddressService";
import React from "react";
import TextButton from "../../Buttons/TextButton";
import { Color } from "../../../../../constants/color";

type Props = {
  onLocateAddress: (address: Address) => void;
  onError?: (Error) => void;
};

const UseMyLocation: React.FC<Props> = ({
  onLocateAddress,
  onError,
}: Props) => {
  const getCurrentLatLng = (): Promise<GeolocationPosition> => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve(position);
        },
        () => {
          reject(new Error("Unable to get current coordinates from browser."));
        }
      );
    });
  };

  const locateAddress = async () => {
    try {
      const position = await getCurrentLatLng();
      const address = await AddressService.getAddressFromLatLng(
        position.coords.latitude,
        position.coords.longitude
      );
      onLocateAddress(address);
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  };

  if (!("geolocation" in navigator)) {
    return null;
  }

  return (
    <TextButton
      onClick={locateAddress}
      text="Use my current location"
      style="underlined"
      textSize="body-md"
      weight="book"
      color={Color.Blue}
    />
  );
};
export default UseMyLocation;
