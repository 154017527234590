import { useCallback, useEffect, useState } from "react";

import { Product } from "@yoco/shop-sdk";
import { Bundle } from "@yoco/shop-sdk/lib/types";
import classNames from "classnames";
import { formatPrice } from "@/utils/price";
import { useIsBreakpoint } from "@/utils/screen";
import { useCartDrawerStore } from "@/shared/components/checkout/Drawer/Root";
import PillButton from "./PillButton";

import { Color } from "../../../../constants/color";

import Checkmark from "../Checkmark";
import PlusIcon from "../PlusIcon";
import Segment from "../../../services/Segment";
import { useCartStore } from "../../../../shop/store";
import { isBundle } from "../../../../utils/item";

type Props = {
  style?: "solid" | "bordered";
  size?: "small" | "medium" | "large";
  hideIcon?: boolean;
  className?: string;
  item: Product | Bundle;
  quantity?: number;
  changeCartQuantity?: boolean;
  reasonNotAvailable?: string;
  buttonColor?: Color;
  price?: number;
  onAdd?: (product: Product | Bundle) => void;
};

const useStore = () => ({
  addProduct: useCartStore(state => state.add),
  addBundle: useCartStore(state => state.addBundle),
});

const useAlreadyInCart = (productOrBundle: Product | Bundle) => {
  return useCartStore(
    state =>
      !!state.cart?.items.find(item =>
        isBundle(productOrBundle)
          ? item.bundle?.id === productOrBundle.id
          : !item.bundle && item.product.sku === productOrBundle.sku
      )
  );
};

const AddToCartButton = ({
  style = "bordered",
  size = "large",
  hideIcon,
  className,
  quantity = 1,
  changeCartQuantity,
  reasonNotAvailable,
  buttonColor = Color.Blue,
  price,
  item,
  onAdd,
}: Props) => {
  const productAlreadyInCart = useAlreadyInCart(item);
  const isLaptop = useIsBreakpoint("laptop");
  const { setCartDrawerOpen } = useCartDrawerStore();

  const iconStyles =
    "group-hover:fill-white-v2 group-hover:rotate-90 group-focus-visible:rotate-90 transform transition duration-300 ease-in-out";

  const { addProduct, addBundle } = useStore();
  const [handlingAddToCart, setHandlingAddToCart] = useState(false);

  const [updateCart, setUpdateCart] = useState(changeCartQuantity);

  const handleAddToCart = useCallback(async () => {
    // TODO combine this and add multiple
    setHandlingAddToCart(true);
    if (isBundle(item)) {
      await addBundle(item.id, quantity);
    } else {
      await addProduct(item.id, quantity);
    }
    Segment.track("shop_add_to_cart", {
      ...(isBundle(item)
        ? { bundle_code: item.bundle_code }
        : { sku: item.sku }),
      quantity,
    });

    if (onAdd) {
      onAdd(item);
    }

    // Trigger the drawer on mobile
    if (!isLaptop) {
      setCartDrawerOpen(true);
    }

    setUpdateCart(false);
    setHandlingAddToCart(false);
  }, [addProduct, item, onAdd, quantity]);

  const handleUpdateCart = useCallback(async () => {
    if (isBundle(item)) {
      await addBundle(item.id, quantity);
    } else {
      await addProduct(item.id, quantity);
    }
    setUpdateCart(false);
    if (changeCartQuantity === true) {
      setUpdateCart(true);
    }
  }, [addBundle, addProduct, changeCartQuantity, item, quantity]);

  useEffect(() => {
    if (changeCartQuantity === true) {
      setUpdateCart(true);
    } else {
      setUpdateCart(false);
    }
  }, [changeCartQuantity]);

  if (reasonNotAvailable) {
    return <p>{reasonNotAvailable}</p>;
  }

  if (!productAlreadyInCart) {
    return (
      <PillButton
        text="Add to cart"
        size={size}
        style={style}
        icon={<PlusIcon className={classNames("fill-black-v2", iconStyles)} />}
        hideIcon={hideIcon}
        color={buttonColor}
        onClick={handleAddToCart}
        disabled={handlingAddToCart}
        className={className}
        data-test-id="add-to-cart"
        overrideTextColor={Color.Black}
        secondaryText={
          price
            ? formatPrice(price * quantity, {
                hasThousandsSeparator: true,
                shouldShowDecimals: true,
              })
            : ""
        }
      />
    );
  }

  if (!updateCart) {
    return (
      <PillButton
        text="In cart"
        size={size}
        style="bordered"
        icon={
          <Checkmark className="stroke-blue-v2 group-hover:stroke-white-v2" />
        }
        hideIcon={hideIcon}
        color={Color.Blue}
        disabled={handlingAddToCart}
        className={className}
        overrideTextColor={Color.Blue}
      />
    );
  }

  return (
    <PillButton
      text="Update cart"
      size={size}
      style="solid"
      disabled={handlingAddToCart}
      icon={<PlusIcon className={iconStyles} />}
      hideIcon={hideIcon}
      color={Color.Blue}
      onClick={handleUpdateCart}
    />
  );
};

export default AddToCartButton;
