import classNames from "classnames";
import React from "react";

import Typography from "./Typography";
import { Color } from "../../../constants/color";

import {
  getBorderColorClass,
  getBackgroundColorClass,
} from "../../../utils/color";

type Props = {
  text: string;
  textColor: Color;
  accentColor: Color;
  style: "solid" | "bordered";
  className?: string;
};

const Badge: React.FC<Props> = ({
  text,
  style,
  textColor,
  accentColor,
  className,
}) => {
  return (
    <Typography
      text={text}
      weight="book"
      font="grotesk"
      color={textColor}
      size="subscript-lg"
      className={classNames(
        getBorderColorClass(accentColor),
        style === "solid" && getBackgroundColorClass(accentColor),
        "pt-4-v2 pb-0.5 px-8-v2 rounded-8-v2 w-fit uppercase border",
        className
      )}
    />
  );
};

export default Badge;
