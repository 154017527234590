import { SignupStep } from "@yoco/sawubona-sdk";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Segment from "@/shared/services/Segment";
import { useCartStore } from "@/shop/store";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import { useSignupStore, useStepStore } from "@/signup/store";
import { Product } from "@yoco/shop-sdk";
import { Bundle } from "@yoco/shop-sdk/lib/types";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import ProductGrid from "./ProductGrid";
import LayoutOrderSummary from "../../../shared/components/checkout/LayoutOrderSummary";
import SubmitButton from "../../../shared/components/checkout/SubmitButton";
import { isBundle } from "../../../utils/item";

const useStore = () => ({
  setActiveStep: useStepStore(state => state.setActiveStep),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
  cart: useCartStore(state => state.cart),
});

const ProductCataloguePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { updateSignup, getNextStepLink, cart } = useStore();

  useEffect(() => {
    Segment.track("web_signup_product_catalogue_start");
  }, [location]);

  const onSubmit = async () => {
    const isSignupUpdated = await updateSignup({
      stage: SignupStep.ProductCataloguePage,
    });

    if (isSignupUpdated) {
      Segment.trackWithSignup(
        "web_signup_product_catalogue_click_checkout_button"
      );

      navigate(getNextStepLink());
    }
  };

  // We're drilling this prop down since it's specific to this route
  const onAddedToCart = (item: Product | Bundle) => {
    Segment.track(
      "web_signup_product_catalogue_add_to_cart",
      isBundle(item) ? { bundle: item } : { product: item }
    );
  };

  return (
    <Formik onSubmit={onSubmit}>
      {() => (
        <Form>
          <LayoutOrderSummary
            title="We’ve found some products for your business"
            description="Select a product that best suites your business needs below to continue."
            isCartEditable
            mobileFooterVariant="next"
            showButtonTarget
          >
            <div className="flex flex-col gap-32-v2">
              <ProductGrid onAddedToCart={onAddedToCart} />
            </div>
            <div className="md-v2:hidden">
              <SubmitButton
                portalled
                type="submit"
                disabled={!cart?.items.length}
              />
            </div>
          </LayoutOrderSummary>
        </Form>
      )}
    </Formik>
  );
};

export default withFetchSignup(
  ProductCataloguePage,
  SignupStep.ProductCataloguePage
);
