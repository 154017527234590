import create from "zustand";
import { devtools } from "zustand/middleware";
import CONFIG from "@/config";
import shop, { Product } from "@/shared/shop/api";
import { Bundle } from "@yoco/shop-sdk/lib/types";

export interface FormHelperState {
  bundles: Bundle[];
  fetchBundles: (storefront?: string) => void;
  products: Product[];
  fetchProducts: (storefront?: string | null, types?: string[]) => void;
}

export const useFormHelperStore = create<FormHelperState>()(
  devtools(
    set => ({
      products: [],
      bundles: [],
      fetchBundles: async (storefront = undefined) => {
        set(
          {
            bundles: await shop.bundles.list({
              is_listed_in_catalogue: true,
              ...(storefront && { storefront }),
            }),
          },
          false,
          "fetchBundles"
        );
      },
      fetchProducts: async (storefront = null, types = []) => {
        const filters = {
          is_listed_in_catalogue: true,
          types,
        } as Record<string, unknown>;

        if (storefront) {
          filters.storefront = storefront;
        }
        set(
          {
            products: await shop.products.list(filters),
          },
          false,
          "fetchProducts"
        );
      },
    }),
    { name: "ShopFormHelperStore", enabled: CONFIG.stateDebugEnabled }
  )
);
