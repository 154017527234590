import { useCartStore } from "@/shop/store";

export const useCartPrice = () => ({
  cartDiscount: useCartStore(state => state.cart?.discount),
  cartTotal: useCartStore(state => state.cart?.net_amount),
  cartTax: useCartStore(state => state.cart?.tax),
  subTotal: useCartStore(state =>
    state.cart?.gross_amount
      ? state.cart.gross_amount - state.cart.tax
      : undefined
  ),
});
