import { formatRoute } from "react-router-named-routes";
import create from "zustand";
import { devtools } from "zustand/middleware";
import CONFIG from "@/config";
import { useCartStore } from "./cart";
import { ShopRoute } from "../types";

export interface StepState {
  steps: ShopRoute[];
  reset: () => void;
  activeStep: ShopRoute;
  setActiveStep: (activeStep: ShopRoute) => void;
  getStepAfter: (step: ShopRoute) => ShopRoute | null;
  getNextStep: () => ShopRoute | null;
  getStepBefore: (step: ShopRoute) => ShopRoute | null;
  getPreviousStep: () => ShopRoute | null;
  getPreviousStepLink: () => string;
  isOnFinalStep: () => boolean;
}

export const useStepShop = create<StepState>()(
  devtools(
    (set, get) => ({
      steps: [
        ShopRoute.HomePage,
        ShopRoute.CartPage,
        ShopRoute.DeliveryPage,
        ShopRoute.PaymentPage,
        ShopRoute.CompletePage,
      ],
      reset: () => {
        get().setActiveStep(ShopRoute.HomePage);
      },
      activeStep: ShopRoute.HomePage,
      setActiveStep: activeStep => {
        set({ activeStep }, false, "setActiveStep");
      },
      getStepAfter: step => {
        const { steps } = get();
        const index = steps.indexOf(step);
        return steps[index + 1] || null;
      },
      getNextStep: () => {
        const { activeStep } = get();

        if (!activeStep) {
          return null;
        }

        return get().getStepAfter(activeStep);
      },
      getStepBefore: step => {
        const { steps } = get();
        const index = steps.indexOf(step);
        return steps[index - 1] || null;
      },
      getPreviousStep: () => {
        const { activeStep } = get();

        if (!activeStep) {
          return null;
        }

        return get().getStepBefore(activeStep);
      },
      getPreviousStepLink: () => {
        const previousStep = get().getPreviousStep();

        if (!previousStep) {
          return "";
        }
        const { cart } = useCartStore.getState();
        if (cart) {
          return formatRoute(previousStep, {
            id: cart.id,
          });
        }

        return "";
      },
      isOnFinalStep: () => {
        return get().getNextStep() === ShopRoute.CompletePage;
      },
    }),
    { name: "StepStore", enabled: CONFIG.stateDebugEnabled }
  )
);
