import { Signup, SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import { isMatch } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Segment from "@/shared/services/Segment";
import { Cart } from "@/shared/shop/api";
import { Address } from "@/shared/types";
import { createBlankAddress, isAddressPopulated } from "@/shared/utils/address";
import { makeTestID } from "@/shared/utils/development";
import { useCartStore } from "@/shop/store";
import { getShippingAddressFromCart } from "@/shop/utils/address";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import withRedirectToProductCatalogueIfCartEmpty from "@/signup/middleware/withRedirectToProductCatalogueIfCartEmpty";
import { useSignupStore, useStepStore } from "@/signup/store";
import { UserJourney } from "@/signup/types";
import { getTradingAddressFromSignup } from "@/utils/address";
// V2 Components
import SubmitButton from "../../../shared/components/checkout/SubmitButton";
import AddressFormFields, {
  getValidationSchema as getShippingAddressValidationSchema,
} from "../../../shared/components/elements/Inputs/Address/AddressFormFields";
import { Color } from "../../../constants/color";
import Typography from "../../../shared/components/elements/Typography";
import CheckBox from "../../../shared/components/elements/Inputs/Checkbox";
import LayoutOrderSummary from "../../../shared/components/checkout/LayoutOrderSummary";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  updateCart: useCartStore(state => state.updateCart),
  signup: useSignupStore(state => state.signup),
  updateSignup: useSignupStore(state => state.updateSignup),
  getNextStepLink: useStepStore(state => state.getNextStepLink),
});

type AddressFormValues = Omit<Address, "line1" | "line2"> & {
  address_line1: string | null;
  address_line2?: string | null;
  is_same_as_trading_address: boolean;
};

type InitialShippingAddressValues = {
  isInitialSameAsTradingAddress: boolean;
  initialShippingAddress: Address;
  initialShippingAddressFormValues: AddressFormValues;
};

const getInitialAddressFormValues = (
  address: Address,
  isDeliverySameAsTradingAddress: boolean
): AddressFormValues => {
  return {
    is_same_as_trading_address: isDeliverySameAsTradingAddress,
    address_line1: address.line1,
    address_line2: address.line2,
    suburb: address.suburb,
    city: address.city,
    province: address.province,
    postal_code: address.postal_code,
    country: address.country,
    latitude: address.latitude,
    longitude: address.longitude,
  };
};

const getInitialShippingAddressValues = (
  signup: Signup | null,
  cart?: Cart | null
): InitialShippingAddressValues => {
  const tradingAddress = getTradingAddressFromSignup(signup);
  const shippingAddress = getShippingAddressFromCart(cart);
  const hasShippingAddress = isAddressPopulated(shippingAddress);

  // If the cart already has a shipping address value assigned, use that. Otherwise see if the trading address
  // must be used for the shipping address. Otherwise use blank as the initial values.
  const initialShippingAddress = hasShippingAddress
    ? shippingAddress
    : tradingAddress;
  const isSameAsTradingAddress = isMatch(
    tradingAddress,
    initialShippingAddress
  );
  const initialShippingAddressFormValues = getInitialAddressFormValues(
    initialShippingAddress,
    isSameAsTradingAddress
  );

  return {
    isInitialSameAsTradingAddress: isSameAsTradingAddress,
    initialShippingAddress,
    initialShippingAddressFormValues,
  };
};

const DeliveryPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { cart, updateCart, signup, updateSignup, getNextStepLink } =
    useStore();

  // Disable Editing if coming from yoco.com
  const canEditCart = signup?.user_journey !== UserJourney.YOCOSHOP;

  const {
    isInitialSameAsTradingAddress,
    initialShippingAddress,
    initialShippingAddressFormValues,
  } = getInitialShippingAddressValues(signup, cart);

  const [isSameAsTradingAddress, setSameAsTradingAddress] = useState(
    isInitialSameAsTradingAddress
  );

  const [address, setAddress] = useState(initialShippingAddress);

  useEffect(() => {
    if (isSameAsTradingAddress) {
      setAddress(getTradingAddressFromSignup(signup));
    }
  }, [isSameAsTradingAddress, signup]);

  useEffect(() => {
    Segment.track("web_signup_delivery_start");
    Segment.track("web_signup_delivery_click_delivery_button");
  }, [location]);

  const initialValues = {
    ...initialShippingAddressFormValues,
    shipping_method: "courier",
    owner_first_name: signup?.owner_first_name || "",
    owner_last_name: signup?.owner_last_name || "",
    owner_document_type: signup?.owner_document_type || "sa_id_number",
  };

  const schema = yup.object({
    ...getShippingAddressValidationSchema(),
    owner_first_name: yup.string().required().label("business owner name(s)"),
    owner_last_name: yup.string().required().label("business owner surname"),
    owner_document_type: yup.string().required().label("document type"),
    is_same_as_trading_address: yup.bool().label("Same as trading address"),
  });

  const onSubmit = async (values: FormikValues) => {
    const newValues = { ...values };

    newValues.stage = SignupStep.DeliveryPage;
    newValues.shipping_email = signup?.email || "";
    newValues.shipping_mobile_number = signup?.mobile_number || "";
    newValues.shipping_first_name = newValues.owner_first_name || "";
    newValues.shipping_last_name = newValues.owner_last_name || "";

    // Set the specific shipping address field values from the generic form address fields
    newValues.shipping_address_line1 = newValues.address_line1;
    newValues.shipping_address_line2 = newValues.address_line2;
    newValues.shipping_suburb = newValues.suburb;
    newValues.shipping_city = newValues.city;
    newValues.shipping_province = newValues.province;
    newValues.shipping_postal_code = newValues.postal_code;
    newValues.shipping_country = newValues.country;
    newValues.shipping_latitude = newValues.latitude;
    newValues.shipping_longitude = newValues.longitude;

    const isCartUpdated = await updateCart(newValues);

    if (isCartUpdated.success) {
      const isSignupUpdated = await updateSignup(newValues);

      if (isSignupUpdated) {
        Segment.trackWithSignup("web_signup_delivery_click_next_button");

        navigate(getNextStepLink());
      }
    }
  };

  const onChangeIsDeliveryAddressTheSameAsTradingAddress = e => {
    setSameAsTradingAddress(e.target.checked);
    setAddress(createBlankAddress());
  };

  return (
    <LayoutOrderSummary
      title="Delivery"
      description="Where can we deliver your card machine?"
      isCartEditable={canEditCart}
      mobileFooterVariant="next"
      showButtonTarget
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <div className="flex flex-col gap-32-v2">
                <CheckBox
                  id="is_same_as_trading_address"
                  label="Deliver to the same as trading address"
                  checked={isSameAsTradingAddress}
                  onChange={onChangeIsDeliveryAddressTheSameAsTradingAddress}
                  data-testid={makeTestID(
                    "delivery",
                    "deliveryAddress",
                    "sameAsTrading"
                  )}
                />
                <div className="space-y-12-v2">
                  <Typography
                    text="Delivery address"
                    font="grotesk"
                    size="body-lg-fixed"
                    color={Color.Black}
                    weight="medium"
                  />
                  <AddressFormFields
                    address={address}
                    readonly={isSameAsTradingAddress}
                  />
                </div>

                <SubmitButton
                  portalled
                  testID={makeTestID("delivery", "next")}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </LayoutOrderSummary>
  );
};

export default withFetchSignup(
  withRedirectToProductCatalogueIfCartEmpty(DeliveryPage),
  SignupStep.DeliveryPage
);
