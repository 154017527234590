import classNames from "classnames";
import { Drawer } from "vaul";

import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { PriceDetails } from "@/shared/components/checkout/Drawer/PriceDetails";
import { DrawerItem } from "./Item";
import TextButton from "../../elements/Buttons/TextButton";
import Typography from "../../elements/Typography";
import { Color } from "../../../../constants/color";
import { formatPrice } from "../../../../utils/price";
import PromoCodeForm from "../PromoCodeForm";
import { useIsBreakpoint } from "../../../../utils/screen";
import { useCartPrice } from "../../../../shop/store/price";
import { useCartItemsStore } from "../../../../shop/store/items";

export const MOBILE_FOOTER_BUTTON_TARGET_ID = "mobile-footer-button-portal";

type CheckoutDrawerProps = {
  /**
   * Allow editing cart items
   * and setting a promo code
   */
  isCartEditable?: boolean;
  /**
   * When set, this shows the fixed mobile
   * footer with an optional portal render
   * target for a next/payment button
   *
   * Leave unset if you dont need a button
   */
  mobileFooterVariant?: "next" | "payment";
};

/**
 * Mobile drawer component for checkout
 *
 * Requires to be wrapped with `CheckoutDrawer`
 * and `Drawer.Trigger` as a sibling
 * (which can be nested down the tree if needed)
 *
 * ```tsx
 * <CheckoutDrawerRoot>
 *   <CheckoutDrawer />
 *   <SomeOtherComponent>
 *     <Drawer.Trigger>
 *        <button>Open the drawer</button>
 *     </Drawer.Trigger>
 *   </SomeOtherComponent>
 * </CheckoutDrawerRoot>
 * ```
 * Mobile-only payment button is handled
 * by `PayButton`, which uses this
 * as a portal target
 */
export const CheckoutDrawer = ({
  isCartEditable = false,
  mobileFooterVariant,
}: CheckoutDrawerProps) => {
  const isLaptop = useIsBreakpoint("laptop");

  const { cartItems, cartItemsCount } = useCartItemsStore();
  const { cartTotal } = useCartPrice();

  const footerContent = (
    <>
      <div className="my-20-v2">
        {!mobileFooterVariant && !isLaptop && (
          <>
            <PriceDetails />
            <div className="absolute -mx-24-v2 h-px w-full bg-charcoal-v2/10" />
          </>
        )}
        <div className="flex justify-between items-center mt-20-v2">
          <div className="flex items-center">
            <Typography
              text="Total"
              font="grotesk"
              weight="medium"
              size="body-md-fixed"
              color={Color.Black}
            />
          </div>

          <Typography
            text={
              cartTotal
                ? formatPrice(cartTotal, {
                    shouldShowDecimals: true,
                    hasThousandsSeparator: true,
                  })
                : "-"
            }
            font="grotesk"
            weight="medium"
            size="lead-sm"
            color={Color.Black}
          />
        </div>

        {!!mobileFooterVariant && (
          <>
            {/* See PayButton/SubmitButton components */}
            <div className="mt-24-v2" id={MOBILE_FOOTER_BUTTON_TARGET_ID} />
          </>
        )}
      </div>
      {!isLaptop && mobileFooterVariant === "payment" ? (
        <div className="flex justify-center">
          <div>
            <Typography
              text="By continuing, I agree to Yoco's"
              color={Color.Black}
              size="body-sm-fixed"
              font="grotesk"
              weight="book"
              className="inline"
            />{" "}
            <TextButton
              text="Terms"
              textSize="body-sm-fixed"
              color={Color.Blue}
              style="text"
              weight="book"
              linkUrl="/za/legal"
              uppercase={false}
              className="inline"
            />
          </div>
        </div>
      ) : null}
    </>
  );

  const headerContent = isLaptop ? (
    <div className="flex justify-between items-center p-24-v2">
      <Drawer.Title className="flex flex-row gap-8-v2">
        <Typography
          text="Cart"
          size="body-md"
          color={Color.Black}
          font="grotesk"
          weight="medium"
        />
        <Typography
          className="content-center uppercase opacity-50"
          text={
            cartItemsCount > 1
              ? `(${cartItemsCount} items)`
              : `(${cartItemsCount} item)`
          }
          size="body-sm"
          color={Color.Charcoal}
          font="grotesk"
          weight="book"
        />
      </Drawer.Title>
      <Drawer.Close asChild>
        <Typography
          text="Close"
          weight="medium"
          font="grotesk"
          color={Color.Blue}
          size="subscript-lg"
          leadingNone
          data-test-id="close-cart-preview-modal"
          className="cursor-pointer py-4-v2 gap-[2px] flex-col after:content-[''] after:block after:bg-current after:h-px after:transition-[width] after:duration-300 after:w-0 hover:after:w-full focus-visible:after:w-full items-start"
        />
      </Drawer.Close>
    </div>
  ) : (
    <Drawer.Title>
      <Typography
        weight="medium"
        color={Color.Black}
        font="grotesk"
        size="h5"
        text="Order summary"
      />
    </Drawer.Title>
  );

  const itemsContent = (
    <div
      className={classNames(
        "flex flex-col gap-24-v2 overflow-y-auto",
        isLaptop
          ? "flex-1 px-24-v2 max-h-[285px] mb-16-v2"
          : "max-h-[220px] py-20-v2"
      )}
    >
      {cartItems?.map(item => (
        <DrawerItem key={item.id} cartItem={item} editable={isCartEditable} />
      ))}
    </div>
  );

  const mobileContent = (
    <div className="relative">
      <div className="mx-auto mt-16-v2 h-[2px] w-40-v2 shrink-0 bg-charcoal-v2/20" />
      <div className="mx-24-v2 my-20-v2">
        {headerContent}
        {itemsContent}
        <div className="absolute -mx-24-v2 h-px w-full bg-charcoal-v2/10" />
        {isCartEditable && (
          <div className="py-16-v2">
            <PromoCodeForm />
          </div>
        )}
        <div className="absolute -mx-24-v2 h-px w-full bg-charcoal-v2/10" />
        {!mobileFooterVariant ? (
          footerContent
        ) : (
          <div className="pt-20-v2 pb-24-v2">
            <PriceDetails />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      {mobileFooterVariant && (
        <>
          <div
            className={classNames(
              "fixed bottom-0 left-0 right-0 z-20 rounded-t-sm bg-white-v2 px-24-v2 pb-20-v2 md-v2:hidden shadow-black shadow-xl drop-shadow-lg"
            )}
          >
            {footerContent}
          </div>
          {/* This fake shadow has been commented out since the dynamic height cant 
          accurately be determined (since pay button might not show in some circumstances).
          Thus, shadow-xl works fine but leaves a a shadow over the drawer when it comes up
          below this cart summary.
    
          ToDo: look into a better way to handle this - since Vaul drawer open state
          isnt in context, we cant use this to simply hide/show the drop shadow */}
          {/* <div className="fixed bottom-0 left-0 right-0 h-[170px] w-full bg-black blur-lg md-v2:hidden" /> */}
        </>
      )}

      <Drawer.Portal>
        <Drawer.Overlay
          className={classNames(
            "fixed bottom-0 left-0 right-0 bg-black-v2/30",
            isLaptop ? "z-30 top-0" : "top-[47px]"
          )}
        />
        <VisuallyHidden>
          <Drawer.Description>Checkout summary</Drawer.Description>
        </VisuallyHidden>
        <Drawer.Content
          className={classNames(
            "fixed right-0 z-40",
            isLaptop
              ? "top-28 "
              : "mt-96-v2 bottom-0 z-10 left-0 max-h-[90%] shadow-2xl shadow-black-v2"
          )}
        >
          <div
            className={classNames(
              "overflow-scroll bg-white-v2",
              isLaptop
                ? "rounded-16-v2 w-[450px] shadow-2xl flex flex-col mr-10 lg-v2:mr-24 xl-v2:mr-40 4k-v2:mr-64"
                : "rounded-t-12-v2"
            )}
          >
            {isLaptop ? (
              <>
                <div className="flex-1 flex flex-col">
                  {headerContent}
                  {itemsContent}
                </div>
                <div className="border-t border-charcoal-v2/10 mx-24-v2 pb-20-v2 flex-none">
                  {footerContent}
                </div>
              </>
            ) : (
              mobileContent
            )}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </>
  );
};
