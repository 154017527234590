import classNames from "classnames";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import { useCallback, useState } from "react";
import { object as yupObject, string as yupString } from "yup";
import type { Asserts as InferType } from "yup/lib/util/types";

import PillButton from "../elements/Buttons/PillButton";
import TextInput from "../elements/Inputs/TextInput";
import { Color } from "../../../constants/color";
import { useCartStore } from "../../../shop/store";
import Spinner from "../elements/Spinner";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  updateCart: useCartStore(state => state.updateCart),
});

const validationSchema = yupObject({
  promoCode: yupString().required("Please enter a valid promo code"),
});

type FormValues = InferType<typeof validationSchema>;

/**
 * Applies a Promo code to the cart
 */
const PromoCodeForm = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  const { cart, updateCart } = useStore();

  const appliedPromoCode = cart?.promo_code;

  const initialValues = {
    promoCode: appliedPromoCode ? appliedPromoCode.code : "",
  };

  const handleApplyPromoCode = async (
    promoCode: string,
    setErrors: (errors: FormikErrors<FormValues>) => void
  ) => {
    if (promoCode) {
      const response = await updateCart({ promo_code_str: promoCode });

      if (!response.success && response.message) {
        setErrors({ promoCode: response.message });
      }

      return response;
    }
    return null;
  };

  const handleClearPromoCode = async () => {
    await updateCart({ promo_code_str: "" });
  };

  const onSubmit = useCallback(
    async (
      values: FormValues,
      {
        resetForm,
        setErrors,
      }: {
        resetForm: FormikHelpers<FormValues>["resetForm"];
        setErrors: (errors: FormikErrors<FormValues>) => void;
      }
    ) => {
      setIsLoading(true);
      if (appliedPromoCode) {
        await handleClearPromoCode();
        resetForm({ values: { promoCode: "" } });
      } else {
        await handleApplyPromoCode(values?.promoCode, setErrors);
      }
      setIsLoading(false);
    },
    [handleApplyPromoCode, appliedPromoCode]
  );

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <div className="relative">
          <TextInput
            id="promoCode"
            name="promoCode"
            label={appliedPromoCode ? `Promo Code applied` : "Promo Code"}
            type="text"
            whiteBackground
            disabled={!!appliedPromoCode}
            className="promoCodeInput"
            onKeyDown={e => {
              // This prevents the submit event from bubbling,
              // which would trigger a submit of other forms on the page
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
          />

          <div className="absolute right-10-v2 top-10-v2">
            <PillButton
              onClick={handleSubmit}
              disabled={isLoading}
              icon={
                isLoading ? (
                  <Spinner
                    color="currentColor"
                    size="small"
                    className={classNames(
                      appliedPromoCode ? "text-white-v2" : "text-blue-v2"
                    )}
                  />
                ) : undefined
              }
              hideIcon={!isLoading}
              style="solid"
              size="medium"
              color={appliedPromoCode ? Color.Green : Color.LightBlue}
              overrideTextColor={appliedPromoCode ? Color.White : Color.Blue}
              text={isLoading ? "" : appliedPromoCode ? "Remove" : "Apply"}
              className="rounded-8-v2!"
            />
          </div>
        </div>
      )}
    </Formik>
  );
};

export default PromoCodeForm;
