// ToDo: since this is a utility component (ie: headless)
// decide if V2/Components/elements is the right place for it
import { Link as RouterLink } from "react-router-dom";
import React, { ReactNode, useMemo } from "react";

export const linkIsExternalUrl = (link: string): boolean => {
  const isEmail = typeof link === "string" && link.includes("mailto:");

  return isEmail || link?.includes("http") || link?.includes("tel:");
};

type Props = React.HTMLAttributes<HTMLAnchorElement> & {
  children: ReactNode;
  link: string;
  opensNewTab?: boolean;
};

/** 
 * This `Link` element should be used to augment
  another element or component to turn it into a link where
  we cannot predict whether the link target of the link will
  be internal or external. E.g. an image or custom card may be
  wrapped in this Link.
*/
const Link = React.forwardRef<HTMLElement, Props>(
  // eslint-disable-next-line react/prop-types
  ({ children, link, opensNewTab = true, ...props }, ref) => {
    const href = useMemo(() => {
      if (link) {
        if (typeof link === "string") {
          return link;
        }
      }

      return undefined;
    }, [link]);

    if (linkIsExternalUrl(link)) {
      if (opensNewTab) {
        return (
          <a
            href={href}
            target="_blank"
            rel="noreferrer nofollow"
            ref={ref as React.Ref<HTMLAnchorElement>}
            {...props}
          >
            {children}
          </a>
        );
      }

      return (
        <a href={href} ref={ref as React.Ref<HTMLAnchorElement>} {...props}>
          {children}
        </a>
      );
    }

    return (
      <RouterLink
        to={href || "/"}
        ref={ref as React.Ref<HTMLAnchorElement>}
        {...props}
      >
        {children}
      </RouterLink>
    );
  }
);

Link.displayName = "Link";

export default React.memo(Link);
