import classNames from "classnames";

import { Color } from "../../../constants/color";
import { getTextColorClass } from "../../../utils/color";

type Props = {
  open: boolean;
  color: Color;
};

const DropdownArrowIcon = ({ open, color }: Props) => {
  return (
    <div
      className={classNames(
        "relative h-24-v2 w-24-v2 transition-transform duration-200 ease-in-out",
        { "rotate-180": open }
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={9}
        className={classNames(
          "fill-current",
          getTextColorClass(color),
          "absolute left-1/2 top-1/2 w-12-v2 -translate-x-1/2 -translate-y-1/2 duration-300"
        )}
      >
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          d="M1 1l5 5 5-5"
        />
      </svg>
    </div>
  );
};

export default DropdownArrowIcon;
