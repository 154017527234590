import { Field as FormikField, useFormikContext } from "formik";
import { ComponentProps, ElementType } from "react";

type Props<T extends ElementType> = {
  name: string;
  component: T;
  "data-testid": string;
} & ComponentProps<T>; // Just derive props from the component we pass in

/**
 * V2 formik form input wrapper that allows us to validate on blur
 *
 * This is only needed for form inputs where we'd like to validate on blur
 */
const Field = <T extends ElementType>(props: Props<T>) => {
  const { setFieldTouched, validateField } = useFormikContext();
  const { name } = props;

  const handleBlur = () => {
    setFieldTouched(name, true);

    validateField(name);
  };

  return <FormikField onBlur={handleBlur} {...props} />;
};

export default Field;
