// TODO: Refactor progress bar into one component
import { SignupStep } from "@yoco/sawubona-sdk";
import classNames from "classnames";
import { Drawer } from "vaul";
import { useNavigate, Link } from "react-router-dom";

import { useEffect, useMemo } from "react";
import { percentage } from "@/shared/utils/helpers/general";

import { UserJourney } from "@/signup/types";
import { useAppConfigStore, useStepStore } from "@/signup/store";

import Typography from "./elements/Typography";
import LogoIcon from "./elements/LogoIcon";
import { CartIcon } from "./elements/CartIcon";
import Section from "./elements/Section";

import { Color } from "../../constants/color";
import { useCartItemsStore } from "../../shop/store/items";
import { useStepShop } from "@/shop/store/steps";
import { ShopRoute } from "@/shop/types";

const useSignupStore = () => ({
  userJourney: useAppConfigStore(state => state.userJourney),

  steps: useStepStore(state => state.steps),
  activeStep: useStepStore(state => state.activeStep),
  getPreviousStep: useStepStore(state => state.getPreviousStep),
  getPreviousStepLink: useStepStore(state => state.getPreviousStepLink),
});

const useShopStore = () => ({
  steps: useStepShop(state => state.steps),
  activeStep: useStepShop(state => state.activeStep),
  getPreviousStep: useStepShop(state => state.getPreviousStep),
  getPreviousStepLink: useStepShop(state => state.getPreviousStepLink),
});

type HeaderProps = {
  headerVariant?: "desktop" | "mobile";
};

const Header = ({ headerVariant }: HeaderProps) => {
  const navigate = useNavigate();
  const {
    userJourney,
    steps,
    activeStep,
    getPreviousStep,
    getPreviousStepLink,
  } = useSignupStore();

  const {
    activeStep: shopActiveStep,
    getPreviousStep: getShopPreviousStep,
    steps: shopSteps,
    getPreviousStepLink: getShopPreviousStepLink,
  } = useShopStore();
  const isShop = window.location.pathname.includes("/shop");
  const { cartItemsCount } = useCartItemsStore();

  const calculateProgress = (
    stepList: (ShopRoute | SignupStep)[],
    currentStep: ShopRoute | SignupStep
  ) => {
    const currentStepIndex = stepList.indexOf(currentStep);
    const currentStepNumber = currentStepIndex >= 0 ? currentStepIndex + 1 : 1;
    return percentage(currentStepNumber, stepList.length);
  };

  const calculatedPercentage = useMemo(() => {
    return isShop
      ? calculateProgress(shopSteps, shopActiveStep)
      : calculateProgress(steps, activeStep);
  }, [activeStep, isShop, shopActiveStep, shopSteps, steps]);

  const previousStep = isShop ? getShopPreviousStep() : getPreviousStep();
  const isBackButtonVisible = !isShop
    ? previousStep &&
      previousStep !== SignupStep.HomePage &&
      activeStep !== SignupStep.CompletePage
    : shopActiveStep !== ShopRoute.HomePage &&
      shopActiveStep !== ShopRoute.CartPage;

  return (
    <div className="sticky top-0 z-30">
      <Section className="bg-white-v2 shadow-md grid grid-cols-3 items-center gap-4-v2 py-16-v2 md-v2:max-[975px]:grid-cols-[1fr_1fr_40%] md-v2:min-[900px]:grid-cols-[1fr_1fr_38%] min-[975px]:grid-cols-3 min-h-[83px]">
        <div className="flex items-center justify-start">
          {isBackButtonVisible && (
            <Typography
              text="Back"
              leadingNone
              size="body-md"
              font="grotesk"
              weight="medium"
              color={Color.Black}
              className="hover:cursor-pointer"
              onClick={() => {
                const previousStepLink = isShop
                  ? getShopPreviousStepLink()
                  : getPreviousStepLink();
                if (previousStepLink) {
                  navigate(previousStepLink);
                }
              }}
            />
          )}
        </div>

        <div className="flex justify-center">
          {userJourney !== UserJourney.CINNAMON && (
            <Link
              to={isShop ? "/shop" : "/"}
              className="h-[30px] w-[78.571px] md-v2:h-[36px] md-v2:w-[92px]"
              data-testid="checkout-yoco-logo"
            >
              <LogoIcon className="w-full h-full" />
            </Link>
          )}
        </div>

        <div className="flex items-center justify-end gap-8-v2">
          {cartItemsCount > 0 && (
            <Drawer.Trigger asChild>
              <button
                type="button"
                aria-label="Cart"
                className={classNames("flex items-center", {
                  "md-v2:hidden": headerVariant === "desktop",
                })}
              >
                <CartIcon className="w-[26px] p-[5px] text-charcoal-v2 md-v2:w-[30px]" />
                <div className="flex items-center gap-[6px]">
                  <Typography
                    text="Cart"
                    size="body-md-fixed"
                    font="grotesk"
                    weight="medium"
                    color={Color.Charcoal}
                    className="hidden pt-[2px] md-v2:block"
                  />
                  <Typography
                    leadingNone
                    size="body-md-fixed"
                    font="grotesk"
                    weight="medium"
                    color={Color.White}
                    text={cartItemsCount.toString()}
                    className="flex h-[26px] w-[27px] items-center justify-center rounded-full bg-blue-v2"
                  />
                </div>
              </button>
            </Drawer.Trigger>
          )}
        </div>
      </Section>
      <div
        className="transform border-b-[3px] border-blue-v2 transition-all duration-300 ease-linear"
        style={{
          width: `${calculatedPercentage}%`,
        }}
      />
    </div>
  );
};

export default Header;
