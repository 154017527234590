import { Color } from "@/constants/color";
import PriceBreakdownLine from "@/shared/components/checkout/PriceBreakdownLine";
import Typography from "@/shared/components/elements/Typography";
import { useCartPrice } from "@/shop/store/price";
import { formatPrice } from "@/utils/price";

export const PriceDetails = () => {
  const { cartDiscount, cartTax, subTotal } = useCartPrice();

  return (
    <div className="pb-20-v2 space-y-[9px]">
      <div className="flex justify-between items-center">
        <Typography
          text="Subtotal"
          size="body-md-fixed"
          font="grotesk"
          weight="medium"
          color={Color.Black}
        />
        <Typography
          size="body-md-fixed"
          font="grotesk"
          weight="medium"
          color={Color.Black}
          text={
            subTotal
              ? formatPrice(subTotal, {
                  shouldShowDecimals: true,
                  hasThousandsSeparator: true,
                })
              : "-"
          }
        />
      </div>
      <PriceBreakdownLine
        description="Shipping"
        value="Free delivery (3 working days)"
        isPriceNegative
        size="body-sm-fixed"
      />
      <PriceBreakdownLine
        description="Tax (Vat)"
        price={cartTax}
        size="body-sm-fixed"
      />
      <PriceBreakdownLine
        description="Discount"
        price={cartDiscount}
        isPriceNegative
        size="body-sm-fixed"
      />
    </div>
  );
};
