// ToDo V2 Design
import React, { useEffect } from "react";
import CardMain from "@/shared/components/V1/CardMain";
import { getQueryStringParams } from "@/shared/utils/url";
import withFetchCart from "@/shop/middleware/withFetchCart";
import { useCartStore } from "@/shop/store";
import Layout from "@/shared/components/Layout";
import { useStepShop } from "@/shop/store/steps";
import { ShopRoute } from "@/shop/types";
import EFTContent from "./EFTContent";
import PaymentCompleteContent from "./PaymentCompleteContent";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
});

const CompletePage = () => {
  const { cart } = useStore();
  const queryStringParams = getQueryStringParams();

  let isPaid;

  if (queryStringParams.isPaid && queryStringParams.isPaid === "true") {
    isPaid = true;
  } else {
    isPaid = cart?.is_paid || false;
  }

  const useShopStore = () => ({
    setActiveStep: useStepShop(state => state.setActiveStep),
  });

  const { setActiveStep } = useShopStore();

  useEffect(() => {
    setActiveStep(ShopRoute.CompletePage);
  }, [setActiveStep]);

  return (
    <Layout isCartEditable={false}>
      <div className="flex flex-col">
        <CardMain>
          <div className="p-8">
            {isPaid ? <PaymentCompleteContent /> : <EFTContent />}
          </div>
        </CardMain>
        <div className="hidden sm:block max-w-[650px]" />
      </div>
    </Layout>
  );
};

export default withFetchCart(CompletePage);
