// TODO: Move to shared components
import { useAppConfigStore, useFormHelperStore } from "@/shop/store";
import { useEffect, useMemo } from "react";
import { Product } from "@yoco/shop-sdk";
import { Bundle } from "@yoco/shop-sdk/lib/types";
import ProductBlock from "./ProductBlock";
import BundleBlock from "./BundleBlock";

const useStore = () => ({
  products: useFormHelperStore(state => state.products),
  fetchProducts: useFormHelperStore(state => state.fetchProducts),
  bundles: useFormHelperStore(state => state.bundles),
  fetchBundles: useFormHelperStore(state => state.fetchBundles),
  storefront: useAppConfigStore(state => state.storefront),
  productTypes: useAppConfigStore(state => state.productTypes),
});

type Props = {
  onAddedToCart?: (product: Product | Bundle) => void;
};

const ProductGrid = ({ onAddedToCart }: Props) => {
  const {
    products,
    fetchProducts,
    bundles,
    fetchBundles,
    storefront,
    productTypes,
  } = useStore();
  const productList = useMemo(() => {
    if (storefront === "matrix") {
      // Hack for the matrix experiment that moves make Khumo the first product
      // TODO - remove this hack when product/bundle ordering feature is specced
      const khumo = products.find(product => product.sku === "KOZP5");
      if (khumo) {
        const khumoFirst = [khumo];
        products.forEach(product => {
          if (product.sku !== "KOZP5") khumoFirst.push(product);
        });
        return khumoFirst;
      }
    }

    return products;
  }, [products, storefront]);

  useEffect(() => {
    fetchProducts(storefront || "signup", productTypes);
    fetchBundles(storefront || "signup");
  }, [fetchBundles, fetchProducts, productTypes, storefront]);

  return (
    <div className="flex flex-col gap-32-v2">
      {productList.map(product => {
        return (
          <ProductBlock
            key={product.id}
            onAddedProductToCart={onAddedToCart}
            product={product}
          />
        );
      })}
      {bundles.map(bundle => {
        return (
          <BundleBlock
            key={bundle.id}
            onAddedBundleToCart={onAddedToCart}
            bundle={bundle}
          />
        );
      })}
    </div>
  );
};

export default ProductGrid;
