import Form from "@/shared/components/form/Form";
import Formik from "@/shared/components/form/Formik";
import Segment from "@/shared/services/Segment";
import { makeTestID } from "@/shared/utils/development";
import withFetchSignup from "@/signup/middleware/withFetchSignup";
import Validator from "@/signup/services/Validator";
import { useSignupStore } from "@/signup/store";
import { SignupRoute } from "@/signup/types";
import { BusinessType, Service, SignupStep } from "@yoco/sawubona-sdk";
import { FormikValues } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import * as yup from "yup";
// V2 Imports
import Layout from "../../../shared/components/Layout";
import FormHeader from "../../../shared/components/checkout/FormHeader";
import Field from "../../../shared/components/elements/Inputs/Field";
import TextInput from "../../../shared/components/elements/Inputs/TextInput";
import SubmitButton from "../../../shared/components/checkout/SubmitButton";

const useStore = () => ({
  signup: useSignupStore(state => state.signup),
  finaliseSignup: useSignupStore(state => state.finaliseSignup),
});

const DeveloperDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { signup, finaliseSignup } = useStore();

  useEffect(() => {
    Segment.track("web_signup_developer_details_start");
  }, [location]);

  const initialValues = {
    stage: SignupStep.DeveloperDetailsPage,
    business_name: signup?.business_name || "",
    owner_first_name: signup?.owner_first_name || "",
    owner_last_name: signup?.owner_last_name || "",
  };

  yup.addMethod(
    yup.string,
    "validBusinessName",
    Validator.validateBusinessName
  );

  const schema = yup.object({
    business_name: (yup as any)
      .string()
      .required()
      .label("business name")
      .validBusinessName(),
    owner_first_name: yup.string().required().label("first name"),
    owner_last_name: yup.string().required().label("last name"),
  });

  const onSubmit = async (values: FormikValues) => {
    const newValues = { ...values };
    newValues.business_type = BusinessType.SOLE_TRADER;
    newValues.business_category_id = 74; // Services -> Computer Services
    newValues.services = [Service.WEBSITE];

    const isSignupFinalised = await finaliseSignup(newValues);

    if (isSignupFinalised) {
      Segment.trackWithSignup("web_signup_click_signup_for_developer_account");
      Segment.trackWithSignup("Opportunity Created", "milestones");

      navigate(
        formatRoute(SignupRoute.CompletePage, {
          id: signup?.id,
        })
      );
    }
  };

  return (
    <Layout>
      <FormHeader title="Developer account" />
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <div className="flex flex-col gap-32-v2">
                <div className="flex flex-col gap-16-v2">
                  <Field
                    name="business_name"
                    id="business_name"
                    type="text"
                    placeholder="Business name *"
                    label="Business name"
                    required
                    component={TextInput}
                    data-testid={makeTestID("developerDetails", "businessName")}
                  />
                  <Field
                    name="owner_first_name"
                    id="owner_first_name"
                    type="text"
                    placeholder="First name *"
                    label="First name"
                    required
                    component={TextInput}
                    data-testid={makeTestID("developerDetails", "firstName")}
                  />
                  <Field
                    name="owner_last_name"
                    id="owner_last_name"
                    type="text"
                    placeholder="Last name *"
                    label="Last name"
                    required
                    component={TextInput}
                    data-testid={makeTestID("developerDetails", "lastName")}
                  />
                </div>
                <SubmitButton
                  text="Signup"
                  testID={makeTestID("businessName", "next")}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default withFetchSignup(
  DeveloperDetailsPage,
  SignupStep.DeveloperDetailsPage
);
