import { CartItem } from "@yoco/shop-sdk";
import { useState } from "react";
import TextButton from "../elements/Buttons/TextButton";
import QuantityInput from "../elements/Inputs/QuantityInput";
import Typography from "../elements/Typography";
import {
  getBundleThumbnailImage,
  getProductThumbnailImage,
} from "../../../assets/images/products";
import { Color } from "../../../constants/color";
import { formatPrice } from "../../../utils/price";
import { useCartStore } from "../../../shop/store";

const useStore = () => ({
  addProduct: useCartStore(state => state.add),
  addBundle: useCartStore(state => state.addBundle),
  removeProduct: useCartStore(state => state.remove),
  removeBundle: useCartStore(state => state.removeBundle),
});

type LayoutOrderSummaryItemProps = {
  cartItem: CartItem;
  editable?: boolean;
};

const LayoutOrderSummaryItem = ({
  cartItem,
  editable = false,
}: LayoutOrderSummaryItemProps) => {
  const { addProduct, removeProduct, addBundle, removeBundle } = useStore();

  const [isQuantityLoading, setIsQuantityLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);

  const handleUpdateQuantity = async (newQuantity: number) => {
    setIsQuantityLoading(true);
    if (newQuantity > cartItem.quantity) {
      if (cartItem.bundle) {
        addBundle(cartItem.bundle.id);
      } else {
        await addProduct(cartItem.product.id);
      }
    }
    if (newQuantity < cartItem.quantity) {
      if (cartItem.bundle) {
        await removeBundle(cartItem.bundle.id);
      } else {
        await removeProduct(cartItem.product.id);
      }
    }
    setIsQuantityLoading(false);
  };

  const handleRemoveItem = async () => {
    setIsRemoveLoading(true);
    if (cartItem.bundle) {
      await removeBundle(cartItem.bundle.id, cartItem.quantity);
    } else {
      await removeProduct(cartItem.product.id, cartItem.quantity);
    }
    setIsRemoveLoading(false);
  };

  return (
    <div className="w-full gap-16-v2 p-12-v2 items-start grid grid-cols-[60px_2fr_1fr]">
      <div className="h-60-v2 w-60-v2 px-10-v2 flex">
        <img
          // TODO: pull the image in from api rather
          alt="Product thumbnail"
          className=" object-contain"
          src={
            cartItem.bundle
              ? getBundleThumbnailImage(cartItem.bundle) ??
                cartItem.bundle.image
              : getProductThumbnailImage(cartItem.product) ??
                cartItem.product.image
          }
        />
      </div>
      <div className="flex flex-col gap-12-v2 w-full">
        <Typography
          text={
            cartItem.bundle
              ? cartItem.bundle.short_name
              : cartItem.product.short_name
          }
          weight="medium"
          font="grotesk"
          size="body-md-fixed"
          color={Color.Black}
        />

        <QuantityInput
          value={cartItem.quantity}
          maxValue={
            cartItem.bundle
              ? cartItem.bundle.max_quantity_per_cart ?? undefined
              : cartItem.product.max_quantity_per_cart ?? undefined
          }
          onQuantityUpdate={async quantity => handleUpdateQuantity(quantity)}
          disabled={!editable || isQuantityLoading}
        />
      </div>
      <div className="ml-auto flex flex-col gap-4-v2">
        <Typography
          text={formatPrice(
            cartItem.bundle
              ? cartItem.bundle.unit_price
              : cartItem.product.unit_price,
            {
              hasThousandsSeparator: true,
              shouldShowDecimals: true,
            }
          )}
          weight="book"
          font="grotesk"
          size="body-md-fixed"
          color={Color.Black}
          className="ml-auto"
        />
        {editable && (
          <TextButton
            text="Remove"
            textSize="body-md-fixed"
            color={Color.Blue}
            style="text"
            weight="medium"
            uppercase={false}
            onClick={handleRemoveItem}
            disabled={isRemoveLoading}
            className="ml-auto"
          />
        )}
      </div>
    </div>
  );
};

export default LayoutOrderSummaryItem;
