import CONFIG from "@/config";
import { CountryCode } from "@/libs/sawubona-sdk/types";
import { useStepStore } from "@/signup/store";
import { UserJourney } from "@/signup/types";
import { Service, Signup } from "@yoco/sawubona-sdk";
import Cookies from "js-cookie";
import create from "zustand";
import { devtools } from "zustand/middleware";

export interface AppConfigState {
  userJourney: UserJourney | null;
  referralCode: string | null;
  resellerCode: string | null;
  salesSmartProposalId: string | null;
  promoCode: string | null;
  services: Service[];
  skipServiceSelectionStep: boolean;
  skipProductCatalogueStep: boolean;
  autoAddProductID: number | null;
  readerSerialNumber: string | null;
  isInApp: boolean;
  isInHub: boolean;
  preQualification: string | null;
  setUserJourney: (userJourney: UserJourney | null) => void;
  setUserJourneyFromSignup: (signup?: Signup | null) => void;
  setReferralCode: (referralCode: string | null) => void;
  setResellerCode: (resellerCode: string | null) => void;
  setSalesSmartProposalId: (salesSmartProposalId: string | null) => void;
  setPromoCode: (promoCode: string | null) => void;
  setServices: (services: Service[]) => void;
  setSkipServiceSelectionStep: (skipServiceSelectionStep: boolean) => void;
  setSkipProductCatalogueStep: (skipProductCatalogueStep: boolean) => void;
  setAutoAddProductID: (autoAddProductID: number | null) => void;
  setReaderSerialNumber: (readerSerialNumber: string | null) => void;
  setIsInApp: (isInApp: boolean) => void;
  setIsInHub: (isInHub: boolean) => void;
  setPreQualification: (preQualification: string | null) => void;
}

export const useAppConfigStore = create<AppConfigState>()(
  devtools(
    (set, get) => ({
      userJourney: CONFIG.defaultUserJourney,
      referralCode: null,
      resellerCode: null,
      salesSmartProposalId: null,
      promoCode: null,
      services: [],
      skipServiceSelectionStep: false,
      skipProductCatalogueStep: false,
      autoAddProductID: null,
      readerSerialNumber: null,
      // used to check if loaded in POS
      isInApp: sessionStorage.getItem("isInApp") === "true",
      // used to check if loaded in new Yoco app (HUB)
      isInHub: sessionStorage.getItem("isInHub") === "true",
      preQualification: null,
      setUserJourney: userJourney => {
        set({ userJourney }, false, "setUserJourney");

        if (
          userJourney === UserJourney.CINNAMON ||
          userJourney === UserJourney.MANKAI
        ) {
          get().setAutoAddProductID(null);
          get().setServices([Service.READER_REGISTRATION]);
          get().setSkipServiceSelectionStep(true);
        }

        if (CONFIG.countryCode === CountryCode.Egypt) {
          get().setSkipServiceSelectionStep(true);
        }
        useStepStore.getState().refreshSteps();
      },
      setUserJourneyFromSignup: signup => {
        if (signup) {
          const newUserJourney = signup?.user_journey as UserJourney;

          if (
            newUserJourney &&
            Object.values(UserJourney).includes(newUserJourney)
          ) {
            get().setUserJourney(newUserJourney);
          } else {
            get().setUserJourney(CONFIG.defaultUserJourney);
          }
        }
      },
      setReferralCode: referralCode => {
        set({ referralCode }, false, "setReferralCode");

        if (referralCode) {
          Cookies.set("referral_code", referralCode, { expires: 30 });
        }
      },
      setResellerCode: resellerCode => {
        set({ resellerCode }, false, "setResellerCode");
      },
      setSalesSmartProposalId: salesSmartProposalId => {
        set({ salesSmartProposalId }, false, "setSalesSmartProposalId");
      },
      setPromoCode: promoCode => {
        set({ promoCode }, false, "setPromoCode");
      },
      setServices: services => {
        set({ services }, false, "setServices");
      },
      setSkipServiceSelectionStep: skipServiceSelectionStep => {
        set({ skipServiceSelectionStep }, false, "setSkipServiceSelectionStep");
      },
      setSkipProductCatalogueStep: skipProductCatalogueStep => {
        set({ skipProductCatalogueStep }, false, "setSkipProductCatalogueStep");
      },
      setAutoAddProductID: autoAddProductID => {
        set({ autoAddProductID }, false, "setAutoAddProductID");
      },
      setReaderSerialNumber: readerSerialNumber => {
        set({ readerSerialNumber }, false, "setReaderSerialNumber");
      },
      setIsInApp: isInApp => {
        sessionStorage.setItem("isInApp", `${isInApp}`);
        set({ isInApp }, false, "setIsInApp");
      },
      setIsInHub: isInHub => {
        sessionStorage.setItem("isInHub", `${isInHub}`);
        set({ isInHub }, false, "setIsInHub");
      },
      setPreQualification: preQualification => {
        set({ preQualification }, false, "setPreQualification");
      },
    }),
    { name: "SignupAppConfigStore", enabled: CONFIG.stateDebugEnabled }
  )
);
