/* eslint-disable jsx-a11y/control-has-associated-label */
import classNames from "classnames";
import { useField } from "formik";
import { useState, useEffect, ChangeEvent, useRef, Fragment } from "react";

import InputError from "./InputError";
import { Color } from "../../../../constants/color";
import Typography from "../Typography";

import DropdownArrowIcon from "../DropdownArrowIcon";

export interface SelectOption {
  label: string;
  value: string | number;
}

export const DropdownStyles =
  "absolute z-50 w-full rounded-12-v2 rounded-t-none border border-t-0 border-yoco-blue bg-white pb-8-v2 shadow-[0_1px_0_0_var(--color-blue-v2),1px_0_0_0_var(--color-blue-v2),-1px_0_0_0_var(--color-blue-v2)]";

export const DropdownItemStyles = "mx-8-v2 cursor-pointer rounded-[3px]";

export interface Props {
  id: string;
  name: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  label: string;
  onFocus?: () => void;
  onBlur?: (e: ChangeEvent<HTMLSelectElement>) => void;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  options: SelectOption[];
  color?: Color.White | Color.Charcoal;
  ["data-testid"]?: string;
}

/**
 * Do not wrap this component with `Field`,
 * since it validates on select internally.
 */
const DropdownInput = ({
  id,
  name,
  onFocus,
  onBlur,
  options,
  className,
  label,
  onChange,
  color = Color.Charcoal,
  required = false,
  disabled = false,
  ...props
}: Props): JSX.Element => {
  const [focus, setFocus] = useState(false);
  const [field, meta, helpers] = useField(id);

  const hasError = meta.touched && meta.error;

  const [isOpen, setIsOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    helpers.setTouched(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBlur = e => {
    if (onBlur) onBlur(e);
    setTimeout(() => {
      if (!dropdownRef.current?.contains(document.activeElement)) {
        setIsOpen(false);
        setFocus(false);
        helpers.setTouched(true);
      }
    }, 10); // Small timeout to ensure the focus change happens after the dropdown state update
  };

  const toggleDropdown = () => setIsOpen(prev => !prev);

  const handleOptionSelect = (value: string | number, index: number) => {
    setTimeout(() => {
      helpers.setValue(value, true); // Trigger validation when new value is set
      setIsOpen(false);
      setSelectedIndex(index);
      setFocus(false);
      if (onChange) {
        const event = {
          target: {
            name: id,
            value,
          },
        } as React.ChangeEvent<HTMLSelectElement>;
        onChange(event);
      }
    }, 10);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLButtonElement | HTMLLIElement>
  ) => {
    if (!isOpen) return;

    if (e.key === "ArrowDown") {
      setFocusedIndex(prev => (prev + 1) % options.length);
    } else if (e.key === "ArrowUp") {
      setFocusedIndex(prev => (prev - 1 + options.length) % options.length);
    } else if (e.key === "Enter" && focusedIndex >= 0) {
      handleOptionSelect(options[focusedIndex].value, focusedIndex);
    } else if (e.key === "Escape") {
      setIsOpen(false);
      helpers.setTouched(true);
    }
  };
  return (
    <div
      className={classNames("w-full", { "opacity-50": disabled }, className)}
      tabIndex={-1}
      ref={dropdownRef}
    >
      <div className="relative w-full">
        <label
          htmlFor={id}
          className={classNames(
            "pointer-events-none absolute left-16-v2 flex gap-8-v2 transition-all duration-200 ease-linear",
            field.value ? "top-10-v2" : "top-[22px]"
          )}
        >
          <Typography
            weight={field.value ? "medium" : "book"}
            font="grotesk"
            color={color}
            text={required ? `${label} *` : label}
            size={field.value ? "body-sm-fixed" : "body-md-fixed"}
            className={classNames("transition-all duration-200")}
          />
        </label>
        <input
          id={id}
          {...field}
          {...props}
          type="hidden"
          value={
            options.length
              ? selectedIndex > -1
                ? options[selectedIndex]?.value
                : ""
              : ""
          }
        />
        <button
          disabled={disabled}
          name={name}
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          className={classNames(
            "h-[61px] -mb-4-v2 w-full rounded-12-v2 border border-charcoal-v2/20 bg-white px-16-v2 py-12-v2 pt-24-v2 text-left hover:border-charcoal-v2",
            {
              "rounded-b-none border-b-0 !border-blue-v2 shadow-[0_-1px_0_0_var(--color-blue-v2),1px_0_0_0_var(--color-blue-v2),-1px_0_0_0_var(--color-blue-v2)]":
                isOpen,
            },
            { "border-orange-v2": hasError }
          )}
          onBlur={handleBlur}
          onClick={e => {
            e.preventDefault();
            toggleDropdown();
          }}
          onFocus={() => {
            setFocus(true);
            if (onFocus) {
              onFocus();
            }
          }}
          onKeyDown={e => {
            e.preventDefault();
            handleKeyDown(e);
          }}
        >
          <Typography
            weight="book"
            font="grotesk"
            color={color}
            text={
              field.value
                ? `${
                    options.find(option => option.value === field.value)?.label
                  }`
                : " "
            }
            size="body-sm"
            className={classNames("mt-[7px] transition-all duration-200")}
          />

          <span
            className={classNames(
              "pointer-events-none absolute right-16-v2 top-20-v2 duration-200 ease-linear",
              { "rotate-180": focus }
            )}
          >
            <DropdownArrowIcon open={false} color={Color.Charcoal} />
          </span>
        </button>

        {isOpen && (
          <ul role="listbox" className={DropdownStyles}>
            {options.map((option, index) => (
              <Fragment key={option.value}>
                <div
                  className={classNames(
                    "mx-8-v2 border-t-[1px]",
                    focusedIndex === index ||
                      focusedIndex === index - 1 ||
                      selectedIndex === index ||
                      selectedIndex === index - 1
                      ? "border-transparent"
                      : "border-charcoal-v2/20"
                  )}
                />
                <li
                  role="option"
                  tabIndex={0}
                  aria-selected={field.value === option.value}
                  className={classNames(
                    DropdownItemStyles,
                    "px-10-v2 py-12-v2",
                    { "bg-blue-v2/20": field.value === option.value },
                    { "bg-blue-v2/30": focusedIndex === index }
                  )}
                  onClick={() => handleOptionSelect(option.value, index)}
                  onKeyDown={handleKeyDown}
                  onMouseEnter={() => setFocusedIndex(index)}
                >
                  <Typography
                    weight="book"
                    font="grotesk"
                    color={color}
                    text={option.label}
                    size="body-sm"
                  />
                </li>
              </Fragment>
            ))}
          </ul>
        )}
      </div>
      {hasError ? (
        <InputError
          error={`${meta.error}`}
          data-test-id={`input-error-${props["data-testid"]}`}
        />
      ) : null}
    </div>
  );
};

export default DropdownInput;
