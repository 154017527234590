import classNames from "classnames";
import Typography from "../elements/Typography";
import { Color } from "../../../constants/color";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  description: string;
  image?: string;
  checked: boolean;
  value: string;
};

const PaymentMethodNavItem = ({
  label,
  description,
  image,
  checked,
  ...props
}: Props) => {
  return (
    <div className="block bg-white rounded-8-v2">
      <label className="cursor-pointer">
        <input type="radio" className="hidden" {...props} />
        <div
          className={classNames(
            "flex gap-16-v2 border rounded-8-v2 py-20-v2 px-12-v2",
            checked
              ? "bg-light-blue-v2 border-blue-v2 "
              : "border-light-gray-v2"
          )}
        >
          <div className="flex-initial shrink justify-center content-center">
            {image && (
              <img src={image} alt="Selected" className="w-[50px] h-[50px]" />
            )}
            <div className="block font-bold text-base tracking-tight" />
          </div>

          <div className="flex flex-col gap-4-v2">
            <Typography
              font="grotesk"
              size="body-lg"
              text={label}
              color={Color.Black}
              weight="medium"
            />
            <Typography
              font="grotesk"
              size="body-sm"
              text={description}
              color={Color.Black}
              weight="book"
            />
          </div>
        </div>
      </label>
    </div>
  );
};

export default PaymentMethodNavItem;
