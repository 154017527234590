import { useFormikContext } from "formik";
import { ComponentProps, useEffect, useState } from "react";
import classNames from "classnames";
import { createPortal } from "react-dom";
import PillButton from "../elements/Buttons/PillButton";
import { Color } from "../../../constants/color";
import { useIsBreakpoint } from "../../../utils/screen";
import { MOBILE_FOOTER_BUTTON_TARGET_ID } from "./Drawer";
import { DESKTOP_LAYOUT_BUTTON_TARGET_ID } from "./LayoutOrderSummary";

type Props =
  | {
      disabled?: boolean;
      submitting?: boolean;
      onClick?: () => void;
      testID?: string;
      text?: string;
      /**
       * Will portal into `LayoutOrderSummary` (desktop)
       * and `Drawer` (mobile) when `portalled` is set
       */
      portalled?: boolean;
      className?: string;
    } & (
      | {
          type?: "button";
          onClick: () => void;
        }
      | {
          /**
           * When type is submit,
           * this component needs
           * to be wrapped by `Formik`
           */
          type?: "submit";
          onClick?: () => void;
        }
    );

/**
 *  Wrapper around V2 `PillButton` that grabs formik submitting state
 *
 *
 */
const SubmitButton = ({
  disabled,
  submitting,
  text = "Next",
  type = "submit",
  onClick,
  portalled,
  className,
  ...props
}: Props) => {
  const isLaptop = useIsBreakpoint("laptop");

  const portalTarget = document.getElementById(
    isLaptop ? DESKTOP_LAYOUT_BUTTON_TARGET_ID : MOBILE_FOOTER_BUTTON_TARGET_ID
  );

  const [isSubmitting, setSubmitting] = useState<boolean | undefined>(
    submitting
  );
  const { submitForm, isSubmitting: isFormikSubmitting } = useFormikContext();

  useEffect(() => {
    // We allow the SubmitButton component to be used both inside and outside the context of Formik.
    // If the user has passed the submitting prop through and it's set to true, we'll use that value.
    // If we're within the context of Formik, we'll grab the submission state from Formik.
    if (submitting === true || isFormikSubmitting) {
      setSubmitting(true);
    } else {
      setSubmitting(false);
    }
  }, [submitting, isFormikSubmitting]);

  const sharedProps: ComponentProps<typeof PillButton> = {
    text,
    size: "medium",
    style: "solid",
    disabled: disabled || isSubmitting,
    color: Color.Blue,
    className: classNames("!w-full", className),
    hideIcon: true,
    nonResponsive: true,
    ...props,
  };

  if (portalled) {
    return portalTarget ? (
      <>
        {createPortal(
          <PillButton
            {...sharedProps}
            type="button"
            onClick={onClick ?? submitForm}
          />,
          portalTarget
        )}
      </>
    ) : null;
  }

  return <PillButton type={type} onClick={onClick} {...sharedProps} />;
};

export default SubmitButton;
