import { FieldInputProps, FormikProps, FormikValues } from "formik";
import React, { useRef, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import useScript from "react-script-hook";
import classNames from "classnames";
import CONFIG from "@/config";
import { Address } from "@/shared/types";
import { makeTestID } from "@/shared/utils/development";
import { executeScroll } from "@/shared/utils/helpers/general";
import AddressService from "@/shop/services/AddressService";
import "./index.css";
import TextInput from "../../TextInput";
import Typography from "../../../Typography";
import { Color } from "../../../../../../constants/color";
import { DropdownItemStyles, DropdownStyles } from "../../DropdownInput";
import Loader from "../../../Spinner";

type GooglePlacesAutocompleteFieldValue =
  | string
  | number
  | readonly string[]
  | undefined;

type Props = {
  form: FormikProps<FormikValues>;
  field: FieldInputProps<GooglePlacesAutocompleteFieldValue>;
  placeholder?: string;
  label?: string;
  onSelect: (address: Address) => void;
  readonly?: boolean;
};

const GooglePlacesAutocomplete: React.FC<Props> = ({
  form,
  field,
  placeholder,
  label,
  onSelect,
  readonly = false,
  ...props
}: Props) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState<number>(-2);
  const [address, setAddress] = useState(form.initialValues.address_line1);

  const [isScriptLoading, scriptError] = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${CONFIG?.googlePlacesPublicKey}&libraries=places`,
    checkForExisting: true,
  });
  const isScriptLoaded = !isScriptLoading && scriptError === null;

  const handleChange = (addressLine1: string) => {
    form.setFieldValue("address_line1", addressLine1);
    setAddress(addressLine1);
  };

  const handleSelect = (addressLine1: string) => {
    geocodeByAddress(addressLine1)
      .then(results => {
        const selectedAddress = AddressService.getStructuredAddress(results[0]);
        setAddress(selectedAddress.line1);
        onSelect(selectedAddress);
      })
      .catch(() => {
        //
      });
  };

  const searchOptions = {
    componentRestrictions: { country: CONFIG.countryCode },
    types: ["address"],
  };

  return isScriptLoaded ? (
    <PlacesAutocomplete
      id={field.name}
      {...props}
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
      debounce={5}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => {
        return (
          <div className="relative" ref={inputRef}>
            <TextInput
              {...getInputProps({
                placeholder,
                label,
                autoComplete: "new-password",
                name: field.name,
              })}
              id={field.name}
              name={field.name}
              value={field.value}
              onFocus={() => executeScroll(inputRef.current as HTMLDivElement)}
              data-testid={makeTestID("delivery", "deliveryAddress", "search")}
              disabled={readonly}
            />
            {suggestions.length >= 1 && (
              <div className={classNames(DropdownStyles, "top-48-v2 pt-8-v2")}>
                {suggestions.map((suggestion, index) => {
                  const isActive = suggestion.active;
                  const isNextActive = index === activeIndex + 1;

                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className: classNames(
                          DropdownItemStyles,
                          isActive ? "bg-blue-v2/30" : "bg-white",
                          "hover:bg-blue-v2/20 flex flex-col justify-between"
                        ),
                        onMouseEnter: () => setActiveIndex(index),
                        onMouseLeave: () => setActiveIndex(-2),
                      })}
                      key={`suggestion-${suggestion.placeId}`}
                    >
                      <div
                        className={classNames(
                          "z-20 mx-8-v2 border-t",
                          isActive || isNextActive
                            ? "border-transparent"
                            : "border-charcoal-v2/20"
                        )}
                      />
                      <Typography
                        text={suggestion.description}
                        size="body-sm"
                        font="grotesk"
                        weight="book"
                        color={Color.Black}
                        className="mx-8-v2 my-16-v2"
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      }}
    </PlacesAutocomplete>
  ) : (
    <div className="flex h-[77px] items-center gap-4-v2 py-16-v2">
      <Typography
        text="Loading street input"
        size="body-sm"
        font="grotesk"
        weight="book"
        color={Color.Black}
      />
      <Loader />
    </div>
  );
};

export default GooglePlacesAutocomplete;
