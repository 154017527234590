import { useSignupStore, useStepStore } from "@/signup/store";
import { SignupRoute } from "@/signup/types";
import { SignupStep } from "@yoco/sawubona-sdk";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { validate as isValidUUID } from "uuid";
// V2 Imports
import Layout from "../../../shared/components/Layout";
import { LoaderIcon } from "../../../shared/components/elements/Loader";

const useStore = () => ({
  fetchSignup: useSignupStore(state => state.fetchSignup),
  getSignup: useSignupStore(state => state.getSignup),
  getStepAfter: useStepStore(state => state.getStepAfter),
});

const ContinuePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { fetchSignup, getSignup, getStepAfter } = useStore();

  useEffect(() => {
    if (id && isValidUUID(id)) {
      fetchSignup(id)
        .then(() => {
          const signup = getSignup();
          const lastActiveStep = signup.stage || SignupStep.HomePage;

          // The "business_details" step has been replaced.  If the user was previously on the business details step,
          // we'll resume their signup on the "business name" step.
          const currentStep =
            lastActiveStep === ("/signup/:id/business_details/" as SignupStep)
              ? SignupStep.BusinessNamePage
              : getStepAfter(lastActiveStep);

          navigate(
            formatRoute(currentStep, {
              id: signup.id,
            })
          );
        })
        .catch(() => {
          navigate(SignupRoute.HomePage);
        });
    } else {
      navigate(SignupRoute.HomePage);
    }
  }, [navigate, id, fetchSignup, getSignup, getStepAfter]);

  return (
    <Layout>
      <LoaderIcon
        width={150}
        className="text-blue-v2 my-32-v2 sm-v2:my-40-v2"
      />
    </Layout>
  );
};

export default ContinuePage;
