const messages = {
  mixed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    required: ({ path }): string => {
      return `The ${path} field is required.`;
    },
  },
  string: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    email: ({ path }): string => {
      return `The ${path} is not a valid email address.`;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    min: ({ path, min }): string => {
      return `The ${path} must be a minimum of ${min} characters.`;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    max: ({ path, max }): string => {
      return `The ${path} must be a maximum of ${max} characters.`;
    },
  },
};

export default {
  messages,
};
