import classNames from "classnames";
import { useField } from "formik";
import {
  useState,
  useEffect,
  FocusEventHandler,
  KeyboardEventHandler,
} from "react";

import { IMaskInput } from "react-imask";
import InputError from "./InputError";
import { Color } from "../../../../constants/color";
import Typography from "../Typography";

import { getTextColorClass } from "../../../../utils/color";
import EyeIcon from "../EyeIcon";

// TODO: Whole input, styling and props is 99% the same as Phone number. Look into refactoring to one code

export interface Props {
  id: string;
  name: string;
  maxLength?: number;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  whiteBackground?: boolean;
  label?: string;
  placeholder?: string;
  color?: Color.White | Color.Charcoal;
  onFocus?: () => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  description?: string;
  ["data-testid"]?: string;
  type: "text" | "password" | "email";
  /**
   * Note we use `imaskjs` in V2
   *
   * @see https://imask.js.org/ for format examples
   */
  mask?: string;
}

const TextInput = ({
  id,
  name,
  type,
  onFocus,
  onBlur,
  className,
  whiteBackground = false,
  label,
  placeholder,
  description,
  color = Color.Charcoal,
  maxLength = 250,
  required = false,
  disabled = false,
  mask,
  ...props
}: Props) => {
  const [focus, setFocus] = useState(false);
  const [field, meta, helpers] = useField(id);
  const [showPassword, setShowPassword] = useState(false);

  const hasError = meta.touched && meta.error;
  const [hasValueOrFocus, setHasValueOrFocus] = useState(
    !!field.value || focus
  );

  useEffect(() => {
    helpers.setTouched(false);
    const input = document.getElementById(id);
    const checkAutofill = () => {
      if (input?.matches(":-webkit-autofill")) {
        setHasValueOrFocus(true);
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(checkAutofill, 500);
    setTimeout(() => clearInterval(intervalId), 3000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHasValueOrFocus(!!field.value || focus);
  }, [field.value, focus]);

  const commonProps = {
    id,
    ...field,
    name,
    value: field.value,
    disabled,
    maxLength,
    onBlurCapture: () => setFocus(false),
    type: type === "password" && showPassword ? "text" : type,
    placeholder: hasValueOrFocus && placeholder ? placeholder : undefined,
    className: classNames(
      whiteBackground ? "bg-white-v2" : "bg-transparent",
      // Note: font-size here is tied to the label & description Typography components below
      "w-full rounded-12-v2 border px-16-v2 text-body-md-v2 placeholder:text-body-md-v2 focus:border-blue-v2 focus:ring ring-blue-v2",
      !label ? "py-16-v2" : "pt-[28px] pb-10-v2",
      getTextColorClass(color),
      { "border-orange-v2": hasError },
      {
        "border-charcoal-v2/20 hover:border-charcoal-v2":
          color === Color.Charcoal && !hasError,
      },
      {
        "border-white-v2/20 hover:border-white-v2":
          color === Color.White && !hasError,
      }
    ),
    onBlur,
    onFocus: () => {
      setFocus(true);
      if (onFocus) {
        onFocus();
      }
    },
    ...props,
  };

  return (
    <div
      className={classNames("w-full", { "opacity-50": disabled }, className)}
    >
      <div className="relative w-full rounded-12-v2">
        {label && (
          <label
            htmlFor={id}
            className={classNames(
              "absolute left-16-v2 flex gap-8-v2 transition-all duration-200 ease-linear",
              {
                "!top-10-v2": hasValueOrFocus,
              },
              {
                "top-[22px]": !hasValueOrFocus,
              }
            )}
          >
            <Typography
              weight={hasValueOrFocus ? "medium" : "book"}
              font="grotesk"
              color={color}
              text={required ? `${label} *` : label}
              // when !hasValueOrFocus: size is tied to font-size in commonProps.className
              size={hasValueOrFocus ? "body-sm-fixed" : "body-md-fixed"}
              className={classNames("transition-all duration-200")}
            />
            {description && (
              <Typography
                weight="book"
                font="grotesk"
                text={`(${description})`}
                color={color}
                // when !hasValueOrFocus: size is tied to font-size in commonProps.className
                size={hasValueOrFocus ? "body-sm-fixed" : "body-md-fixed"}
              />
            )}
          </label>
        )}

        {mask ? (
          <IMaskInput mask={mask} {...commonProps} />
        ) : (
          <input {...commonProps} />
        )}
        {type === "password" && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <span
            onMouseUp={() => setShowPassword(false)}
            onMouseDown={() => setShowPassword(true)}
            onTouchStart={() => setShowPassword(true)}
            onTouchEnd={() => setShowPassword(false)}
            className="flex absolute right-16-v2 top-0 bottom-0 items-center cursor-pointer hover:opacity-50"
          >
            <EyeIcon />
          </span>
        )}
      </div>

      {hasError ? (
        <InputError
          error={`* ${meta.error}`}
          data-testid={`input-error-${props["data-testid"]}`}
        />
      ) : null}
    </div>
  );
};

export default TextInput;
