import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { PaymentMethod } from "@/shared/shop/api";
import withFetchCart from "@/shop/middleware/withFetchCart";
import withRedirectToCartPageIfCartEmpty from "@/shop/middleware/withRedirectToCartPageIfCartEmpty";
import DynamicPaymentMethod from "@/shop/pages/PaymentPage/DynamicPaymentMethod";
import { useCartStore } from "@/shop/store";
import { ShopRoute } from "@/shop/types";
import PaymentMethodNav from "./PaymentMethodNav";
import LayoutOrderSummary from "@/shared/components/checkout/LayoutOrderSummary";
import { useStepShop } from "@/shop/store/steps";

const useStore = () => ({
  cart: useCartStore(state => state.cart),
  intendedPaymentMethod: useCartStore(state => state.intendedPaymentMethod),
  availablePaymentMethods: useCartStore(
    state => state.cart?.available_payment_methods
  ),
  setIntendedPaymentMethod: useCartStore(
    state => state.setIntendedPaymentMethod
  ),
});

const PaymentPage = () => {
  const navigate = useNavigate();
  const {
    availablePaymentMethods,
    cart,
    intendedPaymentMethod,
    setIntendedPaymentMethod,
  } = useStore();
  const [loaderMessage, setLoaderMessage] = useState<string>("");

  const useShopStore = () => ({
    setActiveStep: useStepShop(state => state.setActiveStep),
  });

  const { setActiveStep } = useShopStore();

  useEffect(() => {
    setActiveStep(ShopRoute.PaymentPage);
  }, [setActiveStep]);

  useEffect(() => {
    if (availablePaymentMethods?.includes(PaymentMethod.CREDIT_CARD_CHECKOUT)) {
      setLoaderMessage("Please wait while you are redirected to make payment");
    }
  }, [availablePaymentMethods, loaderMessage]);

  useEffect(() => {
    if (cart?.balance_due.toString() === "0.00") {
      setIntendedPaymentMethod(PaymentMethod.FREE);
    }
  }, [cart, setIntendedPaymentMethod]);

  const onPaymentSuccess = useCallback(
    (_: PaymentMethod, isPaid?: boolean) => {
      // The CompletePage will by default look at the payment state on the cart.  For certain payment methods which do
      // as async callback to us, we don't know that the order is paid for by the time the customer sees this page.
      // We therefore allow for an "isPaid" query string param to be passed. This only affects what the customer sees
      // on this page, not the actual payment state of the order.
      let url = formatRoute(ShopRoute.CompletePage, { id: cart?.id });
      if (isPaid !== undefined) {
        url += `?isPaid=${isPaid ? "true" : "false"}`;
      }

      navigate(url);
    },
    [navigate, cart?.id]
  );

  return (
    <LayoutOrderSummary title="Payment" mobileFooterVariant="payment">
      <div className="flex flex-col gap-32-v2 pb-[200px] md:mb-0">
        {!availablePaymentMethods?.includes(
          PaymentMethod.CREDIT_CARD_CHECKOUT
        ) && <PaymentMethodNav />}
        <DynamicPaymentMethod
          name={intendedPaymentMethod}
          onSuccess={onPaymentSuccess}
        />
      </div>
    </LayoutOrderSummary>
  );
};

export default withFetchCart(withRedirectToCartPageIfCartEmpty(PaymentPage));
